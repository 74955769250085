import { useBaseStore } from '@/store'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'
import { Taxes } from '@/types/Costs'
import { useCallback, useMemo, useReducer } from 'react'

interface Tax {
    groupName: string
    inputs: {
        name: keyof Taxes
        label: string
        value: string
    }[]
}

export interface AddExtraCostDialogState {
    taxes: Tax[]
    costValue: string
    description: string
    quantity: string
}
export enum AddExtraCostDialogActionNames {
    ON_CHANGE_COST,
    ON_CHANGE_DESCRIPTION,
    ON_CHANGE_TAX_VALUE,
    ON_CHANGE_QUANTITY,
    RESET,
}

type AddExtraCostDialogActions =
    | {
          type: AddExtraCostDialogActionNames.ON_CHANGE_COST
          payload: {
              cost: string
          }
      }
    | {
          type: AddExtraCostDialogActionNames.ON_CHANGE_DESCRIPTION
          payload: {
              description: string
          }
      }
    | {
          type: AddExtraCostDialogActionNames.ON_CHANGE_QUANTITY
          payload: {
              quantity: string
          }
      }
    | {
          type: AddExtraCostDialogActionNames.ON_CHANGE_TAX_VALUE
          payload: {
              taxId: string
              value: string
          }
      }
    | {
          type: AddExtraCostDialogActionNames.RESET
      }

const useAddExtraCostDialog = () => {
    const {
        selectedStdCostRow: selectedRow,
        selectedDate,
        stdCostData: { components, products },
    } = useBaseStore(stdCostSliceStateSelector)

    const productOrComponent =
        selectedRow && selectedRow?.isProduct
            ? products.entities[selectedRow.id]
            : components.entities[selectedRow!.id]

    const TAXES: Tax[] = useMemo(
        () => [
            {
                groupName: 'PIS',
                inputs: [
                    {
                        name: 'VL_PIS',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_PIS',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'COFINS',
                inputs: [
                    {
                        name: 'VL_COFINS',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_COFINS',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'ICMS',
                inputs: [
                    {
                        name: 'VL_ICMS',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_ICMS',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'IPI',
                inputs: [
                    {
                        name: 'VL_IPI',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_IPI',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'ST',
                inputs: [
                    {
                        name: 'VL_ST',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_ST',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'FCP',
                inputs: [
                    {
                        name: 'VL_FCP',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_FCP',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
        ],
        []
    )

    const initialState = useMemo<AddExtraCostDialogState>(
        () => ({
            taxes: TAXES,
            costValue: '0',
            description: '',
            category: 'FIXO',
            quantity: '0',
        }),
        [TAXES]
    )

    const reducer = useCallback(
        (
            state: typeof initialState,
            action: AddExtraCostDialogActions
        ): typeof initialState => {
            switch (action.type) {
                case AddExtraCostDialogActionNames.ON_CHANGE_COST: {
                    const { cost } = action.payload
                    return {
                        ...state,
                        costValue: cost,
                    }
                }
                case AddExtraCostDialogActionNames.ON_CHANGE_DESCRIPTION: {
                    const { description } = action.payload
                    return {
                        ...state,
                        description,
                    }
                }
                case AddExtraCostDialogActionNames.ON_CHANGE_QUANTITY: {
                    const { quantity } = action.payload
                    return {
                        ...state,
                        quantity: quantity,
                    }
                }
                case AddExtraCostDialogActionNames.ON_CHANGE_TAX_VALUE: {
                    const { taxId, value } = action.payload
                    return {
                        ...state,
                        taxes: state.taxes.map((taxGroup) => {
                            return {
                                ...taxGroup,
                                inputs: taxGroup.inputs.map((input) => {
                                    if (input.name === taxId) {
                                        return {
                                            ...input,
                                            value: value,
                                        }
                                    }
                                    return input
                                }),
                            }
                        }),
                    }
                }
                case AddExtraCostDialogActionNames.RESET:
                    return initialState
                default:
                    return state
            }
        },
        [initialState]
    )

    const [state, dispatch] = useReducer(reducer, initialState)

    return {
        ...state,
        selectedDate,
        selectedRow,
        productOrComponent,
        dispatch,
    }
}
export default useAddExtraCostDialog

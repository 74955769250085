import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import {
    ArrowDownToLine,
    Layers,
    MoreVertical,
    Save,
    Snowflake,
} from 'lucide-react'
import { useStdCostSpreadsheet } from '../../useStdCostSpreadsheet'
import { Noop } from '@/types/utils'

interface StdCostActionsDropdown {
    disabled?: boolean
    onOpenViewDialog: Noop
    onOpenLayoutsDialog: Noop
    onOpenSaveLayoutDialog: Noop
    onOpenFreezeDialog: Noop
    onDownloadSum: Noop
}

const StdCostActionsDropdown = ({
    disabled,
    onOpenViewDialog,
    onOpenLayoutsDialog,
    onOpenSaveLayoutDialog,
    onOpenFreezeDialog,
    onDownloadSum,
}: StdCostActionsDropdown) => {
    const { onDownload } = useStdCostSpreadsheet()

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild disabled={disabled}>
                <Button
                    variant="ghost"
                    className="px-3 hover:text-primary-600 hover:bg-slate-50"
                >
                    <MoreVertical size={16} />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="w-[250px]">
                <DropdownMenuItem
                    className="gap-2 text-neutral-600"
                    onClick={onOpenLayoutsDialog}
                >
                    <Layers size={16} />
                    Visualizar layouts
                    {
                        <div className="flex items-center gap-0.5 ml-auto text-xs">
                            <span className="p-1 rounded-md bg-sky-50 text-sky-600">
                                ctrl
                            </span>
                            <span className="p-1 rounded-md bg-slate-100 text-primary-600">
                                shift
                            </span>
                            <span className="p-1 rounded-md bg-slate-100 text-primary-600">
                                L
                            </span>
                        </div>
                    }
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                    className="gap-2 text-neutral-600"
                    onClick={onOpenSaveLayoutDialog}
                >
                    <Save size={16} />
                    Salvar layout
                    {
                        <div className="flex items-center gap-0.5 ml-auto text-xs">
                            <span className="p-1 rounded-md bg-sky-50 text-sky-600">
                                ctrl
                            </span>
                            <span className="p-1 rounded-md bg-slate-100 text-primary-600">
                                shift
                            </span>
                            <span className="p-1 rounded-md bg-slate-100 text-primary-600">
                                S
                            </span>
                        </div>
                    }
                </DropdownMenuItem>
                <DropdownMenuItem
                    className="gap-2 text-neutral-600"
                    onClick={onOpenViewDialog}
                >
                    <Save size={16} />
                    Salvar visões
                    {
                        <div className="flex items-center gap-0.5 ml-auto text-xs">
                            <span className="p-1 rounded-md bg-sky-50 text-sky-600">
                                ctrl
                            </span>
                            <span className="p-1 rounded-md bg-slate-100 text-primary-600">
                                shift
                            </span>
                            <span className="p-1 rounded-md bg-slate-100 text-primary-600">
                                V
                            </span>
                        </div>
                    }
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                    className="gap-2 text-neutral-600 focus:bg-sky-50 focus:text-sky-500"
                    onClick={onOpenFreezeDialog}
                >
                    <Snowflake size={16} strokeWidth={1.9} />
                    Congelar
                    {
                        <div className="flex items-center gap-1 ml-auto text-xs">
                            <span className="p-1 rounded-md bg-sky-50 text-sky-600">
                                ctrl
                            </span>
                            <span className="p-1 rounded-md bg-sky-50 text-sky-600">
                                shift
                            </span>
                            <span className="p-1 rounded-md bg-sky-50 text-sky-600">
                                G
                            </span>
                        </div>
                    }
                </DropdownMenuItem>
                <DropdownMenuItem
                    className="gap-2 text-neutral-600 focus:bg-emerald-50 focus:text-emerald-500"
                    onClick={onDownload}
                >
                    <ArrowDownToLine size={16} strokeWidth={1.9} />
                    Exportar custo padrão
                    {
                        <div className="flex items-center gap-0.5 ml-auto text-xs">
                            <span className="p-1 rounded-md bg-sky-50 text-sky-600">
                                ctrl
                            </span>
                            <span className="p-1 rounded-md bg-emerald-50 text-emerald-600">
                                shift
                            </span>
                            <span className="p-1 rounded-md bg-emerald-50 text-emerald-600">
                                D
                            </span>
                        </div>
                    }
                </DropdownMenuItem>
                <DropdownMenuItem
                    className="gap-2 text-neutral-600 focus:bg-emerald-50 focus:text-emerald-500"
                    onClick={onDownloadSum}
                >
                    <ArrowDownToLine size={16} strokeWidth={1.9} />
                    Exportar custo pricing
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

export default StdCostActionsDropdown

import { Column } from '@tanstack/react-table'
import { CSSProperties } from 'react'

export const getCommonPinningStyles = <T>(column: Column<T>): CSSProperties => {
    const isPinned = column.getIsPinned()

    return {
        left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
        right:
            isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
        opacity: isPinned ? 0.95 : 1,
        position: isPinned ? 'sticky' : 'relative',
        width: column.getSize(),
        zIndex: isPinned ? 1 : 0,
        backgroundColor: isPinned ? 'white' : undefined,
        border: isPinned ? '0.5px solid #e5e5e5' : undefined,
    }
}

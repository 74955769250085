import { DebouncedInput } from '@/components/DebouncedInput'
import { Column, Table } from '@tanstack/react-table'
import { useState } from 'react'
import Datalist from './components/Datalist'

export const Filter = ({
    column,
}: {
    column: Column<any, unknown>
    table: Table<any>
}) => {
    const [isOpen, setOpen] = useState(false)
    const columnFilterValue = column.getFilterValue()

    return (
        <>
            {isOpen && <Datalist column={column} />}
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '') as string}
                onChange={(value) => column.setFilterValue(value)}
                onFocus={() => setOpen(true)}
                onBlur={() => setOpen(false)}
                placeholder={`Buscar...`}
                className="w-full p-1 border rounded-md border-neutral-300 text-default-text-color text-ellipsis"
                list={isOpen ? column.id + 'list' : undefined}
            />
            <div className="h-1" />
        </>
    )
}

import Table from '@/components/Table'
import { TableCustomData } from '@/components/Table/type'
import { useInformedCostQuery } from '@/queries/useInformedCostQuery'
import { InformedCost } from '@/types/Costs'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns-tz'
import { ReactNode, useMemo } from 'react'
import TableRowActions from '@/components/Table/components/TableRowActions'
import DeleteDialog from './components/DeleteDialog'
import EditDialog from './components/EditDialog'
import AddDialog from './components/AddDialog'
import { isBefore, isEqual, isToday } from 'date-fns'
import DadosMestreHeader from '../components/DadosMestreHeader'
import { useInformedCostSpreadsheet } from './useInformedCostSpreadsheet'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { getDateFromId } from '@/utils/date'
import BulkDialog from './components/BulkDialog'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'
import { useTable } from '@/hooks/useTable'
import DefaultCell from '../components/DefaultCell'

export type InformedCostTable = InformedCost & TableCustomData<InformedCost>

const columnHelper = createColumnHelper<InformedCost>()

const InformedCostDadosMestre = () => {
    const { data, isFetching, isLoading, refetch } = useInformedCostQuery()

    const {
        table,
        tableState,
        isAddDialogOpen,
        isBulkDialogOpen,
        isDeleteDialogOpen,
        isEditDialogOpen,
        selectedRow,
        selectedRows,
        columnOrder,
        onColumnOrderChange,
        onAddDialogClose,
        onBulkDialogClose,
        onBulkDialogOpen,
        onClickAdd,
        onClickDelete,
        onClickEdit,
        onClickMultiDelete,
        onDeleteDialogClose,
        onEditDialogClose,
        setSelectedRows,
        onTableStateChange,
        setTable,
    } = useTable<InformedCost>(
        import.meta.env.VITE_DADOS_MESTRE_INFORMED_COST_TABLE_TOKEN
    )

    const { onDownload } = useInformedCostSpreadsheet(table)

    const memoData = useMemo(
        () =>
            data?.sort((a, b) =>
                isBefore(new Date(a.CREATED_AT), new Date(b.CREATED_AT))
                    ? 1
                    : -1
            ) || [],
        [data]
    )
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('SK_EMPRESA', {
                id: 'sk_empresa',
                header: 'Cod empresa',
                size: 80,
            }),
            columnHelper.accessor('NK_PRODUTO_ESTRUTURA', {
                id: 'sk_produto',
                header: 'Cod Produto',
                size: 100,
            }),
            columnHelper.accessor('PRODUTO_ESTRUTURA', {
                id: 'produto',
                header: 'Produto',
                size: 300,
            }),
            columnHelper.accessor('NK_PRODUTO_COMPONENTE', {
                id: 'sk_componente',
                header: 'Cod Componente',
                size: 100,
            }),
            columnHelper.accessor('PRODUTO_COMPONENTE', {
                id: 'componente',
                header: 'Componente',
                size: 300,
            }),
            columnHelper.accessor(
                ({ VL_CUSTO_ORCADO, DS_MOEDA }) =>
                    currencyFormat(Number(VL_CUSTO_ORCADO || 0), 4, DS_MOEDA),
                {
                    id: 'VL_CUSTO',
                    header: 'Custo',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 120,
                }
            ),
            columnHelper.accessor(
                ({ SK_TEMPO }) => format(getDateFromId(SK_TEMPO), 'MM/yyyy'),
                {
                    id: 'SK_TEMPO',
                    header: 'Data',

                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_PIS }) => VL_PIS, {
                id: 'VL_PIS',
                header: 'Vl PIS',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_PIS }) =>
                    percentageFormat(Number(VL_ALIQUOTA_PIS), 4),
                {
                    id: 'VL_ALIQUOTA_PIS',
                    header: 'PIS (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_COFINS }) => VL_COFINS, {
                id: 'VL_COFINS',
                header: 'Vl COFINS',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_COFINS }) =>
                    percentageFormat(Number(VL_ALIQUOTA_COFINS || 0), 4),
                {
                    id: 'VL_ALIQUOTA_COFINS',
                    header: 'COFINS (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_FCP }) => VL_FCP, {
                id: 'VL_FCP',
                header: 'Vl FCP',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_FCP }) =>
                    percentageFormat(Number(VL_ALIQUOTA_FCP), 4),
                {
                    id: 'VL_ALIQUOTA_FCP',
                    header: 'FCP (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_ICMS }) => VL_ICMS, {
                id: 'VL_ICMS',
                header: 'Vl ICMS',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_ICMS }) =>
                    percentageFormat(Number(VL_ALIQUOTA_ICMS), 4),
                {
                    id: 'VL_ALIQUOTA_ICMS',
                    header: 'ICMS (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_IPI }) => VL_IPI, {
                id: 'VL_IPI',
                header: 'Vl IPI',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_IPI }) =>
                    percentageFormat(Number(VL_ALIQUOTA_IPI), 4),
                {
                    id: 'VL_ALIQUOTA_IPI',
                    header: 'IPI (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_ST }) => VL_ST, {
                id: 'VL_ST',
                header: 'Vl ST',
                cell: ({ getValue }) => {
                    return getValue()
                },
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_ST }) =>
                    percentageFormat(Number(VL_ALIQUOTA_ST), 4),
                {
                    id: 'VL_ALIQUOTA_ST',
                    header: 'ST (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(
                ({ CREATED_AT }) =>
                    format(new Date(CREATED_AT), 'dd/MM/yyyy HH:mm:ss'),
                {
                    id: 'CREATED_AT',
                    header: 'Data de criação',

                    size: 150,
                }
            ),

            columnHelper.accessor(
                ({ UPDATED_AT }) =>
                    format(new Date(UPDATED_AT), 'dd/MM/yyyy HH:mm:ss', {
                        timeZone: 'America/Sao_Paulo',
                    }),
                {
                    id: 'UPDATED_AT',
                    header: 'Data de atualização',
                }
            ),
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    return (
                        <TableRowActions
                            onClickDelete={() => onClickDelete(row)}
                            onClickEdit={() => onClickEdit(row)}
                        />
                    )
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.display({
                id: 'badge',
                cell: ({ row, column }) => {
                    const { CREATED_AT, UPDATED_AT } = row.original
                    let Badge: ReactNode | null = null
                    const createdAt = new Date(CREATED_AT)
                    const updatedAt = new Date(UPDATED_AT)

                    if (isEqual(createdAt, updatedAt)) {
                        if (isToday(createdAt)) {
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-md text-emerald-500 ">
                                    Novo *
                                </span>
                            )
                        }
                    } else {
                        Badge = (
                            <span className="px-2 py-1 text-xs rounded-md text-zinc-400">
                                Editado
                            </span>
                        )
                    }
                    column.columnDef.meta = {
                        cell: {
                            className: 'flex items-center justify-center',
                        },
                    }

                    return Badge
                },
                size: 80,
                enableColumnFilter: false,
                enableSorting: false,
            }),
        ],
        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<InformedCostTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_NOVO_CUSTO.toString()}
                isLoading={isLoading}
                isFetching={isFetching}
                getTableInstance={(table) => setTable(table)}
                rowSelection={selectedRows}
                onRowSelectionChange={setSelectedRows}
                tableState={tableState}
                columnOrderState={columnOrder}
                onColumnOrderStateChange={onColumnOrderChange}
                onTableStateChange={onTableStateChange}
                tableHeader={
                    <DadosMestreHeader
                        title={`Custos informados ${
                            data ? `(${data.length})` : ''
                        }`}
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onAdd={onClickAdd}
                        onDownload={() => onDownload()}
                        onBulk={onBulkDialogOpen}
                        onRefech={refetch}
                    />
                }
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultCell>{getValue() as string}</DefaultCell>
                    ),
                }}
                virtualize
                stripe
            />
            {isDeleteDialogOpen && (
                <DeleteDialog
                    ids={
                        selectedRow
                            ? [selectedRow.original.SK_NOVO_CUSTO]
                            : table
                            ? table
                                  .getFilteredRowModel()
                                  .flatRows.filter(
                                      (row) => selectedRows[row.id]
                                  )
                                  .map((row) => row.original.SK_NOVO_CUSTO)
                            : []
                    }
                    isOpen={isDeleteDialogOpen}
                    onClose={onDeleteDialogClose}
                />
            )}
            {selectedRow && isEditDialogOpen && (
                <EditDialog
                    row={selectedRow}
                    isOpen={isEditDialogOpen}
                    onClose={onEditDialogClose}
                />
            )}
            {isAddDialogOpen && (
                <AddDialog
                    isOpen={isAddDialogOpen}
                    onClose={onAddDialogClose}
                />
            )}
            {isBulkDialogOpen && table ? (
                <BulkDialog
                    isOpen={isBulkDialogOpen}
                    onClose={onBulkDialogClose}
                />
            ) : null}
        </div>
    )
}

export default InformedCostDadosMestre

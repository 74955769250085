import { cn } from '@/lib/utils'
import { View } from '@/types/StandardCost'
import { Layers, Trash, X } from 'lucide-react'
import { KeyboardEvent, useRef, useState } from 'react'

interface LayoutItemProps extends View {
    onClick: () => void
    onClickDelete: () => void
}

const LayoutItem = ({
    NM_CONFIGURACAO_EXIBICAO,
    SK_CONFIGURACAO_EXIBICAO,
    onClick,
    onClickDelete,
}: LayoutItemProps) => {
    const [isDelete, setIsDelete] = useState(false)
    const ref = useRef<HTMLDivElement | null>(null)

    const onKeyDown = (e: KeyboardEvent) => {
        if (document.activeElement === ref.current && e.key == 'Enter') {
            onClick()
        }
    }

    return (
        <div
            ref={ref}
            key={SK_CONFIGURACAO_EXIBICAO}
            tabIndex={0}
            className={cn(
                'flex items-center justify-between w-full p-4 rounded-md cursor-pointer',
                isDelete
                    ? 'bg-red-50 text-red-500'
                    : 'hover:bg-slate-100 hover:text-primary-600 group'
            )}
            onClick={onClick}
            onKeyDown={onKeyDown}
        >
            <div className="flex items-center flex-1 gap-2 overflow-hidden">
                <Layers size={16} className="shrink-0" />
                <p className="overflow-hidden whitespace-nowrap text-ellipsis">
                    {NM_CONFIGURACAO_EXIBICAO}
                </p>
            </div>
            {isDelete ? (
                <div>
                    <button
                        className="flex items-center justify-center w-6 h-6 text-red-500 rounded-md hover:bg-red-100"
                        onClick={(e) => {
                            e.stopPropagation()
                            onClickDelete()
                        }}
                    >
                        <Trash size={14} />
                    </button>
                </div>
            ) : (
                <div className="hidden group-hover:block">
                    <button
                        className="flex items-center justify-center w-6 h-6 rounded-md text-slate-400 hover:bg-slate-200"
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsDelete(true)
                        }}
                    >
                        <X size={14} />
                    </button>
                </div>
            )}
        </div>
    )
}

export default LayoutItem

import { ReactNode } from 'react'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '../ui/tooltip'
import Button, { ButtonProps } from '../Button'

interface ButtonWithTooltipProps extends ButtonProps {
    tooltipContent?: ReactNode
    align?: 'start' | 'center' | 'end'
}

const ButtonWithTooltip = ({
    tooltipContent,
    children,
    align,
    ...props
}: ButtonWithTooltipProps) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button {...props} variant="icon" size="icon">
                        {children}
                    </Button>
                </TooltipTrigger>
                <TooltipContent
                    align={align}
                    className="text-white border-none bg-neutral-600"
                >
                    {tooltipContent}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}

export default ButtonWithTooltip

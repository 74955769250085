import Table from '@/components/Table'
import { TableCustomData } from '@/components/Table/type'
import { useProductQuery } from '@/queries/useProductQuery'
import { Product } from '@/types/Product'
import { createColumnHelper } from '@tanstack/react-table'
import { ReactNode, useMemo } from 'react'
import DadosMestreHeader from '../components/DadosMestreHeader'
import { useProductSpreadsheet } from './useProductSpreadsheet'
import TableRowActions from '@/components/Table/components/TableRowActions'
import DeleteDialog from './components/DeleteDialog'
import EditDialog from './components/EditDialog'
import AddDialog from './components/AddDialog'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'
import { useTable } from '@/hooks/useTable'

export type ProductTable = Product & TableCustomData<Product>

const columnHelper = createColumnHelper<Product>()

const ProductDadosMestre = () => {
    const { data, isFetching, isLoading, refetch } = useProductQuery({
        enabled: true,
    })

    const {
        table,
        tableState,
        selectedRow,
        selectedRows,
        isAddDialogOpen,
        isDeleteDialogOpen,
        isEditDialogOpen,
        columnOrder,
        onColumnOrderChange,
        onAddDialogClose,
        onClickAdd,
        onClickDelete,
        onClickEdit,
        onClickMultiDelete,
        onDeleteDialogClose,
        onEditDialogClose,
        onTableStateChange,
        setSelectedRows,
        setTable,
    } = useTable<ProductTable>(
        import.meta.env.VITE_DADOS_MESTRE_PRODUCT_TABLE_TOKEN
    )

    const { onDownload } = useProductSpreadsheet()

    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('SK_EMPRESA', {
                id: 'sk_empresa',
                header: 'Cod empresa',
                size: 80,
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'empresa',
                header: 'Empresa',
                size: 150,
            }),
            columnHelper.accessor('NK_PRODUTO', {
                id: 'sk_produto',
                header: 'Cod produto',
                size: 100,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'produto',
                header: 'Produto',
                size: 300,
            }),
            columnHelper.accessor('DD_UM', {
                id: 'um',
                header: 'UM',
                size: 100,
            }),
            columnHelper.accessor('DD_CLASSIFICACAO', {
                id: 'classificacao',
                header: 'Classificação',
                size: 100,
            }),
            columnHelper.accessor('DD_GRUPO', {
                id: 'grupo',
                header: 'Grupo',
                size: 100,
            }),
            columnHelper.accessor('DD_TAMANHO_EMBALAGEM', {
                id: 'DD_TAMANHO_EMBALAGEM',
                header: 'Tamanho da embalagem',
                size: 100,
            }),
            columnHelper.accessor('QTD_VOLUME', {
                id: 'QTD_VOLUME',
                header: 'Volume',
                size: 100,
            }),
            columnHelper.accessor('DD_ORIGEM_DADO', {
                id: 'origem',
                header: 'Origem',
                cell: ({ getValue }) => {
                    const value = getValue()
                    let Badge: ReactNode | null = null

                    switch (value) {
                        case 'MANUAL':
                            Badge = (
                                <span className="px-2 py-1 text-xs text-green-600 rounded-md bg-green-50">
                                    {value}
                                </span>
                            )
                            break
                        case 'PROTHEUS':
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-md text-cyan-600 bg-blue-50">
                                    {value}
                                </span>
                            )

                            break
                    }

                    return Badge
                },
                meta: {
                    cell: {
                        className: 'flex items-center justify-center',
                    },
                },
                size: 100,
            }),
            columnHelper.accessor(
                ({ DD_BLOQUEADO }) => (DD_BLOQUEADO === 1 ? 'Sim' : 'Não'),
                {
                    id: 'bloqueado',
                    header: 'Bloqueado',
                    size: 100,
                }
            ),
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    const { DD_ORIGEM_DADO } = row.original
                    return (
                        DD_ORIGEM_DADO === 'MANUAL' && (
                            <TableRowActions
                                onClickDelete={() => onClickDelete(row)}
                                onClickEdit={() => onClickEdit(row)}
                            />
                        )
                    )
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
        ],
        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<ProductTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_PRODUTO.toString()}
                isLoading={isLoading}
                isFetching={isFetching}
                getTableInstance={(table) => setTable(table)}
                onRowSelectionChange={setSelectedRows}
                rowSelection={selectedRows}
                columnOrderState={columnOrder}
                onColumnOrderStateChange={onColumnOrderChange}
                tableState={tableState}
                onTableStateChange={onTableStateChange}
                tableHeader={
                    <DadosMestreHeader
                        title={`Produtos ${data ? `(${data.length})` : ''}`}
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onDownload={() => table && onDownload(table)}
                        onRefech={refetch}
                        onAdd={onClickAdd}
                    />
                }
                virtualize
                stripe
            />
            {isDeleteDialogOpen && (
                <DeleteDialog
                    ids={
                        selectedRow
                            ? [selectedRow.original.SK_PRODUTO]
                            : table
                            ? table
                                  .getFilteredRowModel()
                                  .flatRows.filter(
                                      (row) => selectedRows[row.id]
                                  )
                                  .map((row) => row.original.SK_PRODUTO)
                            : []
                    }
                    isOpen={isDeleteDialogOpen}
                    onClose={onDeleteDialogClose}
                />
            )}
            {selectedRow && (
                <EditDialog
                    row={selectedRow}
                    isOpen={isEditDialogOpen}
                    onClose={onEditDialogClose}
                />
            )}
            <AddDialog isOpen={isAddDialogOpen} onClose={onAddDialogClose} />
        </div>
    )
}

export default ProductDadosMestre

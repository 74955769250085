import { ScrollArea } from '@/components/ui/scroll-area'
import { ProductCustomEntity } from '@/types/StandardCost'
import Component from './components/Component'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { useMemo } from 'react'

interface ComponentsSectionProps {
    product: ProductCustomEntity
}
const stateSelector = (state: Store) =>
    state.stdCostSlice.state.stdCostData.components

const ComponentsSection = ({ product }: ComponentsSectionProps) => {
    const componentsIds = product.COMPONENTES

    const components = useBaseStore(stateSelector)

    const productComponents = useMemo(
        () => componentsIds.map((id) => components.entities[id]),
        [product]
    )

    const extraCosts = useMemo(
        () =>
            productComponents.filter(
                (component) =>
                    component.ESTRUTURA === 'EXTRA-COMPONENTE' ||
                    component.ESTRUTURA == 'EXTRA-PRODUTO'
            ),
        [product]
    )

    const notExtraCosts = useMemo(
        () =>
            productComponents
                .filter(
                    (component) =>
                        component.ESTRUTURA !== 'EXTRA-COMPONENTE' &&
                        component.ESTRUTURA !== 'EXTRA-PRODUTO'
                )
                .sort((a, b) => {
                    if (
                        a.productUsedAsComponentId &&
                        !b.productUsedAsComponentId
                    ) {
                        return -1
                    } else if (
                        !a.productUsedAsComponentId &&
                        b.productUsedAsComponentId
                    ) {
                        return 1
                    } else {
                        return 0
                    }
                }),
        [product]
    )

    return (
        <div className="w-[40%] flex flex-col">
            <ScrollArea className="flex-1 w-full px-4">
                <div className="w-full">
                    <div className="p-2">
                        <h2 className="font-semibold">Componentes</h2>
                    </div>
                    <div className="w-full overflow-hidden">
                        {notExtraCosts.map((component) => (
                            <Component key={component._id} id={component._id} />
                        ))}
                    </div>
                </div>

                {extraCosts.length > 0 && (
                    <div>
                        <div className="p-2">
                            <h2 className="font-semibold">Custos extras</h2>
                        </div>
                        {extraCosts.map((component) => (
                            <Component key={component._id} id={component._id} />
                        ))}
                    </div>
                )}
                <div className="p-4" />
            </ScrollArea>
        </div>
    )
}

export default ComponentsSection

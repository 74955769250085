import { lastDayOfMonth } from 'date-fns'
import { useCallback, useReducer } from 'react'

export interface AddDialogState {
    currency: string
    value: string
    date: {
        from: Date
        to: Date
    }
}
export enum AddDialogActionNames {
    ON_CHANGE_DATE = 'ON_CHANGE_DATE',
    ON_CHANGE_CURRENCY = 'ON_CHANGE_CURRENCY',
    ON_CHANGE_VALUE = 'ON_CHANGE_VALUE',
    RESET = 'RESET',
}

type AddDialogActions =
    | {
          type: AddDialogActionNames.ON_CHANGE_DATE
          payload: {
              from: Date
              to: Date
          }
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_CURRENCY
          payload: {
              currency: string
          }
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_VALUE
          payload: {
              value: string
          }
      }
    | {
          type: AddDialogActionNames.RESET
      }

const useAddDialog = (initialState: AddDialogState) => {
    const reducer = useCallback(
        (
            state: typeof initialState,
            action: AddDialogActions
        ): typeof initialState => {
            switch (action.type) {
                case AddDialogActionNames.ON_CHANGE_CURRENCY:
                    return {
                        ...state,
                        currency: action.payload.currency,
                    }
                case AddDialogActionNames.ON_CHANGE_VALUE:
                    return {
                        ...state,
                        value: action.payload.value,
                    }
                case AddDialogActionNames.ON_CHANGE_DATE:
                    return {
                        ...state,
                        date: {
                            from: action.payload.from,
                            to: lastDayOfMonth(action.payload.to),
                        },
                    }
                case AddDialogActionNames.RESET:
                    return initialState
                default:
                    return state
            }
        },
        [initialState]
    )

    const [state, dispatch] = useReducer(reducer, initialState)

    return {
        ...state,
        dispatch,
    }
}
export default useAddDialog

import { cn } from '@/lib/utils'
import { motion, AnimatePresence } from 'framer-motion'

interface TopMessageProps {
    text: string
    type: 'error' | 'success' | 'loading'
    className?: string
}

const TopMessage = ({ text, type = 'success', className }: TopMessageProps) => {
    return (
        <AnimatePresence>
            <motion.div
                className={cn(
                    'mb-4 w-full py-1 text-center text-xs',
                    type === 'success' && 'text-green-500 bg-green-100',
                    type === 'loading' && 'bg-primary-50 text-primary-500',
                    type === 'error' && 'text-red-500 bg-red-100',
                    className
                )}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
            >
                {text}
            </motion.div>
        </AnimatePresence>
    )
}

export default TopMessage

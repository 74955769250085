import { cn } from '@/lib/utils'
import { AcceptTypes, ProductParent } from '@/store/productStructureSlice/type'
import { Product } from '@/types/Product'
import { Box } from 'lucide-react'
import { HTMLAttributes } from 'react'
import { useDrag } from 'react-dnd'

interface DraggableProductProps extends HTMLAttributes<HTMLDivElement> {
    product: Product
}

const DraggableProduct = ({ product, ...props }: DraggableProductProps) => {
    const [{ isDragging }, drag] = useDrag<
        ProductParent,
        unknown,
        { isDragging: boolean }
    >(
        () => ({
            type: AcceptTypes.PRODUCT,
            item: {
                ...product,
                parentId: null,
                id: product.SK_PRODUTO,
                type: AcceptTypes.PRODUCT,
                products: [],
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }),
        [product]
    )

    return (
        <div
            ref={drag}
            {...props}
            className={cn(
                'flex gap-4 absolute w-full items-center px-2 py-2 text-xs rounded-md hover:bg-slate-100 cursor-pointer overflow-hidden',
                isDragging &&
                    'text-neutral-300 hover:bg-slate-50 cursor-grabbing'
            )}
        >
            <div>
                <Box size={18} />
            </div>
            <div className="flex-1 -space-y-1 overflow-hidden pointer-events-none">
                <div className="flex items-center gap-2">
                    <p className="w-full overflow-hidden text-sm whitespace-nowrap text-ellipsis pointer">
                        {product.NK_PRODUTO} - {product.DS_PRODUTO}
                    </p>
                    <span
                        className={cn(
                            'px-2 py-1 rounded-md',
                            product.DD_ORIGEM_DADO === 'PROTHEUS'
                                ? 'bg-cyan-50 text-cyan-600'
                                : 'bg-green-50 text-green-500'
                        )}
                    >
                        {product.DD_ORIGEM_DADO}
                    </span>
                </div>
                <p
                    className={cn(
                        'text-xs text-neutral-500',
                        isDragging && 'text-neutral-300'
                    )}
                >
                    {product.ABREVIATURA_EMPRESA}
                </p>
            </div>
        </div>
    )
}

export default DraggableProduct

import {
    ColumnFiltersState,
    ColumnOrderState,
    ExpandedState,
    PaginationState,
    Row,
    RowSelectionState,
    SortingState,
    TableState,
    getCoreRowModel,
    getExpandedRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { useEffect, useMemo, useRef, useState } from 'react'
import TopMessage from '../TopMessage'
import { useVirtualizer } from '@tanstack/react-virtual'
import { cn } from '@/lib/utils'
import TableSettings from './components/TableSettings'
import TableHeader from './components/TableColumnHeader'
import TableCell from './components/TableCell'
import { TableCustomData, TableProps } from './type'
import { Checkbox } from '../ui/checkbox'
import TablePagination from './components/TablePagination'
import {
    arrayMove,
    SortableContext,
    horizontalListSortingStrategy,
} from '@dnd-kit/sortable'
import { type DragEndEvent, useDndMonitor } from '@dnd-kit/core'

const Table = <T extends TableCustomData<T>>({
    columns,
    data,
    isError,
    errorMessage,
    selectedRowId,
    defaultColumn,
    isFetching,
    isLoading,
    virtualize,
    rowSelection,
    tableHeader,
    tableActions,
    expandedState,
    columnOrderState,
    tableState,
    meta,
    paginationState,
    columnFiltersState,
    enableRowSelection,
    onPaginationChange,
    onColumnFiltersChange,
    onTableStateChange,
    onExpandedStateChange,
    onRowSelectionChange,
    onColumnOrderStateChange,
    getTableInstance,
    getRowId,
    onRowClick,
}: TableProps<T>) => {
    const tableRef = useRef<HTMLDivElement | null>(null)
    const [sorting, setSorting] = useState<SortingState>([])
    const [expanded, setExpanded] = useState<ExpandedState>(expandedState || {})
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
        columnFiltersState || []
    )
    const [selectedRows, setSelectedRows] = useState<RowSelectionState>(
        rowSelection || {}
    )
    const [pagination, setPagination] = useState<PaginationState>(
        paginationState || { pageIndex: 0, pageSize: 0 }
    )

    const columnsMemo = useMemo(
        () =>
            rowSelection
                ? [
                      {
                          id: 'Seleção',
                          header: ({ table }) => (
                              <Checkbox
                                  className={cn('w-5 h-5 rounded-full mx-auto')}
                                  checked={table.getIsAllRowsSelected()}
                                  onClick={
                                      pagination
                                          ? table.getToggleAllPageRowsSelectedHandler()
                                          : table.getToggleAllRowsSelectedHandler()
                                  }
                              />
                          ),
                          cell: ({ row }) => {
                              return (
                                  row.getCanSelect() && (
                                      <Checkbox
                                          className={cn(
                                              'hidden w-5 h-5 rounded-full group-hover:block',
                                              row.getIsSelected() && 'block'
                                          )}
                                          disabled={!row.getCanSelect()}
                                          checked={row.getIsSelected()}
                                          onCheckedChange={(value) =>
                                              row.toggleSelected(!!value)
                                          }
                                      />
                                  )
                              )
                          },
                          size: 80,
                          meta: {
                              enableMenu: false,
                              header: {
                                  className: 'px-4 py-2',
                              },
                          },
                      },
                      ...columns,
                  ]
                : columns,
        [columns]
    )

    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
        columnOrderState || []
    )

    const state: Partial<TableState> = useMemo(
        () => ({
            ...tableState,
            sorting,
            columnFilters: columnFiltersState || columnFilters,
            expanded: expandedState || expanded,
            rowSelection: rowSelection || selectedRows,
            columnOrder: columnOrderState || columnOrder,
        }),

        [
            tableState,
            rowSelection,
            expandedState,
            columnOrderState,
            columnFiltersState,
            selectedRows,
            sorting,
            columnFilters,
            columnOrder,
            expanded,
        ]
    )

    const table = useReactTable({
        data,
        columns: columnsMemo,
        state,
        meta,
        manualPagination: true,
        enableRowSelection,
        initialState: {
            columnOrder: columnsMemo.map((column) => column.id || ''),
        },
        onStateChange: (updaterOrValue) => {
            if (!onTableStateChange) return

            if (typeof updaterOrValue === 'function') {
                if (tableState) {
                    onTableStateChange(
                        updaterOrValue({
                            ...tableState,
                            pagination: {
                                pageIndex: 0,
                                pageSize: 0,
                            },
                        })
                    )
                }
            } else {
                onTableStateChange(updaterOrValue)
            }
        },
        filterFromLeafRows: true,
        columnResizeMode: 'onChange',
        getRowId,
        getSubRows: (row) => row.subRows as T[],
        onColumnOrderChange: (updaterOrValue) => {
            if (!onColumnOrderStateChange) return setColumnOrder

            if (typeof updaterOrValue === 'function') {
                if (columnOrderState) {
                    onColumnOrderStateChange(updaterOrValue(columnOrderState))
                }
            } else {
                onColumnOrderStateChange(updaterOrValue)
            }
        },
        onRowSelectionChange: (updaterOrValue) => {
            if (!onRowSelectionChange) return setSelectedRows

            if (typeof updaterOrValue === 'function') {
                if (rowSelection) {
                    onRowSelectionChange(updaterOrValue(rowSelection))
                }
            } else {
                onRowSelectionChange(updaterOrValue)
            }
        },
        onExpandedChange: (updaterOrValue) => {
            if (!onExpandedStateChange) return setExpanded

            if (typeof updaterOrValue === 'function') {
                if (expandedState) {
                    onExpandedStateChange(updaterOrValue(expandedState))
                }
            } else {
                onExpandedStateChange(updaterOrValue)
            }
        },
        onPaginationChange: (updaterOrValue) => {
            if (!onPaginationChange) return setPagination

            if (typeof updaterOrValue === 'function') {
                if (pagination) {
                    onPaginationChange(updaterOrValue(pagination))
                }
            } else {
                onPaginationChange(updaterOrValue)
            }
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: (updaterOrValue) => {
            if (!onColumnFiltersChange) return setColumnFilters(updaterOrValue)

            if (typeof updaterOrValue === 'function') {
                if (columnFiltersState) {
                    onColumnFiltersChange(updaterOrValue(columnFiltersState))
                }
            } else {
                onColumnFiltersChange(updaterOrValue)
            }
        },
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getPaginationRowModel: getPaginationRowModel(),
        defaultColumn,
    })

    const rowVirtualizer = useVirtualizer({
        getScrollElement: () => tableRef.current,
        estimateSize: () => 40,
        count: table.getExpandedRowModel().rows.length,
    })

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event
        if (active && over && active.id !== over.id) {
            table.setColumnOrder((columnOrder) => {
                const oldIndex = columnOrder.indexOf(active.id as string)
                const newIndex = columnOrder.indexOf(over.id as string)
                return arrayMove(columnOrder, oldIndex, newIndex)
            })
        }
    }

    useDndMonitor({
        onDragEnd: handleDragEnd,
    })

    const { getVirtualItems, getTotalSize } = rowVirtualizer

    const tableRows = table.getRowModel().rows
    const rows = virtualize ? getVirtualItems() : tableRows

    useEffect(() => {
        if (getTableInstance) getTableInstance(table)
    }, [])

    useEffect(() => {
        table.setColumnOrder(columnsMemo.map((column) => column.id || ''))
    }, [columnsMemo])

    return (
        <div className="flex flex-col w-full h-full overflow-hidden">
            <div>
                {tableHeader || tableActions ? (
                    <div
                        className={cn(
                            'flex items-end justify-between w-full',
                            tableHeader && tableActions ? 'mb-4' : ''
                        )}
                    >
                        {tableHeader && <div>{tableHeader}</div>}
                        {tableActions && (
                            <div className="flex items-center h-full gap-1.5">
                                {tableActions}
                                <TableSettings
                                    table={table}
                                    columnOrder={table.getState().columnOrder}
                                />
                            </div>
                        )}
                    </div>
                ) : null}
                {isLoading && (
                    <TopMessage
                        text="Carregando dados..."
                        type="loading"
                        className="text-green-500 bg-green-100"
                    />
                )}
                {isFetching && !isLoading ? (
                    <TopMessage text="Atualizando dados..." type="loading" />
                ) : null}
                {isError ? (
                    <TopMessage
                        text={errorMessage || 'Erro ao carregar dados'}
                        type="error"
                    />
                ) : null}
            </div>
            <div
                ref={virtualize ? tableRef : null}
                className="relative flex-1 w-full overflow-auto"
            >
                {/* table */}
                <div
                    className="flex flex-col min-w-full"
                    style={{
                        width: table.getTotalSize(),
                    }}
                >
                    {/* thead */}
                    <div className="sticky top-0 z-10 bg-white">
                        {table.getHeaderGroups().map((headerGroup) => (
                            // tr
                            <div className="flex" key={headerGroup.id}>
                                <SortableContext
                                    items={columnOrder!}
                                    strategy={horizontalListSortingStrategy}
                                >
                                    {headerGroup.headers.map((header) => (
                                        // th
                                        <TableHeader
                                            key={header.id}
                                            header={header}
                                            table={table}
                                        />
                                    ))}
                                </SortableContext>
                            </div>
                        ))}
                    </div>

                    {/* tbody */}
                    <div
                        className="relative"
                        {...(virtualize && {
                            style: {
                                height: `${getTotalSize()}px`,
                            },
                        })}
                    >
                        {rows.map((item, idx) => {
                            const row = virtualize
                                ? (tableRows[item.index] as Row<T>)
                                : (item as Row<T>)

                            if (!row) return null

                            const selectedTableRow = table
                                .getRowModel()
                                .rows.find((trow) => trow?.id === selectedRowId)

                            const isSelected =
                                selectedTableRow &&
                                (selectedTableRow?.id === row?.id ||
                                    row
                                        ?.getParentRows()
                                        .some(
                                            (parentRow) =>
                                                parentRow.id ===
                                                selectedTableRow?.id
                                        ))

                            const hasParentRow = row?.depth !== 0

                            const isParentRow =
                                !hasParentRow &&
                                selectedTableRow?.id === row?.id

                            const isLastRow =
                                hasParentRow &&
                                row?.id ===
                                    selectedTableRow?.subRows[
                                        selectedTableRow?.subRows.length - 1
                                    ]?.id
                            return (
                                // tr
                                <div
                                    {...(virtualize && {
                                        style: {
                                            height: `${item.size}px`,
                                            transform: `translateY(${item.start}px)`,
                                            position: 'absolute',
                                        },
                                    })}
                                    className={cn(
                                        'flex w-full group',
                                        onRowClick ? 'cursor-pointer' : '',
                                        idx % 2 === 0 ? '' : 'bg-slate-50',
                                        isSelected || row.getIsSelected()
                                            ? 'bg-slate-200/80 hover:bg-slate-300'
                                            : 'hover:bg-slate-200',
                                        isParentRow &&
                                            'rounded-tl-lg rounded-tr-lg',
                                        isLastRow &&
                                            'rounded-bl-lg rounded-br-lg',
                                        meta?.row?.className
                                    )}
                                    key={row?.id}
                                    onClick={() =>
                                        onRowClick ? onRowClick({ row }) : null
                                    }
                                >
                                    {row?.getVisibleCells().map((cell) => {
                                        return (
                                            // td
                                            <SortableContext
                                                key={cell.id}
                                                items={columnOrder!}
                                                strategy={
                                                    horizontalListSortingStrategy
                                                }
                                            >
                                                <TableCell
                                                    key={cell.id}
                                                    table={table}
                                                    cell={cell}
                                                    isRowSelected={
                                                        cell.row.getIsSelected() ||
                                                        !!isSelected
                                                    }
                                                />
                                            </SortableContext>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {paginationState && onPaginationChange && (
                <TablePagination
                    totalItems={paginationState.totalItems}
                    totalPages={paginationState.totalPages}
                    selectedPage={paginationState.pageIndex}
                    onPageChange={(page) =>
                        onPaginationChange({
                            pageIndex: page,
                            pageSize: paginationState.pageSize,
                        })
                    }
                />
            )}
        </div>
    )
}

export default Table

import { CostView } from '@/types/Product'
import {
    ColumnOrderState,
    ExpandedState,
    TableState,
} from '@tanstack/react-table'
import { getIdFromDate } from '@/utils/date'
import {
    ComponentCustomNormalized,
    StandardCostTableData,
} from '@/types/StandardCost'
import { setItem } from '@/utils/storage'
import {
    CostViewState,
    StdCost,
    StdCostSlice,
    StdCostState,
    StdCostTableState,
} from './type'
import { ImmerStateCreator, Store } from '../type'
import StdCostWorker from '@/workers/stdCostWorker?worker'
import { WorkerTypes } from '@/workers/type'
import { current } from 'immer'

const initialState: StdCostState = {
    isInitialStdCostFetch: true,
    stdCostSelectedCompany: '',
    isDetailsOpen: false,
    stdCostData: {
        components: { entities: {}, ids: [] },
        products: { entities: {}, ids: [] },
    },
    tableInstance: null,
    companies: [],
    initialCostViews: [],
    changedViews: {},
    selectedDate: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        id: Number(
            getIdFromDate(
                new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            ).join('')
        ),
    },
    stdCostTableState: (() => {
        try {
            const state: StdCostTableState = JSON.parse(
                localStorage.getItem(
                    import.meta.env.VITE_STANDARD_COST_TABLE_TOKEN
                ) ||
                    JSON.stringify({
                        columnOrderState: [] as ColumnOrderState,
                        expandedState: {} as ExpandedState,
                        state: {} as TableState,
                    })
            )

            const filtered = state.columnOrderState?.filter(Boolean)

            state.columnOrderState =
                filtered && filtered?.length > 0 ? filtered : undefined

            return state
        } catch (err) {
            return {
                columnOrderState: [] as ColumnOrderState,
                expandedState: {} as ExpandedState,
                state: {} as TableState,
            }
        }
    })(),
    selectedStdCostRow: undefined,
    isAnualAvg: false,
    isCalculating: false,
}

export const useStdCostSlice: ImmerStateCreator<StdCostSlice> = (set, get) => ({
    state: initialState,
    actions: {
        setStdCostData: (data, costViews) => {
            const uniqueCompaniesIds = [
                ...new Set(
                    Object.values(data.products.entities).map(
                        (d) => d.SK_EMPRESA
                    )
                ),
            ]

            const currentCostView = costViews
                .filter(
                    (cost) => cost.DD_TIPO_CONFIGURACAO === 'VISAO_CUSTO_PADRAO'
                )
                .find(
                    (costView) =>
                        costView.SK_TEMPO ===
                        get().stdCostSlice.state.selectedDate.id
                )

            const viewObj: CostViewState =
                currentCostView?.DS_CONFIGURACAO_EXIBICAO
                    ? JSON.parse(currentCostView.DS_CONFIGURACAO_EXIBICAO)
                    : {}

            const companies = uniqueCompaniesIds
                .map((id) => ({
                    ABREVIATURA_EMPRESA: Object.values(
                        data.products.entities
                    ).find((d) => d.SK_EMPRESA === id)!.ABREVIATURA_EMPRESA,
                    SK_EMPRESA: id,
                }))
                .sort((a, b) =>
                    a.ABREVIATURA_EMPRESA.localeCompare(b.ABREVIATURA_EMPRESA)
                )

            const components: ComponentCustomNormalized = {
                entities: {},
                ids: [],
            }

            for (const id of data.components.ids) {
                const component = data.components.entities[id]

                let selectedValue = component.ULTIMO_PRECO || 0
                let view: CostView = CostView.ULTIMO_PRECO

                const selectedViewDate =
                    get().stdCostSlice.state.changedViews[
                        get().stdCostSlice.state.selectedDate.id
                    ] || viewObj[get().stdCostSlice.state.selectedDate.id]

                const selectedView: CostView | undefined = selectedViewDate
                    ? selectedViewDate[id]?.updated
                    : undefined

                const productUsedAsComponent = Object.values(
                    data.products.entities
                ).find(
                    (product) =>
                        product.NK_PRODUTO_ESTRUTURA ===
                            component.NK_PRODUTO_COMPONENTE &&
                        product.SK_EMPRESA === component.SK_EMPRESA
                )

                if (
                    component.ESTRUTURA === 'EXTRA-COMPONENTE' ||
                    component.ESTRUTURA === 'EXTRA-PRODUTO'
                ) {
                    selectedValue = component.ULTIMO_PRECO
                    view = CostView.CUSTO_EXTRA
                } else if (productUsedAsComponent) {
                    selectedValue = component.ULTIMO_PRECO
                    view = CostView.CUSTO_PADRAO
                } else if (selectedView) {
                    view = selectedView

                    switch (selectedView) {
                        case CostView.ULTIMO_PRECO:
                            selectedValue = component.ULTIMO_PRECO
                            break
                        case CostView.CUSTO_INFORMADO:
                            selectedValue =
                                Number(component.VALOR_INFORMADO_COMPONENTE) ||
                                0
                            break
                        default:
                            break
                    }
                } else if (component.VALOR_INFORMADO_COMPONENTE) {
                    view = CostView.CUSTO_INFORMADO
                    selectedValue = Number(component.VALOR_INFORMADO_COMPONENTE)
                }

                components.entities[id] = {
                    ...component,
                    productUsedAsComponentId:
                        productUsedAsComponent?._id || null,
                    CUSTO_SELECIONADO: selectedValue,
                    VISAO_SELECIONADA: view,
                    TIPO_VISAO: view,
                }
                components.ids = [...components.ids, id]
            }

            const initialCostViews = costViews
                .filter(
                    (view) => view.DD_TIPO_CONFIGURACAO === 'VISAO_CUSTO_PADRAO'
                )
                .map((costView) => {
                    if (
                        costView.SK_TEMPO ===
                        get().stdCostSlice.state.selectedDate.id
                    ) {
                        const obj: CostViewState = {}

                        for (const [date, productIds] of Object.entries(
                            viewObj
                        )) {
                            for (const [productId, costView] of Object.entries(
                                productIds
                            )) {
                                obj[date] = {
                                    ...obj[date],
                                    [productId]: {
                                        original: costView.updated,
                                        updated: costView.updated,
                                    },
                                }
                            }
                        }

                        return {
                            ...costView,
                            DS_VISAO: JSON.stringify(obj),
                        }
                    }

                    return costView
                })

            set((state) => {
                const prevState = state.stdCostSlice.state
                prevState.companies = companies
                prevState.isInitialStdCostFetch = false
                prevState.initialCostViews = initialCostViews
                prevState.stdCostData = { components, products: data.products }
            })

            get().stdCostSlice.actions.onCalculateTotalCost()
        },
        onTableStateChange: (tableState) => {
            set((state) => {
                const newState = {
                    ...state.stdCostSlice.state.stdCostTableState,
                    state: tableState,
                }

                setItem(
                    localStorage,
                    import.meta.env.VITE_STANDARD_COST_TABLE_TOKEN,
                    JSON.stringify(newState)
                )

                state.stdCostSlice.state.stdCostTableState = newState
            })
        },
        onTableExpandedChange: (expanded) => {
            set((state) => {
                state.stdCostSlice.state.stdCostTableState = {
                    ...state.stdCostSlice.state.stdCostTableState,
                    expandedState: expanded,
                }
            })
        },
        onTableColumnOrderChange: (columnOrder) => {
            set((state) => {
                const newState = {
                    ...state.stdCostSlice.state.stdCostTableState,
                    columnOrderState: columnOrder,
                }
                setItem(
                    localStorage,
                    import.meta.env.VITE_STANDARD_COST_TABLE_TOKEN,
                    JSON.stringify(newState)
                )

                state.stdCostSlice.state.stdCostTableState = newState
            })
        },
        onResetChangedViews: () =>
            set((state) => {
                state.stdCostSlice.state.changedViews = {}
            }),
        onCalculateTotalCost: (productId) => {
            const worker = new StdCostWorker()
            set((state) => {
                worker.postMessage({
                    type: WorkerTypes.ON_CALCULATE_STD_COST,
                    payload: {
                        data: current(state.stdCostSlice.state.stdCostData),
                        productId: productId,
                    },
                })
            })
            worker.addEventListener('message', (e) => {
                switch (e.data.type) {
                    case 'result':
                        set((state) => {
                            state.stdCostSlice.state.stdCostData = e.data
                                .data as StdCost
                            state.stdCostSlice.state.isCalculating = false
                        })
                        worker.terminate()
                        break
                    case 'log':
                        console.log(e.data.message)
                        break
                }
            })
        },
        onSelectStdCostCompany: (id) => {
            set((state) => {
                state.stdCostSlice.state.stdCostSelectedCompany = id
            })
        },
        onAddTableInstance: (table) => {
            set((state) => {
                //@ts-ignore
                state.stdCostSlice.state.tableInstance = table
            })
        },
        onOpenDetails: () => {
            set((state) => {
                state.stdCostSlice.state.isDetailsOpen = true
            })
        },
        onCloseDetails: () => {
            set((state) => {
                state.stdCostSlice.state.isDetailsOpen = false
            })
        },
        onChangeDate: (year, month) =>
            set((state) => {
                state.stdCostSlice.state.selectedDate = {
                    year,
                    month,
                    id: Number(
                        getIdFromDate(new Date(year, month, 1)).join('')
                    ),
                }
            }),
        onSelectStdCostRow: ({ id, isProduct }) => {
            set((state) => {
                const productOrComponent = isProduct
                    ? state.stdCostSlice.state.stdCostData.products.entities[id]
                    : state.stdCostSlice.state.stdCostData.components.entities[
                          id
                      ]

                state.stdCostSlice.state.isDetailsOpen =
                    Boolean(productOrComponent)
                state.stdCostSlice.state.selectedStdCostRow = {
                    id,
                    isProduct,
                }
            })
        },
        onChangeCostView: ({ productId, costView, dateId }) => {
            const component =
                get().stdCostSlice.state.stdCostData.components.entities[
                    productId
                ]

            let value = 0

            switch (costView) {
                case CostView.ULTIMO_PRECO:
                    value = component.ULTIMO_PRECO
                    break
                case CostView.CUSTO_INFORMADO:
                    value = Number(component.VALOR_INFORMADO_COMPONENTE)
                    break
                case CostView.CUSTO_EXTRA:
                    value = component.ULTIMO_PRECO
                    break
            }

            const originalCostView = component.TIPO_VISAO

            set((state) => {
                const prevState = state.stdCostSlice.state

                if (originalCostView) {
                    const date = prevState.changedViews[dateId]

                    if (date) {
                        date[productId] = {
                            original: originalCostView,
                            updated: costView,
                        }
                    } else {
                        prevState.changedViews[dateId] = {
                            [productId]: {
                                original: originalCostView,
                                updated: costView,
                            },
                        }
                    }
                }

                prevState.stdCostData.components.entities[
                    productId
                ].CUSTO_SELECIONADO = value

                prevState.stdCostData.components.entities[
                    productId
                ].VISAO_SELECIONADA = costView

                prevState.stdCostData.components.entities[
                    productId
                ].TIPO_VISAO = costView
            })

            const getProduct = (parentId: string | number) => {
                const product =
                    get().stdCostSlice.state.stdCostData.products.entities[
                        parentId
                    ] ||
                    get().stdCostSlice.state.stdCostData.components.entities[
                        parentId
                    ]

                return product._parentId
                    ? getProduct(product._parentId)
                    : product
            }

            get().stdCostSlice.actions.onCalculateTotalCost(
                getProduct(component._parentId)._id
            )
        },
        onChangeStdCostQuantity: ({ isProduct, id, quantity }) => {
            set((state) => {
                const prevState = state.stdCostSlice.state.stdCostData

                if (isProduct) {
                    prevState.products.entities[id].PRODUTO_QTD_SELECIONADA =
                        quantity
                } else {
                    prevState.components.entities[id].PRODUTO_QTD_SELECIONADA =
                        quantity
                }
            })

            get().stdCostSlice.actions.onCalculateTotalCost(
                isProduct
                    ? get().stdCostSlice.state.stdCostData.products.entities[id]
                          ._id
                    : undefined
            )
        },
        onAnualAvgToggle: (isChecked) => {
            set((state) => {
                state.stdCostSlice.state.isAnualAvg = isChecked
            })
        },
        setCalculating: (isCalculating) => {
            set((state) => {
                state.stdCostSlice.state.isCalculating = isCalculating
            })
        },
        onReset: () =>
            set((state) => {
                state.stdCostSlice.state = {
                    ...initialState,
                    tableInstance: null,
                }
            }),
    },
})

export const stdCostSliceActionsSelector = (state: Store) =>
    state.stdCostSlice.actions

export const stdCostSliceStateSelector = (state: Store) =>
    state.stdCostSlice.state

export const getStdCostTableDataSelector = (
    state: Store
): StandardCostTableData[] => {
    const getComponents = (
        componentsIds: string[]
    ): StandardCostTableData[] => {
        return componentsIds.map((id) => {
            const prevState = state.stdCostSlice.state.stdCostData

            const component = prevState.components.entities[id]

            const parent =
                prevState.products.entities[component._parentId] ||
                prevState.components.entities[component._parentId]

            const productUsedAsComponent = component.productUsedAsComponentId
                ? prevState.products.entities[
                      component.productUsedAsComponentId
                  ]
                : null

            return {
                _id: component._id,
                CUSTO_TOTAL_REAL: component.CUSTO_TOTAL_REAL,
                QTD_COMPONENTE: component.QTD_COMPONENTE,
                NK_PRODUTO_ESTRUTURA: component.NK_PRODUTO_COMPONENTE,
                productUsedAsComponentId: component.productUsedAsComponentId,
                CUSTO_PADRAO_REAL: component.CUSTO_PADRAO_REAL,
                CUSTO_PADRAO_TOTAL: component.CUSTO_PADRAO_TOTAL,
                CUSTO_PADRAO_TOTAL_REAL: component.CUSTO_PADRAO_TOTAL_REAL,
                CUSTO_SELECIONADO: component.CUSTO_SELECIONADO,
                VARIACAO_CUSTO_PADRAO: component.VARIACAO_CUSTO_PADRAO,
                PRODUTO_FILTRO: `${parent.PRODUTO_FILTRO} ${component.PRODUTO_COMPONENTE} (${component.NK_PRODUTO_COMPONENTE})`,
                PRODUTO_APONTADO_BLOQUEADO:
                    component.PRODUTO_COMPONENTE_BLOQUEADO,
                PRODUTO_QTD_SELECIONADA: component.PRODUTO_QTD_SELECIONADA,
                TIPO_VISAO: component.TIPO_VISAO,
                VISAO_SELECIONADA: component.VISAO_SELECIONADA,
                ABREVIATURA_EMPRESA: parent.ABREVIATURA_EMPRESA,
                DD_CLASSIFICACAO: component.DD_CLASSIFICACAO,
                DD_ORIGEM_DADO: parent.DD_ORIGEM_DADO,
                DS_REVISAO_INICIAL: productUsedAsComponent
                    ? productUsedAsComponent.DS_REVISAO_INICIAL
                    : undefined,
                DS_REVISAO_FINAL: productUsedAsComponent
                    ? productUsedAsComponent.DS_REVISAO_FINAL
                    : undefined,
                INDICE_COMPONENTE_REAL: component.INDICE_COMPONENTE_REAL,
                INDICE_STANDARD: component.INDICE_STANDARD,
                PRODUTO_ESTRUTURA: component.PRODUTO_COMPONENTE,
                QTD_REAL_UTILIZADA: component.QTD_REAL_UTILIZADA,
                SK_EMPRESA: parent.SK_EMPRESA,
                SK_PRODUTO_ESTRUTURA: component.SK_PRODUTO_COMPONENTE,
                ULTIMO_PRECO: component.ULTIMO_PRECO,
                UM_PRODUTO_ESTRUTURA: component.UM_PRODUTO_COMPONENTE,
                VALOR_CUSTO_REAL_COMPONENTE:
                    component.VALOR_CUSTO_REAL_COMPONENTE,
                VALOR_CUSTO_REAL_UNITARIO: component.VALOR_CUSTO_REAL_UNITARIO,
                VALOR_INFORMADO_PRODUTO: component.VALOR_INFORMADO_COMPONENTE,
                ULTIMO_PRECO_UNITARIO: component.ULTIMO_PRECO_UNITARIO,
                CUSTO_PADRAO_UNITARIO: component.CUSTO_PADRAO_UNITARIO,
                VARIACAO_INDICE: component.VARIACAO_INDICE,
                VARIACAO_PERCENTUAL_INDICE:
                    component.VARIACAO_PERCENTUAL_INDICE,
                VARIACAO_CUSTO_PADRAO_TOTAL:
                    component.VARIACAO_CUSTO_PADRAO_TOTAL,
                VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL:
                    component.VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL,
                VARIACAO_CUSTO_REAL_TOTAL: component.VARIACAO_CUSTO_REAL_TOTAL,
                VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL:
                    component.VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL,
                VARIACAO_QUANTIDADE_TOTAL: component.VARIACAO_QUANTIDADE_TOTAL,
                VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL:
                    component.VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL,
                IMPACTO_CONSUMO: component.IMPACTO_CONSUMO,
                IMPACTO_PRECO: component.IMPACTO_PRECO,
                VARIACAO_CUSTO_UNITARIO: component.VARIACAO_CUSTO_UNITARIO,
                VARIACAO_CUSTO_UNITARIO_PERCENTUAL:
                    component.VARIACAO_CUSTO_UNITARIO_PERCENTUAL,
                DD_VARIANCIA_MAX: component.DD_VARIANCIA_MAX,
                DD_VARIANCIA_MIN: component.DD_VARIANCIA_MIN,
                subRows:
                    component.COMPONENTES.length > 0
                        ? getComponents(component.COMPONENTES)
                        : [],
            }
        })
    }

    const products: StandardCostTableData[] =
        state.stdCostSlice.state.stdCostData.products.ids.map((id) => {
            const product =
                state.stdCostSlice.state.stdCostData.products.entities[id]

            return {
                _id: product._id,
                CUSTO_TOTAL_REAL: product.CUSTO_TOTAL_REAL,
                QTD_COMPONENTE: 0,
                NK_PRODUTO_ESTRUTURA: product.NK_PRODUTO_ESTRUTURA,
                productUsedAsComponentId: product.productUsedAsComponentId,
                DS_REVISAO_INICIAL: product.DS_REVISAO_INICIAL,
                DS_REVISAO_FINAL: product.DS_REVISAO_FINAL,
                PRODUTO_APONTADO_BLOQUEADO: product.PRODUTO_APONTADO_BLOQUEADO,
                CUSTO_PADRAO_REAL: product.CUSTO_PADRAO_REAL,
                CUSTO_PADRAO_TOTAL: product.CUSTO_PADRAO_TOTAL,
                CUSTO_PADRAO_TOTAL_REAL: product.CUSTO_PADRAO_TOTAL_REAL,
                CUSTO_SELECIONADO: product.CUSTO_SELECIONADO,
                VARIACAO_CUSTO_PADRAO: product.VARIACAO_CUSTO_PADRAO,
                PRODUTO_FILTRO: `${product.PRODUTO_ESTRUTURA} (${product.NK_PRODUTO_ESTRUTURA})`,
                PRODUTO_QTD_SELECIONADA: product.PRODUTO_QTD_SELECIONADA,
                TIPO_VISAO: product.TIPO_VISAO,
                VISAO_SELECIONADA: product.VISAO_SELECIONADA,
                ABREVIATURA_EMPRESA: product.ABREVIATURA_EMPRESA,
                DD_CLASSIFICACAO: product.DD_CLASSIFICACAO,
                DD_ORIGEM_DADO: product.DD_ORIGEM_DADO,
                INDICE_COMPONENTE_REAL: 0,
                INDICE_STANDARD: 0,
                PRODUTO_ESTRUTURA: product.PRODUTO_ESTRUTURA,
                QTD_REAL_UTILIZADA:
                    product.PRODUTO_QTD_SELECIONADA ||
                    product.QTD_PRODUCAO_APONTADA,
                SK_EMPRESA: product.SK_EMPRESA,
                SK_PRODUTO_ESTRUTURA: product.SK_PRODUTO_ESTRUTURA,
                ULTIMO_PRECO: 0,
                UM_PRODUTO_ESTRUTURA: product.UM_PRODUTO_ESTRUTURA,
                VALOR_CUSTO_REAL_COMPONENTE:
                    product.VALOR_CUSTO_REAL_COMPONENTE,
                VALOR_CUSTO_REAL_UNITARIO: 0,
                VALOR_INFORMADO_PRODUTO: product.VALOR_INFORMADO_PRODUTO,
                ULTIMO_PRECO_UNITARIO: 0,
                CUSTO_PADRAO_UNITARIO: product.CUSTO_PADRAO_UNITARIO,
                VARIACAO_INDICE: product.VARIACAO_INDICE,
                VARIACAO_PERCENTUAL_INDICE: product.VARIACAO_PERCENTUAL_INDICE,
                VARIACAO_CUSTO_PADRAO_TOTAL:
                    product.VARIACAO_CUSTO_PADRAO_TOTAL,
                VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL:
                    product.VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL,
                VARIACAO_CUSTO_REAL_TOTAL: product.VARIACAO_CUSTO_REAL_TOTAL,
                VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL:
                    product.VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL,
                VARIACAO_QUANTIDADE_TOTAL: product.VARIACAO_QUANTIDADE_TOTAL,
                VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL:
                    product.VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL,
                IMPACTO_CONSUMO: product.IMPACTO_CONSUMO,
                IMPACTO_PRECO: product.IMPACTO_PRECO,
                VARIACAO_CUSTO_UNITARIO: product.VARIACAO_CUSTO_UNITARIO,
                VARIACAO_CUSTO_UNITARIO_PERCENTUAL:
                    product.VARIACAO_CUSTO_UNITARIO_PERCENTUAL,
                DD_VARIANCIA_MAX: product.DD_VARIANCIA_MAX,
                DD_VARIANCIA_MIN: product.DD_VARIANCIA_MIN,
                subRows: getComponents(product.COMPONENTES),
            }
        })
    return products.filter(
        (value) =>
            value.SK_EMPRESA === state.stdCostSlice.state.stdCostSelectedCompany
    )
}

export const getProductByIdSelector =
    (productId: string | number) => (state: Store) =>
        state.stdCostSlice.state.stdCostData.products.entities[productId]

export const getComponentByIdSelector =
    (componentId: string | number) => (state: Store) =>
        state.stdCostSlice.state.stdCostData.components.entities[componentId]

export const getAllProductsSelector = (state: Store) =>
    state.stdCostSlice.state.stdCostData.products.ids.map(
        (id) => state.stdCostSlice.state.stdCostData.products.entities[id]
    )

export const useGetAllComponents = (state: Store) =>
    state.stdCostSlice.state.stdCostData.components.ids.map(
        (id) => state.stdCostSlice.state.stdCostData.components.entities[id]
    )

export const useGetDetailsOpenState = (state: Store) =>
    state.stdCostSlice.state.isDetailsOpen

import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'

const stateSelector = (state: Store) => ({
    isAnualAvg: state.stdCostSlice.state.isAnualAvg,
})
const actionsSelector = (state: Store) => ({
    onAnualAvgToggle: state.stdCostSlice.actions.onAnualAvgToggle,
})

const StdCostAnualAvgToggle = () => {
    const { onAnualAvgToggle } = useBaseStore(actionsSelector)
    const { isAnualAvg } = useBaseStore(stateSelector)

    return (
        <div className="flex items-center space-x-2">
            <Switch
                id="taxes"
                checked={isAnualAvg}
                onCheckedChange={onAnualAvgToggle}
            />
            <Label htmlFor="taxes" className="text-neutral-500">
                Média anual
            </Label>
        </div>
    )
}

export default StdCostAnualAvgToggle

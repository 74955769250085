import { recoverPassword } from '@/api/auth/user'
import Button from '@/components/Button'
import { useToast } from '@/components/ui/use-toast'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ResetPassword = () => {
    const [email, setEmail] = useState('')
    const { toast } = useToast()
    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation({
        mutationFn: recoverPassword,
        onSuccess: () => {
            toast({
                title: 'Senha recuperada com sucesso.',
                description: `Nova senha enviada para o email ${email}`,
            })
            navigate('/login')
        },
        onError: () => {
            toast({
                title: 'Erro ao recuperar senha.',
                description: `Houve um erro ao recuperar a senha. Verifique os dados e tente novamente`,
                variant: 'destructive',
            })
        },
    })

    return (
        <div className="container flex items-center justify-center h-full mx-auto">
            <form
                className="w-1/3 space-y-4"
                onSubmit={(e) => {
                    if (isLoading) return

                    e.preventDefault()
                    mutate(email)
                }}
            >
                <div>
                    <h1 className="text-2xl font-semibold">
                        Recuperação de senha
                    </h1>
                    <p className="text-neutral-400">
                        Digite seu email e confirme.
                    </p>
                </div>
                <div className="space-y-1.2">
                    <label htmlFor="email" className="text-xs text-neutral-400">
                        Email:
                    </label>
                    <input
                        className="w-full p-2 border rounded-md"
                        id="email"
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                </div>
                <div className="flex">
                    <Button
                        className="w-[100px] ml-auto rounded-md"
                        isLoading={isLoading}
                        disabled={!email}
                    >
                        Confirmar
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default ResetPassword

import {
    CreateBulkExtraCostProps,
    CreateBulkExtraCostReturn,
} from '@/api/business/extraCost/types'
import { getIdFromDate } from '@/utils/date'
import { useCallback, useReducer } from 'react'
import { BulkData } from '.'
import { useProductStructureQuery } from '@/queries/useProductStructureQuery'

export interface BulkDialogState {}
export enum BulkDialogActionNames {
    SET_TABLE_DATA = 'SET_TABLE_DATA',
    UPDATE_TABLE_DATA = 'UPDATE_TABLE_DATA',
    SET_DATA_STATUS = 'SET_DATA_STATUS',
    ADD_ROW = 'ADD_ROW',
    REMOVE_ROW = 'REMOVE_ROW',
    RESET = 'RESET',
}

type BulkDialogActions =
    | {
          type: BulkDialogActionNames.SET_TABLE_DATA
          payload: CreateBulkExtraCostProps[]
      }
    | {
          type: BulkDialogActionNames.UPDATE_TABLE_DATA
          payload: { rowIdx: number; columnId: string; value: unknown }
      }
    | {
          type: BulkDialogActionNames.SET_DATA_STATUS
          payload: CreateBulkExtraCostReturn[]
      }
    | {
          type: BulkDialogActionNames.ADD_ROW
      }
    | {
          type: BulkDialogActionNames.REMOVE_ROW
          payload: {
              id: string
          }
      }
    | {
          type: BulkDialogActionNames.RESET
      }

interface State {
    tableData: BulkData[]
    feedbackResponse: CreateBulkExtraCostReturn[]
}

const initialState: State = {
    tableData: [],
    feedbackResponse: [],
}

const useBulkDialog = () => {
    const { data: products, isLoading } = useProductStructureQuery({
        enabled: true,
    })

    const reducer = useCallback(
        (
            state: typeof initialState,
            action: BulkDialogActions
        ): typeof initialState => {
            switch (action.type) {
                case BulkDialogActionNames.SET_TABLE_DATA: {
                    const data = action.payload

                    return {
                        tableData: data.map((product) => {
                            const productId = `${product.SK_EMPRESA}${product.SK_PRODUTO_ESTRUTURA}`
                            const componentId = `${product.SK_EMPRESA}${product.SK_PRODUTO_COMPONENTE}`

                            return {
                                ...product,
                                id: crypto.randomUUID(),
                                DS_PRODUTO_APONTADO: products?.find((prod) => {
                                    return (
                                        prod.SK_PRODUTO_ESTRUTURA === productId
                                    )
                                })?.PRODUTO_ESTRUTURA,
                                DS_PRODUTO: products?.find((prod) => {
                                    return product.SK_PRODUTO_ESTRUTURA
                                        ? prod.SK_PRODUTO_COMPONENTE ===
                                              componentId &&
                                              prod.SK_PRODUTO_ESTRUTURA ===
                                                  productId
                                        : prod.SK_PRODUTO_COMPONENTE ===
                                              componentId
                                })?.PRODUTO_COMPONENTE,
                                DS_CUSTO: product.DS_CUSTO,
                                VL_ALIQUOTA_COFINS:
                                    product.VL_ALIQUOTA_COFINS || '0',
                                VL_ALIQUOTA_FCP: product.VL_ALIQUOTA_FCP || '0',
                                VL_ALIQUOTA_ICMS:
                                    product.VL_ALIQUOTA_ICMS || '0',
                                VL_ALIQUOTA_IPI: product.VL_ALIQUOTA_IPI || '0',
                                VL_ALIQUOTA_PIS: product.VL_ALIQUOTA_PIS || '0',
                                VL_ALIQUOTA_ST: product.VL_ALIQUOTA_ST || '0',
                                VL_COFINS: product.VL_COFINS || '0',
                                VL_CUSTO_ORCADO: product.VL_CUSTO_ORCADO || '0',
                                VL_FCP: product.VL_FCP || '0',
                                VL_ICMS: product.VL_ICMS || '0',
                                VL_IPI: product.VL_IPI || '0',
                                VL_PIS: product.VL_PIS || '0',
                                VL_ST: product.VL_ST || '0',
                            }
                        }),
                        feedbackResponse: [],
                    }
                }
                case BulkDialogActionNames.SET_DATA_STATUS: {
                    const response = action.payload

                    return { ...state, feedbackResponse: response }
                }
                case BulkDialogActionNames.UPDATE_TABLE_DATA: {
                    const { columnId, rowIdx, value } = action.payload
                    return {
                        ...state,
                        tableData: state.tableData.map((row, idx) => {
                            if (idx === rowIdx) {
                                const productId = `${state.tableData[rowIdx]['SK_EMPRESA']}${state.tableData[rowIdx]['SK_PRODUTO_ESTRUTURA']}`
                                const componentId = `${state.tableData[rowIdx]['SK_EMPRESA']}${value}`

                                if (columnId === 'SK_PRODUTO_COMPONENTE') {
                                    return {
                                        ...state.tableData[rowIdx],
                                        [columnId]: value as string,
                                        DS_PRODUTO: products?.find((prod) =>
                                            state.tableData[rowIdx][
                                                'SK_PRODUTO_ESTRUTURA'
                                            ]
                                                ? prod.SK_PRODUTO_ESTRUTURA ===
                                                      productId &&
                                                  prod.SK_PRODUTO_COMPONENTE ===
                                                      componentId
                                                : prod.SK_PRODUTO_COMPONENTE ===
                                                  componentId
                                        )?.PRODUTO_COMPONENTE,
                                    }
                                }

                                if (columnId === 'SK_PRODUTO_ESTRUTURA') {
                                    const productId = `${state.tableData[rowIdx]['SK_EMPRESA']}${value}`
                                    const componentId = `${state.tableData[rowIdx]['SK_EMPRESA']}${state.tableData[rowIdx]['SK_PRODUTO_COMPONENTE']}`

                                    return {
                                        ...state.tableData[rowIdx],
                                        [columnId]: value as string,
                                        DS_PRODUTO_APONTADO: products?.find(
                                            (prod) =>
                                                prod.SK_PRODUTO_ESTRUTURA ===
                                                productId
                                        )?.PRODUTO_ESTRUTURA,
                                        DS_PRODUTO: products?.find((prod) =>
                                            value
                                                ? prod.SK_PRODUTO_COMPONENTE ===
                                                      componentId &&
                                                  prod.SK_PRODUTO_ESTRUTURA ===
                                                      productId
                                                : prod.SK_PRODUTO_COMPONENTE ===
                                                  componentId
                                        )?.PRODUTO_COMPONENTE,
                                    }
                                }
                                if (columnId === 'SK_EMPRESA') {
                                    const productId = `${value}${state.tableData[rowIdx]['SK_PRODUTO_ESTRUTURA']}`
                                    const componentId = `${value}${state.tableData[rowIdx]['SK_PRODUTO_COMPONENTE']}`

                                    return {
                                        ...state.tableData[rowIdx],
                                        [columnId]: value as string,
                                        DS_PRODUTO: products?.find((prod) =>
                                            state.tableData[rowIdx][
                                                'SK_PRODUTO_ESTRUTURA'
                                            ]
                                                ? prod.SK_PRODUTO_COMPONENTE ===
                                                      componentId &&
                                                  prod.SK_PRODUTO_ESTRUTURA ===
                                                      productId
                                                : prod.SK_PRODUTO_COMPONENTE ===
                                                  componentId
                                        )?.PRODUTO_COMPONENTE,
                                        DS_PRODUTO_APONTADO:
                                            products?.find((prod) => {
                                                return (
                                                    prod.SK_PRODUTO_ESTRUTURA ===
                                                    productId
                                                )
                                            })?.PRODUTO_ESTRUTURA || '',
                                    }
                                }
                                return {
                                    ...state.tableData[rowIdx],
                                    [columnId]: value,
                                }
                            }
                            return row
                        }),
                    }
                }
                case BulkDialogActionNames.ADD_ROW: {
                    return {
                        ...state,
                        tableData: [
                            ...state.tableData,
                            {
                                id: crypto.randomUUID(),
                                DS_CUSTO: '',
                                CATEGORIA_VALOR: 'FIXO',
                                SK_PRODUTO_ESTRUTURA: '',
                                SK_PRODUTO_COMPONENTE: '',
                                SK_EMPRESA: '',
                                SK_MOEDA: 1,
                                SK_TEMPO: Number(
                                    getIdFromDate(new Date()).join('')
                                ),
                                VL_ALIQUOTA_COFINS: '0',
                                VL_ALIQUOTA_FCP: '0',
                                VL_ALIQUOTA_ICMS: '0',
                                VL_ALIQUOTA_IPI: '0',
                                VL_ALIQUOTA_PIS: '0',
                                VL_ALIQUOTA_ST: '0',
                                VL_COFINS: '0',
                                VL_CUSTO_ORCADO: '0',
                                VL_FCP: '0',
                                VL_ICMS: '0',
                                VL_IPI: '0',
                                VL_PIS: '0',
                                VL_ST: '0',
                            },
                        ],
                    }
                }
                case BulkDialogActionNames.REMOVE_ROW: {
                    const { id } = action.payload
                    return {
                        ...state,
                        tableData: state.tableData.filter(
                            (cost) => cost.id !== id
                        ),
                    }
                }
                case BulkDialogActionNames.RESET:
                    return initialState
                default:
                    return state
            }
        },
        [products]
    )

    const [state, dispatch] = useReducer(reducer, initialState)

    return {
        ...state,
        productsLoading: isLoading,
        dispatch,
    }
}
export default useBulkDialog

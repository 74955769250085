import Button from '@/components/Button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { useBaseStore } from '@/store'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'
import { useStdCostSpreadsheet } from '../../useStdCostSpreadsheet'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { freezeStdCost } from '@/api/business/stdCost'
import { isAxiosError } from '@/api/business'
import { useToast } from '@/components/ui/use-toast'
import { stdCostKeys, useStdCostFreezeLog } from '@/queries/useStdCostsQuery'
import { Loader, Snowflake } from 'lucide-react'

interface FreezeDialogProps {
    isOpen: boolean
    onClose: () => void
}

const FreezeDialog = ({ isOpen, onClose }: FreezeDialogProps) => {
    const { selectedDate } = useBaseStore(stdCostSliceStateSelector)
    const { getTableRows } = useStdCostSpreadsheet()
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation({
        mutationFn: freezeStdCost,
        onSuccess: () => {
            onClose()
            toast({
                title: 'Custo congelado com sucesso',
            })
            queryClient.invalidateQueries({
                queryKey: stdCostKeys.freeze(selectedDate.id.toString()),
            })
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao congelar custos',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const { data, isLoading: isLogLoading } = useStdCostFreezeLog({
        date: selectedDate.id,
    })

    const onFreeze = () => {
        mutate({ costs: getTableRows(true), date: selectedDate.id })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle className="gap-2">
                        Congelar visões{' '}
                        {data && data.length > 0 ? (
                            <span className="inline-flex items-center gap-2 px-2 py-1 text-sm font-normal bg-slate-50 text-primary-500">
                                <Snowflake size={12} />
                                Congelado
                            </span>
                        ) : null}
                        {isLogLoading && (
                            <Loader
                                size={16}
                                className="inline-block text-primary-600 animate-spin"
                            />
                        )}
                    </DialogTitle>
                    {data && data.length > 0 ? (
                        <DialogDescription>
                            <p className="text-sm text-neutral-500">
                                Período congelado por {data[0].NM_USUARIO} em{' '}
                                {data[0].CREATED_AT}
                            </p>
                        </DialogDescription>
                    ) : null}
                </DialogHeader>
                <div className="space-y-2">
                    <p>
                        Deseja congelar os custos do período{' '}
                        {(selectedDate.month + 1).toString().padStart(2, '0')}/
                        {selectedDate.year}?
                    </p>
                </div>

                <DialogFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button onClick={onFreeze} isLoading={isLoading}>
                        Congelar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default FreezeDialog

import { cn } from '@/lib/utils'
import { Cell, Table, flexRender } from '@tanstack/react-table'
import { ChevronDown, ChevronRight, CornerDownRight } from 'lucide-react'
import {
    CSSProperties,
    ReactNode,
    useEffect,
    // useMemo,
    useRef,
    useState,
} from 'react'
import { getCommonPinningStyles } from '../../utils'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

interface CellProps<TData> {
    table: Table<TData>
    cell: Cell<TData, any>
    isRowSelected: boolean
}

const TableCell = <T,>({ cell, table, isRowSelected }: CellProps<T>) => {
    const initialValue = cell.getValue()
    const [value, setValue] = useState(initialValue || '')
    const [isEditing, setIsEditing] = useState(false)

    const isGrouped = cell.column.columnDef?.meta?.row?.isGrouped
    const isCellEditable = cell.column?.columnDef?.meta?.enableColumnEditable
    const tableRowLayout = table.options.meta?.layout || 'default'
    const cellClassName =
        cell.column.columnDef.meta?.cell?.className ||
        table.options.meta?.cell?.className

    // const validator = useMemo(
    //     () =>
    //         table.options.meta?.validator?.find(
    //             (validate) =>
    //                 validate.columnId === cell.column.columnDef.id &&
    //                 validate.rowId === cell.row.id
    //         ),
    //     [table]
    // )
    // const defaultFocused = cell.column.columnDef.meta?.defaultFocused

    const inputRef = useRef<HTMLInputElement | null>(null)

    const formatterFn = cell.column.columnDef.meta?.cell?.formatterFn

    const { isDragging, setNodeRef, transform } = useSortable({
        id: cell.column.id,
    })

    const style: CSSProperties = {
        opacity: isDragging ? 0.8 : 1,
        position: 'relative',
        transform: CSS.Translate.toString(transform),
        transition: 'width transform 0.2s ease-in-out',
        width: cell.column.getSize(),
        zIndex: isDragging ? 1 : 0,
        paddingLeft:
            cell.getIsGrouped() || isGrouped
                ? `${cell.row.depth * 2}rem`
                : undefined,
    }

    const onBlur = () => {
        if (table.options.meta?.updateData) {
            table.options.meta.updateData(
                cell.row.id,
                cell.column.id as keyof T,
                value,
                cell.column.columnDef
            )
        }
    }

    useEffect(() => {
        setValue(initialValue || '')
    }, [initialValue])

    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus()
        }
    }, [isEditing])

    const renderContent = (): ReactNode => {
        if (cell.getIsGrouped() || isGrouped) {
            const toggle = cell.row.getToggleExpandedHandler()

            return (
                <div className="flex items-center overflow-hidden">
                    {cell.row.depth !== 0 && !cell.row.getCanExpand() ? (
                        <div className="px-2">
                            <CornerDownRight
                                className="text-neutral-500"
                                size={12}
                            />
                        </div>
                    ) : (
                        <button
                            onClick={(e) => {
                                e.stopPropagation()
                                toggle()
                            }}
                            className={cn(
                                'px-2 cursor-pointer',
                                isGrouped && cell.row.depth === 0
                                    ? 'text-primary-500'
                                    : ''
                            )}
                        >
                            {cell.row.getIsExpanded() ? (
                                <ChevronDown size={16} />
                            ) : (
                                <ChevronRight size={16} />
                            )}
                        </button>
                    )}
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
            )
        }

        // if ((isCellEditable && isEditing) || defaultFocused) {
        if (isCellEditable) {
            const editableColumnType =
                cell.column?.columnDef?.meta?.editableColumnType

            return (
                <input
                    ref={inputRef}
                    className="w-full h-full px-2 py-1 text-xs border"
                    value={value as string}
                    type={editableColumnType || 'text'}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={() => {
                        onBlur()
                        setIsEditing(false)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onBlur()
                        }
                    }}
                />
            )
        }

        return formatterFn ? (
            <p className="overflow-hidden whitespace-nowrap text-ellipsis">
                {formatterFn({ row: cell.row, value: cell.getValue() })}
            </p>
        ) : (
            flexRender(cell.column.columnDef.cell, cell.getContext())
        )
    }

    const onClick = () => {
        if (isCellEditable) setIsEditing(true)
    }

    const onFocus = () => {
        if (isCellEditable) setIsEditing(true)
    }

    return (
        <div
            ref={setNodeRef}
            className={cn(
                'flex border-r overflow-hidden first-of-type:border-l border-b group-hover:border-white border-neutral-200 items-center py-1.5 px-4 w-full text-xs',
                isRowSelected && 'border-white',
                tableRowLayout === 'stretch' && 'last-of-type:flex-1',
                cellClassName
            )}
            tabIndex={10}
            onClick={onClick}
            onFocus={onFocus}
            style={{
                ...style,
                ...getCommonPinningStyles(cell.column),
            }}
        >
            {renderContent()}
        </div>
    )
}

export default TableCell

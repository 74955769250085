import ConfirmDialog from '@/components/ConfirmDialog'
import Select from '@/components/Select'
import useAddDialog, { AddDialogActionNames } from './useAddDialog'
import DatePickerMonthWithRange from '@/components/DatePickerMonthWithRange'
import { MultiSelect } from '@/components/MultiSelect'
import useDisclosure from '@/hooks/useDisclosure'
import { Separator } from '@/components/ui/separator'
import { informedCostKeys } from '@/queries/useInformedCostQuery'
import { createInformedCost } from '@/api/business/informedCost'
import { useToast } from '@/components/ui/use-toast'
import { getIdFromDate } from '@/utils/date'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
    addDays,
    eachDayOfInterval,
    isFirstDayOfMonth,
    isSunday,
} from 'date-fns'
import { Taxes } from '@/types/Costs'
import { useState } from 'react'
import { isAxiosError } from '@/api/business'
interface AddDialogProps {
    isOpen: boolean
    onClose: () => void
}

const AddDialog = ({ isOpen, onClose }: AddDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const [productFound, setProductFound] = useState(true)
    const [hasSelected, setHasSelected] = useState(true)

    const {
        companies,
        companyId,
        productIds,
        componentId,
        components,
        currencies,
        products,
        costValue,
        currencyId,
        date,
        taxes,
        isCurrencyLoading,
        isProductStructureLoading,
        dispatch,
    } = useAddDialog({ isOpen })

    const {
        onOpen: onOpenMultiSelect,
        isOpen: isMultiSelectOpen,
        onClose: onMultiSelectClose,
    } = useDisclosure()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: createInformedCost,
        onSuccess: () => {
            queryClient.invalidateQueries(informedCostKeys.lists())

            toast({
                title: 'Custo adicionado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao criar custo',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const onSubmit = () => {
        const formattedTaxes: Taxes = taxes.reduce((tax, curr) => {
            return {
                ...tax,
                ...curr.inputs.reduce((tx, curr) => {
                    return {
                        ...tx,
                        [curr.name]: curr.value,
                    }
                }, {} as Taxes),
            }
        }, {} as Taxes)

        const dates = eachDayOfInterval({
            start: date.from,
            end: date.to,
        })
            .filter((day) => isFirstDayOfMonth(day))
            .map((day) => {
                return isSunday(day)
                    ? Number(getIdFromDate(addDays(day, 1)).join(''))
                    : Number(getIdFromDate(day).join(''))
            })

        const product = products.all.some(
            (product) =>
                product.NK_COMPONENTE === componentId &&
                productIds.includes(product.NK_PRODUTO.toLowerCase())
        )

        if (productIds.length === 0) {
            return setHasSelected(false)
        }
        setHasSelected(true)

        if (!product) {
            return setProductFound(false)
        }

        mutate({
            SK_EMPRESA: companyId,
            SK_MOEDA: Number(currencyId),
            SK_PRODUTO_COMPONENTE: components.filtered.find(
                (component) =>
                    component.NK_COMPONENTE.toLowerCase() ===
                    componentId.toLowerCase()
            )!.SK_PRODUTO_COMPONENTE,
            SK_PRODUTO_ESTRUTURA: productIds.map(
                (productId) =>
                    products.filtered.find(
                        (product) =>
                            product.NK_PRODUTO.toLowerCase() ===
                            productId.toLowerCase()
                    )!.SK_PRODUTO_ESTRUTURA
            ),
            SK_TEMPO: dates,
            VL_CUSTO_ORCADO: costValue,
            ...formattedTaxes,
        })
    }

    return (
        <ConfirmDialog
            className="max-w-5xl"
            title="Adicionar custo informado"
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={onSubmit}
            isLoading={mutateLoading}
        >
            <div className="flex items-center h-full gap-10 py-4">
                <div className="flex-1 h-full space-y-4 overflow-hidden">
                    <div>
                        <p className="font-semibold">Informações</p>
                        <Separator />
                    </div>
                    <div>
                        <label htmlFor="">Selecionar empresa</label>
                        <Select
                            placeholder={
                                isProductStructureLoading
                                    ? `Carregando empresas...`
                                    : ''
                            }
                            value={companyId}
                            options={companies.map((company) => ({
                                label: company.company,
                                value: company.companyId.toString(),
                            }))}
                            onChange={(value) =>
                                dispatch({
                                    type: AddDialogActionNames.ON_CHANGE_COMPANY,
                                    payload: { companyId: value },
                                })
                            }
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="">Selecionar produto</label>
                        <input
                            id="product"
                            className="block w-full p-2 border rounded-md"
                            list="products"
                            name="product"
                            placeholder={
                                isProductStructureLoading
                                    ? `Carregando produtos...`
                                    : ''
                            }
                            value={componentId}
                            onChange={(e) =>
                                dispatch({
                                    type: AddDialogActionNames.ON_SELECT_COMPONENT,
                                    payload: {
                                        componentId: e.target.value,
                                    },
                                })
                            }
                        />
                        <datalist id="products">
                            {components.filtered.map((product) => (
                                <option
                                    key={product.SK_ESTRUTURA_DE_PRODUTO}
                                    id={product.SK_ESTRUTURA_DE_PRODUTO.toString()}
                                    value={`${product.NK_COMPONENTE}`}
                                    label={product.PRODUTO_COMPONENTE}
                                />
                            ))}
                        </datalist>
                        <p className="flex-1 py-2 overflow-hidden text-sm whitespace-nowrap text-ellipsis">
                            {componentId
                                ? components.filtered.find(
                                      (product) =>
                                          product.NK_COMPONENTE === componentId
                                  )?.PRODUTO_COMPONENTE ||
                                  'Produto não encontrado'
                                : null}
                        </p>

                        {!productFound && hasSelected ? (
                            <p className="text-red-500">
                                Insira um produto válido
                            </p>
                        ) : null}
                    </div>
                    <div>
                        <label htmlFor="">Selecionar estrutura</label>
                        <MultiSelect
                            isOpen={isMultiSelectOpen}
                            onChange={(value) =>
                                dispatch({
                                    type: AddDialogActionNames.ON_SELECT_PRODUCT_STRUCTURE,
                                    payload: {
                                        productId: value,
                                    },
                                })
                            }
                            onSelectAll={(values) => {
                                dispatch({
                                    type: AddDialogActionNames.ON_SELECT_ALL_PRODUCT_STRUCTURES,
                                    payload: { productIds: values },
                                })
                            }}
                            customFilter={(id, search) => {
                                const value = products.filtered.find(
                                    (product) => product.NK_PRODUTO === id
                                )

                                if (!value) return 0

                                const matchName =
                                    value.PRODUTO_ESTRUTURA?.toLowerCase()
                                        .trim()
                                        .includes(
                                            search.toLocaleLowerCase().trim()
                                        )

                                const matchId =
                                    value.NK_PRODUTO.includes(search)

                                return matchName || matchId ? 1 : 0
                            }}
                            onOpen={onOpenMultiSelect}
                            onClose={onMultiSelectClose}
                            options={products.filtered.map((component) => ({
                                label: component.PRODUTO_ESTRUTURA,
                                value: component.NK_PRODUTO,
                            }))}
                            selectedValues={productIds}
                        />
                        {!hasSelected && (
                            <p className="text-red-500">
                                Selecione no mínimo uma estrutura
                            </p>
                        )}
                    </div>

                    <div>
                        <label htmlFor="">Data</label>
                        <DatePickerMonthWithRange
                            className="w-full"
                            date={date}
                            setDate={({ from, to }) =>
                                dispatch({
                                    type: AddDialogActionNames.ON_CHANGE_DATE,
                                    payload: {
                                        from,
                                        to,
                                    },
                                })
                            }
                        />
                    </div>
                </div>
                <div className="flex-1 h-full space-y-4">
                    <div>
                        <p className="font-semibold">Custos</p>
                        <Separator />
                    </div>
                    <div className="flex items-center gap-4">
                        <div className="flex-1">
                            <label htmlFor="">Custo</label>
                            <input
                                id="quantity"
                                className="block w-full p-2 border rounded-md"
                                name="quantity"
                                type="number"
                                value={costValue}
                                onChange={(e) =>
                                    dispatch({
                                        type: AddDialogActionNames.ON_CHANGE_COST,
                                        payload: {
                                            cost: e.target.value,
                                        },
                                    })
                                }
                            />
                        </div>
                        <div>
                            <label htmlFor="">Moeda</label>
                            <Select
                                placeholder={
                                    isCurrencyLoading
                                        ? `Carregando moedas...`
                                        : ''
                                }
                                value={currencyId}
                                options={currencies.map((currency) => ({
                                    label: currency.DS_MOEDA,
                                    value: currency.SK_MOEDA.toString(),
                                }))}
                                onChange={(value) =>
                                    dispatch({
                                        type: AddDialogActionNames.ON_CHANGE_CURRENCY,
                                        payload: {
                                            currencyId: value,
                                        },
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="space-y-4">
                        <div>
                            <p className="font-semibold">Impostos</p>
                            <Separator />
                        </div>
                        <div className="grid grid-cols-2 w-fit gap-x-6 gap-y-6">
                            {taxes.map((taxGroup, idx) => (
                                <div
                                    key={idx}
                                    className="relative flex items-center gap-4 p-2 py-3 border rounded-md border-neutral-300 "
                                >
                                    <span className="absolute px-2 bg-white -top-3 left-5">
                                        {taxGroup.groupName}
                                    </span>
                                    {taxGroup.inputs.map((tax) => (
                                        <div
                                            key={tax.name}
                                            className="flex flex-col"
                                        >
                                            <label
                                                htmlFor={tax.name}
                                                className="text-neutral-400 whitespace-nowrap"
                                            >
                                                {tax.label}
                                            </label>
                                            <input
                                                className="w-full px-4 py-1 text-base border rounded-md border-divider-color"
                                                type="number"
                                                id={tax.name}
                                                name={tax.name}
                                                value={tax.value}
                                                onChange={(e) =>
                                                    dispatch({
                                                        type: AddDialogActionNames.ON_CHANGE_TAX_VALUE,
                                                        payload: {
                                                            taxId: tax.name,
                                                            value: e.target
                                                                .value,
                                                        },
                                                    })
                                                }
                                                step="any"
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </ConfirmDialog>
    )
}

export default AddDialog

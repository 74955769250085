import TabSection from '../../../../../TabSection'
import { DollarSign } from 'lucide-react'
import { percentageFormat } from '@/utils/stringFormatter'
import { IdToRemove } from '../..'
import { ComponentEntity } from '@/types/StandardCost'
import { useBaseStore } from '@/store'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'

interface ExtraCostProps {
    component: ComponentEntity
    onRemoveCostDialogOpen: () => void
    setIdToRemove: (props: IdToRemove) => void
}

const ExtraCost = ({
    component,
    onRemoveCostDialogOpen,
    setIdToRemove,
}: ExtraCostProps) => {
    const {
        stdCostData: { components, products },
    } = useBaseStore(stdCostSliceStateSelector)

    return (
        <>
            <TabSection title={'Produto'}>
                <p className="flex items-center gap-2 ">
                    <DollarSign size={20} />
                    <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                        {component.PRODUTO_COMPONENTE}
                    </span>
                </p>
            </TabSection>
            <TabSection title="Tipo">
                <p className="text-center text-purple-500">Custo extra</p>
            </TabSection>{' '}
            <TabSection title="Impostos">
                <table className="">
                    <thead className="text-left">
                        <tr>
                            <th className="text-primary-600 px-4 w-[100px]">
                                Descrição
                            </th>
                            <th className="text-primary-600 px-4 w-[100px]">
                                Valor
                            </th>
                            <th className="text-primary-600 px-4 w-[100px]">
                                Aliquota
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="p-1 px-4">PIS</td>
                            <td className="p-1 px-4">
                                {component.VL_PIS || 0}
                            </td>
                            <td className="p-1 px-4">
                                {percentageFormat(
                                    Number(component.VL_ALIQUOTA_PIS) || 0
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">COFINS</td>
                            <td className="p-1 px-4">
                                {component.VL_COFINS || 0}
                            </td>
                            <td className="p-1 px-4">
                                {percentageFormat(
                                    Number(component.VL_ALIQUOTA_COFINS) || 0
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">FCP</td>
                            <td className="p-1 px-4">
                                {component.VL_FCP || 0}
                            </td>
                            <td className="p-1 px-4">
                                {percentageFormat(
                                    Number(component.VL_ALIQUOTA_FCP) || 0
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">ICMS</td>
                            <td className="p-1 px-4">
                                {component.VL_ICMS || 0}
                            </td>
                            <td className="p-1 px-4">
                                {percentageFormat(
                                    Number(component.VL_ALIQUOTA_ICMS) || 0
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">IPI</td>
                            <td className="p-1 px-4">
                                {component.VL_IPI || 0}
                            </td>
                            <td className="p-1 px-4">
                                {percentageFormat(
                                    Number(component.VL_ALIQUOTA_IPI) || 0
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">ST</td>
                            <td className="p-1 px-4">{component.VL_ST || 0}</td>
                            <td className="p-1 px-4">
                                {percentageFormat(
                                    Number(component.VL_ALIQUOTA_ST) || 0
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </TabSection>
            {component.MANIPULA_COMPONENTE === 1 && (
                <button
                    className="w-full py-2 text-center text-red-500 rounded-md bg-red-50"
                    onClick={() => {
                        setIdToRemove({
                            id: Number(component.SK_PRODUTO_COMPONENTE),
                            isExtraCost: true,
                            parentId:
                                components.entities[component._parentId]?._id ||
                                products.entities[component._parentId]._id ||
                                null,
                        })
                        onRemoveCostDialogOpen()
                    }}
                >
                    Remover
                </button>
            )}
        </>
    )
}

export default ExtraCost

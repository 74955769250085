import { ReactNode, useMemo } from 'react'
import Table from '@/components/Table'
import { TableCustomData } from '@/components/Table/type'
import { ProductStructureFlat } from '@/types/Product'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns-tz'
import { getDateFromId } from '@/utils/date'
import { numberFormat } from '@/utils/stringFormatter'
import TableRowActions from '@/components/Table/components/TableRowActions'
import DeleteDialog from '../DeleteDialog'
import EditDialog from '../EditDialog'
import DadosMestreHeader from '@/pages/DadosMestre/components/DadosMestreHeader'
import DadosMestreHeaderActions from '@/pages/DadosMestre/components/DadosMestreHeaderActions'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { ListTree } from 'lucide-react'
import { useTable } from '@/hooks/useTable'
import { cn } from '@/lib/utils'
import DefaultCell from '@/pages/DadosMestre/components/DefaultCell'

interface FlatTableProps {
    data: ProductStructureFlat[]
    title: string
    isLoading: boolean
    isFetching: boolean
    onChangeTreeStructure: () => void
    onAddDialogOpen: () => void
    onDownload: () => void
    refetch: () => void
}

export type ProductStructureTable = ProductStructureFlat &
    TableCustomData<ProductStructureFlat>

const columnHelper = createColumnHelper<ProductStructureFlat>()

const FlatTable = ({
    data,
    isFetching,
    isLoading,
    title,
    onChangeTreeStructure,
    onAddDialogOpen,
    onDownload,
    refetch,
}: FlatTableProps) => {
    const memoData = useMemo(() => data, [data])

    const {
        selectedRow,
        isDeleteDialogOpen,
        isEditDialogOpen,
        columnOrder,
        onColumnOrderChange,
        onDeleteDialogClose,
        onEditDialogClose,
        onClickDelete,
        onClickEdit,
    } = useTable<ProductStructureTable>(
        import.meta.env.VITE_DADOS_MESTRE_PRODUCT_STRUCTURE_TABLE_TOKEN + 'FLAT'
    )

    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('NK_PRODUTO', {
                id: 'sk_produto',
                header: 'Cod produto',
                size: 100,
            }),
            columnHelper.accessor('PRODUTO_ESTRUTURA', {
                id: 'produto',
                header: 'Produto',
                size: 300,
            }),
            columnHelper.accessor('NK_COMPONENTE', {
                id: 'sk_componente',
                header: 'Cod componente',
                size: 100,
            }),
            columnHelper.accessor('PRODUTO_COMPONENTE', {
                id: 'componente',
                header: 'Componente',
                size: 300,
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'empresa',
                header: 'Empresa',
                size: 150,
            }),
            columnHelper.accessor(
                ({ QTD_COMPONENTE }) => numberFormat(QTD_COMPONENTE, 6),
                {
                    id: 'qtde',
                    header: 'Qtde',
                    size: 100,
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                }
            ),
            columnHelper.accessor('UM_PRODUTO_COMPONENTE', {
                id: 'um',
                header: 'U.M',
                size: 90,
            }),
            columnHelper.accessor('DS_REVISAO_INICIAL', {
                id: 'DS_REVISAO_INICIAL',
                header: 'Rev inicial',
                size: 90,
            }),
            columnHelper.accessor('DS_REVISAO_FINAL', {
                id: 'DS_REVISAO_FINAL',
                header: 'Rev final',
                size: 90,
            }),
            columnHelper.accessor(
                ({ SK_TEMPO_INICIAL }) =>
                    format(getDateFromId(SK_TEMPO_INICIAL), 'dd/MM/yyyy'),
                {
                    id: 'SK_TEMPO_INICIAL',
                    header: 'Dt revisão inicial',
                    size: 100,
                }
            ),
            columnHelper.accessor(
                ({ SK_TEMPO_FINAL }) =>
                    format(getDateFromId(SK_TEMPO_FINAL), 'dd/MM/yyyy'),
                {
                    id: 'SK_TEMPO_FINAL',
                    header: 'Dt revisão final',
                    size: 100,
                }
            ),
            columnHelper.accessor(
                ({ DD_PRODUTO_PROPRIO }) =>
                    DD_PRODUTO_PROPRIO === 1 ? 'SIM' : 'NÃO',
                {
                    id: 'dd_produto_proprio',
                    header: 'Produto Próprio',
                    cell: ({ getValue }) => {
                        return (
                            <span
                                className={cn(
                                    'py-1 px-2 rounded-md',
                                    getValue() === 'SIM'
                                        ? 'bg-emerald-50 text-emerald-500'
                                        : 'bg-orange-50 text-orange-500'
                                )}
                            >
                                {getValue()}
                            </span>
                        )
                    },
                    meta: {
                        cell: {
                            className: 'justify-center',
                        },
                    },
                    size: 100,
                }
            ),
            columnHelper.accessor('DD_ORIGEM_DADO', {
                id: 'origem',
                header: 'Origem',
                cell: ({ getValue }) => {
                    const value = getValue()
                    let Badge: ReactNode | null = null

                    switch (value) {
                        case 'MANUAL':
                            Badge = (
                                <span className="px-2 py-1 text-xs text-green-600 rounded-md bg-green-50">
                                    {value}
                                </span>
                            )
                            break
                        case 'PROTHEUS':
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-md text-cyan-600 bg-blue-50">
                                    {value}
                                </span>
                            )

                            break
                    }

                    return Badge
                },
                size: 100,
                meta: {
                    cell: {
                        className: 'flex items-center justify-center',
                    },
                },
            }),
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    return (
                        row.original.DD_ORIGEM_DADO === 'MANUAL' && (
                            <TableRowActions
                                onClickDelete={() => onClickDelete(row)}
                                onClickEdit={() => onClickEdit(row)}
                            />
                        )
                    )
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
        ],
        []
    )
    return (
        <>
            <Table<ProductStructureTable>
                data={memoData}
                isLoading={isLoading}
                isFetching={isFetching}
                columns={memoColumns}
                columnOrderState={columnOrder}
                onColumnOrderStateChange={onColumnOrderChange}
                virtualize
                stripe
                tableHeader={<DadosMestreHeader title={title} />}
                tableActions={
                    <DadosMestreHeaderActions
                        onDownload={onDownload}
                        CustomButtons={
                            <ButtonWithTooltip
                                className="flex items-center justify-center text-orange-600 rounded-md w-9 h-9 bg-orange-50"
                                tooltipContent={<p>Dados Flat</p>}
                                onClick={() => onChangeTreeStructure()}
                            >
                                <ListTree size={18} />
                            </ButtonWithTooltip>
                        }
                        onRefech={refetch}
                        onAdd={onAddDialogOpen}
                    />
                }
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultCell>{getValue() as string}</DefaultCell>
                    ),
                }}
            />
            {selectedRow && (
                <>
                    <DeleteDialog
                        row={selectedRow}
                        isOpen={isDeleteDialogOpen}
                        onClose={onDeleteDialogClose}
                    />
                    <EditDialog
                        row={selectedRow}
                        isOpen={isEditDialogOpen}
                        onClose={onEditDialogClose}
                    />
                </>
            )}
        </>
    )
}

export default FlatTable

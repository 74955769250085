import { useCurrencyQuery } from '@/queries/useCurrencyQuery'
import { useBaseStore } from '@/store'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'
import { Taxes } from '@/types/Costs'
import { CurrencyDS } from '@/types/Currency'
import { ComponentEntity, Product, ProductEntity } from '@/types/StandardCost'
import { lastDayOfMonth } from 'date-fns'
import { useCallback, useEffect, useMemo, useReducer } from 'react'

interface Tax {
    groupName: string
    inputs: {
        name: keyof Taxes
        label: string
        value: string
    }[]
}

export interface AddDialogState {
    taxes: Tax[]
    date: Date
    dateRange: {
        from: Date
        to: Date
    }
    products: {
        all: Product[]
        filtered: Product[]
    }
    component?: ComponentEntity
    componetParent?: ProductEntity
    productIds: string[]
    currencyId?: string
    currencies?: CurrencyDS[]
    costValue: string
}
export enum AddDialogActionNames {
    SET_INITIAL_DATA = 'SET_INITIAL_DATA',
    SET_PRODUCTS = 'SET_PRODUCTS',
    SET_CURRENCIES = 'SET_CURRENCIES',
    ON_CHANGE_TAX_VALUE = 'ON_CHANGE_TAX_VALUE',
    ON_SELECT_PRODUCT_STRUCTURE = 'ON_SELECT_PRODUCT_STRUCTURE',
    ON_SELECT_ALL_PRODUCTS = 'ON_SELECT_ALL_PRODUCTS',
    ON_CHANGE_DATE = 'ON_CHANGE_DATE',
    ON_CHANGE_DATE_RANGE = 'ON_CHANGE_DATE_RANGE',
    ON_CHANGE_CURRENCY = 'ON_CHANGE_CURRENCY',
    ON_CHANGE_COST = 'ON_CHANGE_COST',
    RESET = 'RESET',
}

type AddDialogActions =
    | {
          type: AddDialogActionNames.SET_INITIAL_DATA
          payload: AddDialogState
      }
    | {
          type: AddDialogActionNames.SET_CURRENCIES
          payload: CurrencyDS[]
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_DATE
          payload: { date: Date }
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_DATE_RANGE
          payload: {
              from: Date
              to: Date
          }
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_CURRENCY
          payload: {
              currencyId: string
          }
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_COST
          payload: {
              cost: string
          }
      }
    | {
          type: AddDialogActionNames.ON_SELECT_PRODUCT_STRUCTURE
          payload: {
              productId: string
          }
      }
    | {
          type: AddDialogActionNames.ON_SELECT_ALL_PRODUCTS
          payload: {
              productIds: string[]
          }
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_TAX_VALUE
          payload: {
              taxId: string
              value: string
          }
      }
    | {
          type: AddDialogActionNames.RESET
      }

const useAddDialog = () => {
    const {
        selectedStdCostRow: selectedRow,
        selectedDate,
        stdCostData: { components, products },
    } = useBaseStore(stdCostSliceStateSelector)

    const getParent = (parentId: string | number) => {
        const parent =
            products.entities[parentId] || components.entities[parentId]
        return parent._parentId ? products.entities[parent._parentId] : parent
    }

    const component =
        selectedRow && !selectedRow.isProduct
            ? components.entities[selectedRow.id]
            : null

    // const product = component
    //     ? getProductById(component._parentId) ||
    //       getComponentById(component._parentId)
    //     : null
    const product = component ? getParent(component._parentId) : null

    const TAXES: Tax[] = useMemo(
        () => [
            {
                groupName: 'PIS',
                inputs: [
                    {
                        name: 'VL_PIS',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_PIS',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'COFINS',
                inputs: [
                    {
                        name: 'VL_COFINS',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_COFINS',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'ICMS',
                inputs: [
                    {
                        name: 'VL_ICMS',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_ICMS',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'IPI',
                inputs: [
                    {
                        name: 'VL_IPI',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_IPI',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'ST',
                inputs: [
                    {
                        name: 'VL_ST',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_ST',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'FCP',
                inputs: [
                    {
                        name: 'VL_FCP',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_FCP',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
        ],
        []
    )

    const { data: currencyData, isLoading: isCurrencyLoading } =
        useCurrencyQuery()

    useEffect(() => {
        if (component && product) {
            const allProducts = products.ids
                .map((id) => products.entities[id])
                .map((product) => ({
                    ...product,
                    COMPONENTES: product.COMPONENTES.map(
                        (componentId) => components.entities[componentId]
                    ),
                }))

            const filtered: typeof allProducts = allProducts.filter(
                (product) =>
                    product.COMPONENTES.some(
                        (comp) =>
                            comp.SK_PRODUTO_COMPONENTE ===
                            component.SK_PRODUTO_COMPONENTE
                    ) ||
                    product.COMPONENTES.some((comp) =>
                        comp.COMPONENTES.some(
                            (c) =>
                                components.entities[c].SK_PRODUTO_COMPONENTE ===
                                component.SK_PRODUTO_COMPONENTE
                        )
                    )
            )

            const idSelected = product.SK_PRODUTO_ESTRUTURA

            dispatch({
                type: AddDialogActionNames.SET_INITIAL_DATA,
                payload: {
                    taxes: TAXES,
                    products: {
                        all: allProducts,
                        filtered: filtered,
                    },
                    productIds: [idSelected],
                    component,
                    componetParent: product,
                    costValue: '0',
                    currencyId: currencyData
                        ? currencyData[0].SK_MOEDA.toString()
                        : undefined,
                    currencies: [],
                    dateRange: {
                        from: new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            1
                        ),
                        to: lastDayOfMonth(new Date()),
                    },
                    date: new Date(),
                },
            })
        }
    }, [])

    useEffect(() => {
        if (currencyData) {
            dispatch({
                type: AddDialogActionNames.SET_CURRENCIES,
                payload: currencyData,
            })
        }
    }, [currencyData])

    const reducer = useCallback(
        (state: AddDialogState, action: AddDialogActions): AddDialogState => {
            switch (action.type) {
                case AddDialogActionNames.SET_INITIAL_DATA: {
                    const data = action.payload

                    return { ...data }
                }
                case AddDialogActionNames.SET_CURRENCIES: {
                    const currencies = action.payload

                    return {
                        ...state,
                        currencies,
                        currencyId: currencies[0].SK_MOEDA.toString(),
                    }
                }
                case AddDialogActionNames.ON_CHANGE_CURRENCY: {
                    const { currencyId } = action.payload

                    return {
                        ...state,
                        currencyId,
                    }
                }
                case AddDialogActionNames.ON_CHANGE_COST: {
                    const { cost } = action.payload
                    return {
                        ...state,
                        costValue: cost,
                    }
                }
                case AddDialogActionNames.ON_CHANGE_DATE: {
                    const { date } = action.payload
                    return {
                        ...state,
                        date,
                    }
                }
                case AddDialogActionNames.ON_CHANGE_DATE_RANGE: {
                    const { to, from } = action.payload
                    return {
                        ...state,
                        dateRange: {
                            from: from,
                            to: to,
                        },
                    }
                }
                case AddDialogActionNames.ON_SELECT_ALL_PRODUCTS: {
                    const { productIds } = action.payload

                    return {
                        ...state,
                        productIds,
                    }
                }
                case AddDialogActionNames.ON_SELECT_PRODUCT_STRUCTURE: {
                    const { productId } = action.payload

                    return {
                        ...state,
                        productIds: state.productIds.includes(productId)
                            ? state.productIds.filter((id) => id !== productId)
                            : [...state.productIds, productId],
                    }
                }
                case AddDialogActionNames.ON_CHANGE_TAX_VALUE: {
                    const { taxId, value } = action.payload
                    return {
                        ...state,
                        taxes: state.taxes.map((taxGroup) => {
                            return {
                                ...taxGroup,
                                inputs: taxGroup.inputs.map((input) => {
                                    if (input.name === taxId) {
                                        return {
                                            ...input,
                                            value: value,
                                        }
                                    }
                                    return input
                                }),
                            }
                        }),
                    }
                }
                case AddDialogActionNames.RESET:
                    return {
                        costValue: '0',
                        dateRange: {
                            from: new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                1
                            ),
                            to: lastDayOfMonth(new Date()),
                        },
                        date: new Date(),
                        productIds: [],
                        products: {
                            all: [],
                            filtered: [],
                        },
                        taxes: TAXES,
                        currencies: [],
                        currencyId: '',
                    }
                default:
                    return state
            }
        },
        []
    )

    const [state, dispatch] = useReducer(reducer, {
        costValue: '0',
        dateRange: {
            from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            to: lastDayOfMonth(new Date()),
        },
        date: new Date(),
        productIds: [],
        products: {
            all: [],
            filtered: [],
        },
        taxes: TAXES,
        currencies: [],
        currencyId: '',
    })

    return {
        ...state,
        component,
        product,
        selectedDate,
        selectedRow,
        isCurrencyLoading,
        dispatch,
    }
}
export default useAddDialog

import StdCostActionsDropdown from '@/pages/StdCosts/components/StdCostActionsDropdown'
import { RefreshCcw, Search } from 'lucide-react'
import { Button } from '@/components/ui/button'
import SaveViewDialog from '@/pages/StdCosts/components/SaveViewDialog'
import LayoutsDialog from '@/pages/StdCosts/components/LayoutsDialog'
import SaveLayoutDialog from '@/pages/StdCosts/components/SaveLayoutDialog'
import FreezeDialog from '@/pages/StdCosts/components/FreezeDialog'
import { FormEvent, useEffect, useMemo, useState } from 'react'
import Select from '@/components/Select'
import { getMonthsFromRange, getYearsFromRange } from '@/utils/date'
import { useBaseStore } from '@/store'
import { useStdCostSpreadsheet } from '../../useStdCostSpreadsheet'
import useDisclosure from '@/hooks/useDisclosure'
import { useStdCostsQuery } from '@/queries/useStdCostsQuery'
import { useCostViewQuery } from '@/queries/useCostViewQuery'
import { Store } from '@/store/type'

const stateSelector = (state: Store) => ({
    selectedDate: state.stdCostSlice.state.selectedDate,
    isAnualAvg: state.stdCostSlice.state.isAnualAvg,
    stdCostData: state.stdCostSlice.state.stdCostData,
})

const actionsSelector = (state: Store) => ({
    onChangeDate: state.stdCostSlice.actions.onChangeDate,
    onCloseDetails: state.stdCostSlice.actions.onCloseDetails,
})

const StdCostHeader = () => {
    const { selectedDate, isAnualAvg, stdCostData } =
        useBaseStore(stateSelector)
    const { onChangeDate: onSelectDate, onCloseDetails: closeDetails } =
        useBaseStore(actionsSelector)

    const { isSuccess: isCostViewSuccess } = useCostViewQuery({
        SK_TEMPO: selectedDate.id,
    })

    const { refetch: onRefetch } = useStdCostsQuery({
        date: selectedDate.id,
        enabled: isCostViewSuccess,
        isAnualAvg,
    })

    const [date, setDate] = useState<{ month: number; year: number }>(
        selectedDate
    )

    const months = useMemo(() => getMonthsFromRange(), [])
    const years = useMemo(() => getYearsFromRange(2020), [])

    const onChangeMonth = (value: string) => {
        setDate((prev) => ({ ...prev, month: Number(value) }))
    }

    const onChangeYear = (value: string) => {
        setDate((prev) => ({ ...prev, year: Number(value) }))
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        closeDetails()
        onSelectDate(date.year, date.month)
    }

    const { onDownload, onDownloadSum } = useStdCostSpreadsheet()

    const {
        isOpen: isSaveDialogOpen,
        onClose: onSaveDialogClose,
        onOpen: onSaveDialogOpen,
    } = useDisclosure()

    const {
        isOpen: isLayoutsDialogOpen,
        onClose: onLayoutsDialogClose,
        onOpen: onLayoutsDialogOpen,
    } = useDisclosure()

    const {
        isOpen: isSaveLayoutDialogOpen,
        onClose: onSaveLayoutDialogClose,
        onOpen: onSaveLayoutDialogOpen,
    } = useDisclosure()

    const {
        isOpen: isFreezeDialogOpen,
        onClose: onFreezeDialogClose,
        onOpen: onFreezeDialogOpen,
    } = useDisclosure()

    const canMakeActions =
        stdCostData.components.ids.length > 0 &&
        stdCostData.products.ids.length > 0

    useEffect(() => {
        const keyDown = (e: KeyboardEvent) => {
            if (e.key === 'L' && e.shiftKey && e.ctrlKey) {
                onLayoutsDialogOpen()
            }
            if (e.key === 'S' && e.shiftKey && e.ctrlKey) {
                onSaveLayoutDialogOpen()
            }
            if (e.key === 'V' && e.shiftKey && e.ctrlKey) {
                onSaveDialogOpen()
            }
            if (e.key === 'D' && e.shiftKey && e.ctrlKey) {
                onDownload()
            }
            if (e.key === 'T' && e.shiftKey && e.ctrlKey) {
                onDownloadSum()
            }
        }

        document.addEventListener('keydown', keyDown)

        return () => document.removeEventListener('keydown', keyDown)
    }, [])

    return (
        <>
            <div className="flex flex-col justify-between gap-4 mb-4 sm:items-center sm:flex-row">
                <div>
                    <div className="flex items-center gap-4">
                        <h1 className="text-lg font-semibold">
                            Visão geral do custo padrão
                        </h1>
                        <div className="flex items-center">
                            <Button
                                variant="ghost"
                                className="px-3 hover:text-primary-600 hover:bg-slate-50"
                                onClick={() => onRefetch()}
                                disabled={!canMakeActions}
                            >
                                <RefreshCcw size={18} />
                            </Button>
                            <StdCostActionsDropdown
                                disabled={!canMakeActions}
                                onOpenViewDialog={onSaveDialogOpen}
                                onOpenLayoutsDialog={onLayoutsDialogOpen}
                                onOpenSaveLayoutDialog={onSaveLayoutDialogOpen}
                                onOpenFreezeDialog={onFreezeDialogOpen}
                                onDownloadSum={onDownloadSum}
                            />
                        </div>
                    </div>
                    <div>
                        <p className="text-sm text-neutral-500">
                            Mostrando dados do perído{' '}
                            {(selectedDate.month + 1)
                                .toString()
                                .padStart(2, '0')}
                            /{selectedDate.year}
                        </p>
                    </div>
                </div>

                <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2">
                        <button className="w-10 h-10" onClick={onSubmit}>
                            <Search size={18} absoluteStrokeWidth />
                        </button>
                        <Select
                            className="w-[65px]"
                            options={months.map((month) => ({
                                label: month.short,
                                value: month.number.toString(),
                            }))}
                            placeholder="Mês"
                            onChange={onChangeMonth}
                            value={date.month.toString()}
                        />
                        <Select
                            className="w-[80px]"
                            options={years.map((year) => ({
                                label: year.long.toString(),
                                value: year.long.toString(),
                            }))}
                            placeholder="Ano"
                            onChange={onChangeYear}
                            value={date.year.toString()}
                        />
                    </div>
                </div>
            </div>
            {isSaveDialogOpen && (
                <SaveViewDialog
                    isOpen={isSaveDialogOpen}
                    onClose={onSaveDialogClose}
                />
            )}
            {isLayoutsDialogOpen && (
                <LayoutsDialog
                    isOpen={isLayoutsDialogOpen}
                    onOpen={onLayoutsDialogClose}
                    onOpenSaveLayoutDialog={onSaveLayoutDialogOpen}
                />
            )}
            {isSaveLayoutDialogOpen && (
                <SaveLayoutDialog
                    isOpen={isSaveLayoutDialogOpen}
                    onOpen={onSaveLayoutDialogClose}
                />
            )}
            {isFreezeDialogOpen && (
                <FreezeDialog
                    isOpen={isFreezeDialogOpen}
                    onClose={onFreezeDialogClose}
                />
            )}
        </>
    )
}

export default StdCostHeader

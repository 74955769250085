import Table from '@/components/Table'
import { TableCustomData } from '@/components/Table/type'
import TableRowActions from '@/components/Table/components/TableRowActions'
import { CurrencyDS, CurrencyValue } from '@/types/Currency'
import { createColumnHelper } from '@tanstack/react-table'
import { ReactNode, useMemo } from 'react'
import DeleteDialog from './components/DeleteDialog'
import EditDialog from './components/EditDialog'
import AddDialog from './components/AddDialog'
import { useCurrencyValuesQuery } from '@/queries/useCurrencyValuesQuery'
import { format, isEqual, isToday } from 'date-fns'
import { currencyFormat } from '@/utils/stringFormatter'
import { getDateFromId } from '@/utils/date'
import { useCurrencySpreadsheet } from './useCurrencySpreadsheet'
import DadosMestreHeader from '../components/DadosMestreHeader'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'
import { useTable } from '@/hooks/useTable'
import DefaultCell from '../components/DefaultCell'

export type CurrencyTable = CurrencyValue & TableCustomData<CurrencyDS>

const columnHelper = createColumnHelper<CurrencyValue>()

const CurrencyDadosMestre = () => {
    const { data, isLoading, isFetching, refetch } = useCurrencyValuesQuery()

    const {
        table,
        tableState,
        isAddDialogOpen,
        isDeleteDialogOpen,
        isEditDialogOpen,
        selectedRow,
        selectedRows,
        columnOrder,
        onColumnOrderChange,
        onAddDialogClose,
        onClickAdd,
        onClickDelete,
        onClickEdit,
        onClickMultiDelete,
        onDeleteDialogClose,
        onEditDialogClose,
        setSelectedRows,
        setTable,
        onTableStateChange,
    } = useTable<CurrencyTable>(
        import.meta.env.VITE_DADOS_MESTRE_CURRENCY_TABLE_TOKEN
    )

    const { onDownload } = useCurrencySpreadsheet()

    const memoData = useMemo(
        () => data?.sort((a, b) => (a.SK_TEMPO < b.SK_TEMPO ? 1 : -1)) || [],
        [data]
    )

    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('DS_MOEDA', {
                id: 'moeda',
                header: 'Moeda',
                size: 80,
            }),
            columnHelper.accessor(
                ({ VL_MOEDA }) => currencyFormat(VL_MOEDA, 4),
                {
                    id: 'vl_moeda',
                    header: 'Valor',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                }
            ),
            columnHelper.accessor(
                ({ SK_TEMPO }) => format(getDateFromId(SK_TEMPO), 'dd/MM/yyyy'),
                {
                    id: 'sk_tempo',
                    header: 'Data',
                    size: 100,
                    sortingFn: (rowA, rowB) =>
                        rowA.original.SK_TEMPO < rowB.original.SK_TEMPO
                            ? 1
                            : -1,
                }
            ),
            columnHelper.accessor('NOME', {
                id: 'nome',
                header: 'Usuário',
                size: 200,
            }),

            columnHelper.accessor(
                ({ CREATED_AT }) =>
                    format(new Date(CREATED_AT), 'dd/MM/yyyy HH:mm:ss'),
                {
                    id: 'CREATED_AT',
                    header: 'Criado em',
                }
            ),
            columnHelper.accessor(
                ({ UPDATED_AT }) =>
                    format(new Date(UPDATED_AT), 'dd/MM/yyyy HH:mm:ss'),
                {
                    id: 'UPDATED_AT',
                    header: 'Atualizado em',
                }
            ),
            columnHelper.accessor('DD_ORIGEM_DADO', {
                id: 'origem',
                header: 'Origem',
                cell: ({ getValue }) => {
                    const value = getValue()
                    let Badge: ReactNode | null = null

                    switch (value) {
                        case 'MANUAL':
                            Badge = (
                                <span className="px-2 py-1 text-xs text-green-600 rounded-md bg-green-50">
                                    {value}
                                </span>
                            )
                            break
                        case 'PROTHEUS':
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-md text-cyan-600 bg-blue-50">
                                    {value}
                                </span>
                            )

                            break
                    }

                    return Badge
                },
                size: 100,
                meta: {
                    cell: {
                        className: 'justify-center',
                    },
                },
            }),
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    const { DD_ORIGEM_DADO } = row.original
                    return (
                        DD_ORIGEM_DADO !== 'PROTHEUS' && (
                            <TableRowActions
                                onClickDelete={() => onClickDelete(row)}
                                onClickEdit={() => onClickEdit(row)}
                            />
                        )
                    )
                },
                size: 100,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.display({
                id: 'badge',
                cell: ({ row }) => {
                    const { CREATED_AT, UPDATED_AT } = row.original
                    let Badge: ReactNode | null = null
                    const createdAt = new Date(CREATED_AT)
                    const updatedAt = new Date(UPDATED_AT)

                    if (isEqual(createdAt, updatedAt)) {
                        if (isToday(createdAt)) {
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-md text-emerald-500 ">
                                    Novo *
                                </span>
                            )
                        }
                    } else {
                        Badge = (
                            <span className="px-2 py-1 text-xs rounded-md text-zinc-400">
                                Editado
                            </span>
                        )
                    }

                    return Badge
                },
                size: 80,
                enableColumnFilter: false,
                enableSorting: false,
                meta: {
                    cell: {
                        className: 'justify-center',
                    },
                },
            }),
        ],

        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<CurrencyTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_VALOR_MOEDA.toString()}
                isLoading={isLoading}
                isFetching={isFetching}
                getTableInstance={(table) => setTable(table)}
                rowSelection={selectedRows}
                onRowSelectionChange={setSelectedRows}
                columnOrderState={columnOrder}
                onColumnOrderStateChange={onColumnOrderChange}
                virtualize
                stripe
                onTableStateChange={onTableStateChange}
                tableState={tableState}
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultCell>{getValue() as string}</DefaultCell>
                    ),
                }}
                tableHeader={
                    <DadosMestreHeader
                        title={`Moeda ${data ? `(${data.length})` : ''}`}
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onDownload={() => table && onDownload(table)}
                        onAdd={onClickAdd}
                        onRefech={refetch}
                    />
                }
            />
            {isDeleteDialogOpen && (
                <DeleteDialog
                    ids={
                        selectedRow
                            ? [selectedRow.original.SK_VALOR_MOEDA]
                            : table
                            ? table
                                  .getFilteredRowModel()
                                  .flatRows.filter(
                                      (row) => selectedRows[row.id]
                                  )
                                  .map((row) => row.original.SK_VALOR_MOEDA)
                            : []
                    }
                    isOpen={isDeleteDialogOpen}
                    onClose={onDeleteDialogClose}
                />
            )}

            {selectedRow && (
                <EditDialog
                    row={selectedRow}
                    isOpen={isEditDialogOpen}
                    onClose={onEditDialogClose}
                />
            )}
            <AddDialog isOpen={isAddDialogOpen} onClose={onAddDialogClose} />
        </div>
    )
}

export default CurrencyDadosMestre

import ConfirmDialog from '@/components/ConfirmDialog'
import { Row } from '@tanstack/react-table'
import { InformedCostTable } from '../..'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import useEditDialog, { EditDialogActionNames } from './useEditDialog'
import { Separator } from '@/components/ui/separator'
import Select from '@/components/Select'
import { updateInformedCost } from '@/api/business/informedCost'
import { informedCostKeys } from '@/queries/useInformedCostQuery'
import { Taxes } from '@/types/Costs'
import { getIdFromDate } from '@/utils/date'
import DatePickerMonthYear from '@/components/DatePickerMonthYear'
import { isAxiosError } from '@/api/business'

interface EditDialogProps {
    row: Row<InformedCostTable>
    isOpen: boolean
    onClose: () => void
}

const EditDialog = ({ row, ...props }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: updateInformedCost,
        onSuccess: () => {
            queryClient.invalidateQueries(informedCostKeys.lists())
            toast({
                title: 'Custo informado atualizado com sucesso',
            })
            props.onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao atualizar custo informado',
                        description: 'Verifique os dados e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const { original } = row

    const {
        costValue,
        currencies,
        currencyId,
        date,
        isCurrencyLoading,
        taxes,
        dispatch,
    } = useEditDialog(original)

    const onSubmit = () => {
        if (!original || !date) return

        const formattedTaxes: Taxes = taxes.reduce((tax, curr) => {
            return {
                ...tax,
                ...curr.inputs.reduce((tx, curr) => {
                    return {
                        ...tx,
                        [curr.name]: curr.value,
                    }
                }, {} as Taxes),
            }
        }, {} as Taxes)

        mutate({
            IDENTIFICADOR: original.SK_NOVO_CUSTO,
            CAMPOS: {
                SK_TEMPO: Number(getIdFromDate(date).join('')),
                SK_MOEDA: Number(currencyId),
                VL_CUSTO_ORCADO: Number(costValue),
                ...formattedTaxes,
            },
        })
    }

    return (
        <ConfirmDialog
            className="max-w-5xl"
            title="Editar custo informado"
            onConfirm={onSubmit}
            isLoading={mutateLoading}
            {...props}
        >
            <div className="flex items-center h-full gap-10 py-4">
                <div className="flex-1 h-full space-y-4">
                    <div>
                        <p className="font-semibold">Informações</p>
                        <Separator />
                    </div>
                    <div>
                        <label htmlFor="">Empresa</label>
                        <input
                            className="w-full p-2 border rounded-md"
                            type="text"
                            value={original.SK_EMPRESA}
                            disabled
                        />
                    </div>
                    <div>
                        <label htmlFor="">Estrutura</label>
                        <input
                            className="w-full p-2 border rounded-md"
                            type="text"
                            value={original.PRODUTO_ESTRUTURA}
                            disabled
                        />
                    </div>
                    <div>
                        <label htmlFor="">Produto</label>
                        <input
                            className="w-full p-2 border rounded-md"
                            type="text"
                            value={original.PRODUTO_COMPONENTE}
                            disabled
                        />
                    </div>

                    <div>
                        <label htmlFor="">Data</label>
                        <DatePickerMonthYear
                            // className="w-full"
                            month={date.getMonth()}
                            year={date.getFullYear()}
                            setDate={(date) => {
                                if (!date) return
                                dispatch({
                                    type: EditDialogActionNames.ON_CHANGE_DATE,
                                    payload: {
                                        date,
                                    },
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="flex-1 h-full space-y-4">
                    <div>
                        <p className="font-semibold">Custos</p>
                        <Separator />
                    </div>
                    <div className="flex items-center gap-4">
                        <div className="flex-1">
                            <label htmlFor="">Custo</label>
                            <input
                                id="quantity"
                                className="block w-full p-2 border rounded-md"
                                name="quantity"
                                type="number"
                                value={costValue}
                                onChange={(e) =>
                                    dispatch({
                                        type: EditDialogActionNames.ON_CHANGE_COST,
                                        payload: {
                                            cost: e.target.value,
                                        },
                                    })
                                }
                            />
                        </div>
                        <div>
                            <label htmlFor="">Moeda</label>
                            <Select
                                placeholder={
                                    isCurrencyLoading
                                        ? `Carregando moedas...`
                                        : ''
                                }
                                value={currencyId}
                                options={currencies.map((currency) => ({
                                    label: currency.DS_MOEDA,
                                    value: currency.SK_MOEDA.toString(),
                                }))}
                                onChange={(value) =>
                                    dispatch({
                                        type: EditDialogActionNames.ON_CHANGE_CURRENCY,
                                        payload: {
                                            currencyId: value,
                                        },
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="space-y-4">
                        <div>
                            <p className="font-semibold">Impostos</p>
                            <Separator />
                        </div>
                        <div className="grid grid-cols-2 w-fit gap-x-6 gap-y-6">
                            {taxes.map((taxGroup, idx) => (
                                <div
                                    key={idx}
                                    className="relative flex items-center gap-4 p-2 py-3 border rounded-md border-neutral-300 "
                                >
                                    <span className="absolute px-2 bg-white -top-3 left-5">
                                        {taxGroup.groupName}
                                    </span>
                                    {taxGroup.inputs.map((tax) => (
                                        <div
                                            key={tax.name}
                                            className="flex flex-col"
                                        >
                                            <label
                                                htmlFor={tax.name}
                                                className="text-neutral-400 whitespace-nowrap"
                                            >
                                                {tax.label}
                                            </label>
                                            <input
                                                className="w-full px-4 py-1 text-base border rounded-md border-divider-color"
                                                type="number"
                                                id={tax.name}
                                                name={tax.name}
                                                value={tax.value}
                                                onChange={(e) =>
                                                    dispatch({
                                                        type: EditDialogActionNames.ON_CHANGE_TAX_VALUE,
                                                        payload: {
                                                            taxId: tax.name,
                                                            value: e.target
                                                                .value,
                                                        },
                                                    })
                                                }
                                                step="any"
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </ConfirmDialog>
    )
}

export default EditDialog

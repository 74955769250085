import { AcceptTypes, ProductParent } from '@/store/productStructureSlice/type'
import DroppedProduct from '../DroppedProduct'
import { useDrop } from 'react-dnd'
import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'

interface CompanyDropSectionProps {
    id: string
    title: string
    items: ProductParent[]
}

const stateSelector = (state: Store) => ({
    dropItem: state.productStructureSlice.actions.dropItem,
})

const CompanyDropSection = ({ id, title, items }: CompanyDropSectionProps) => {
    const { dropItem } = useBaseStore(stateSelector)

    const [{ isOver, canDrop }, drop] = useDrop<
        ProductParent,
        unknown,
        { isOver: boolean; canDrop: boolean }
    >(() => ({
        accept: [AcceptTypes.PRODUCT],
        canDrop: (item) => {
            if (item.SK_EMPRESA !== id) return false

            return true
        },
        drop: (item, monitor) => {
            if (!monitor.didDrop()) {
                dropItem({ ...item, type: AcceptTypes.PARENT })
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true }),
            canDrop: monitor.canDrop(),
        }),
    }))

    return (
        <div
            className={cn(
                'p-2 w-full rounded-md',
                isOver && canDrop ? 'bg-neutral-100' : ''
            )}
            ref={drop}
        >
            <div className="p-2">
                <p className="text-sm text-neutral-600">{title}</p>
            </div>
            <div>
                {items.map((item) => {
                    return <DroppedProduct key={item.id} product={item} />
                })}
            </div>
        </div>
    )
}

export default CompanyDropSection

import ConfirmDialog from '@/components/ConfirmDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createExtraCost } from '@/api/business/stdCost'
import { useToast } from '@/components/ui/use-toast'
import { stdCostKeys } from '@/queries/useStdCostsQuery'
import { isAxiosError } from '@/api/business'
import useAddExtraCostDialog, {
    AddExtraCostDialogActionNames,
} from './useAddExtraCost'
import { Taxes } from '@/api/business/stdCost/type'
import { Separator } from '@/components/ui/separator'
import {
    ComponentCustomEntity,
    ProductCustomEntity,
} from '@/types/StandardCost'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'
import { useBaseStore } from '@/store'

interface AddExtraCostDialogProps {
    isOpen: boolean
    onClose: () => void
}

const AddExtraCostDialog = ({ isOpen, onClose }: AddExtraCostDialogProps) => {
    const queryClient = useQueryClient()

    const {
        stdCostData: { products },
        isAnualAvg,
    } = useBaseStore(stdCostSliceStateSelector)

    const {
        costValue,
        description,
        productOrComponent,
        selectedDate,
        taxes,
        dispatch,
    } = useAddExtraCostDialog()

    const { toast } = useToast()

    const queryKey = stdCostKeys.list(selectedDate.id, isAnualAvg)

    const { mutate: mutateExtraCost, isLoading: isExtraCostLoading } =
        useMutation({
            mutationFn: createExtraCost,
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey)
                toast({
                    title: 'Custo adicionado com sucesso',
                })
                onClose()
            },
            onError: (err) => {
                if (isAxiosError(err)) {
                    if (
                        err.response?.status === 401 ||
                        err.response?.status === 402
                    ) {
                        toast({
                            title: 'Sem permissão de acesso',
                            description:
                                'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                            variant: 'destructive',
                        })
                    } else {
                        toast({
                            title: 'Erro ao adicionar custo',
                            description:
                                'Não foi possível adicionar o custo, tente novamente',
                            variant: 'destructive',
                        })
                    }
                }
            },
        })

    return (
        <ConfirmDialog
            title="Adicionar custo extra"
            isOpen={isOpen}
            isLoading={isExtraCostLoading}
            onConfirm={() => {
                const taxObj = taxes.reduce((acc, tax) => {
                    return {
                        ...acc,
                        ...tax.inputs.reduce((acc, input) => {
                            return {
                                ...acc,
                                [input.name]: input.value,
                            }
                        }, {} as Taxes),
                    }
                }, {} as Taxes)

                if (productOrComponent) {
                    if (
                        (productOrComponent as ComponentCustomEntity)?._parentId
                    ) {
                        const component =
                            productOrComponent as ComponentCustomEntity

                        const product = products.entities[component._parentId]

                        mutateExtraCost({
                            SK_EMPRESA: product.SK_EMPRESA,
                            SK_PRODUTO_ESTRUTURA: product.SK_PRODUTO_ESTRUTURA,
                            SK_PRODUTO_COMPONENTE:
                                component.SK_PRODUTO_COMPONENTE,
                            SK_TEMPO: [selectedDate.id],
                            DS_CUSTO: description,
                            CATEGORIA_VALOR: 'VARIAVEL',
                            QTD_CUSTO: Number(component.INDICE_STANDARD),
                            VL_CUSTO_ORCADO: Number(costValue),
                            ...taxObj,
                        })
                    } else {
                        const product =
                            productOrComponent as ProductCustomEntity

                        mutateExtraCost({
                            SK_EMPRESA: product.SK_EMPRESA,
                            SK_PRODUTO_ESTRUTURA: product.SK_PRODUTO_ESTRUTURA,
                            SK_PRODUTO_COMPONENTE: null,
                            SK_TEMPO: [selectedDate.id],
                            DS_CUSTO: description,
                            CATEGORIA_VALOR: 'FIXO',
                            QTD_CUSTO: 1,
                            VL_CUSTO_ORCADO: Number(costValue),
                            ...taxObj,
                        })
                    }
                }
            }}
            onClose={onClose}
        >
            <div className="flex flex-col py-6">
                <div>
                    <p className="font-semibold">Custo extra</p>
                    <Separator />
                    <div className="space-y-2">
                        <div>
                            <label htmlFor="" className="mr-4 text-base">
                                Descrição:{' '}
                            </label>
                            <input
                                className="w-full px-4 py-1 text-base border rounded-md border-divider-color"
                                type="text"
                                value={description}
                                onChange={(e) =>
                                    dispatch({
                                        type: AddExtraCostDialogActionNames.ON_CHANGE_DESCRIPTION,
                                        payload: {
                                            description: e.target.value,
                                        },
                                    })
                                }
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex-1 h-full">
                                <label htmlFor="" className="mr-4 text-base">
                                    Custo:{' '}
                                </label>
                                <input
                                    className="w-full px-4 py-1 text-base border rounded-md border-divider-color"
                                    type="number"
                                    value={costValue}
                                    onChange={(e) =>
                                        dispatch({
                                            type: AddExtraCostDialogActionNames.ON_CHANGE_COST,
                                            payload: {
                                                cost: e.target.value,
                                            },
                                        })
                                    }
                                    step="any"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex-1 py-6">
                    <p className="font-semibold">Impostos</p>
                    <Separator />
                    <div className="grid grid-cols-2 w-fit gap-x-6 gap-y-6">
                        {taxes.map((taxGroup, idx) => (
                            <div
                                key={idx}
                                className="relative flex items-center gap-4 p-2 py-3 border rounded-md border-neutral-300 "
                            >
                                <span className="absolute px-2 bg-white -top-3 left-5">
                                    {taxGroup.groupName}
                                </span>
                                {taxGroup.inputs.map((tax) => (
                                    <div
                                        key={tax.name}
                                        className="flex flex-col"
                                    >
                                        <label
                                            htmlFor={tax.name}
                                            className="text-neutral-400 whitespace-nowrap"
                                        >
                                            {tax.label}
                                        </label>
                                        <input
                                            className="w-full px-4 py-1 text-base border rounded-md border-divider-color"
                                            type="number"
                                            id={tax.name}
                                            name={tax.name}
                                            value={tax.value}
                                            onChange={(e) =>
                                                dispatch({
                                                    type: AddExtraCostDialogActionNames.ON_CHANGE_TAX_VALUE,
                                                    payload: {
                                                        taxId: tax.name,
                                                        value: e.target.value,
                                                    },
                                                })
                                            }
                                            step="any"
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ConfirmDialog>
    )
}

export default AddExtraCostDialog

import { CreateBulkExtraCostReturn } from '@/api/business/extraCost/types'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'

import Table from '@/components/Table'
import { TableCustomData } from '@/components/Table/type'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import Button from '@/components/Button'
import { cn } from '@/lib/utils'

interface ResponseDialogProps {
    response: CreateBulkExtraCostReturn[]
    isOpen: boolean
    onClose: () => void
}

type ResponseDialogTable = CreateBulkExtraCostReturn &
    TableCustomData<CreateBulkExtraCostReturn>
const columnHelper = createColumnHelper<CreateBulkExtraCostReturn>()

const ResponseDialog = ({ response, isOpen, onClose }: ResponseDialogProps) => {
    const columns = useMemo(
        () => [
            columnHelper.accessor('SK_PRODUTO_ESTRUTURA', {
                header: 'Cod Produto',
                cell: ({ getValue }) => getValue(),
            }),
            columnHelper.accessor('SK_PRODUTO_COMPONENTE', {
                header: 'Cod Componente',
                cell: ({ getValue }) => getValue(),
            }),
            columnHelper.accessor('DS_CUSTO', {
                header: 'Descrição',
                cell: ({ getValue }) => getValue(),
            }),
            columnHelper.accessor('BODY', {
                header: 'Erro',
                cell: ({ getValue }) => getValue(),
                size: 300,
            }),
        ],
        []
    )

    const data = useMemo(() => response, [response])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent
                className={cn(
                    'flex flex-col max-w-3xl ',
                    response.length > 0 ? 'h-2/3' : 'h-fit'
                )}
            >
                <DialogHeader>
                    {response.length > 0 ? (
                        <>
                            <DialogTitle>Produtos não inseridos</DialogTitle>
                            <DialogDescription>
                                Os produtos com erros serão mantidos na tabela
                                para alteração
                            </DialogDescription>
                        </>
                    ) : (
                        <>
                            <DialogTitle>Inseridos com sucesso</DialogTitle>
                            <DialogDescription>
                                Todos os produtos foram inseridos com sucesso
                            </DialogDescription>
                        </>
                    )}
                </DialogHeader>
                <div className="flex-1 overflow-hidden">
                    {response.length > 0 ? (
                        <>
                            <Table<ResponseDialogTable>
                                data={data}
                                columns={columns}
                            />
                        </>
                    ) : null}
                </div>
                <DialogFooter>
                    <Button
                        className={cn(
                            'px-6 py-2  rounded-md',
                            response.length > 0
                                ? 'bg-red-500'
                                : 'bg-primary-500'
                        )}
                        onClick={onClose}
                    >
                        Fechar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default ResponseDialog

interface DadosMestreHeaderProps {
    title: string
    selectedRows?: number
    onDelete?: () => void
}

const DadosMestreHeader = ({
    title,
    selectedRows,
    onDelete,
}: DadosMestreHeaderProps) => {
    return (
        <div>
            <h2 className="text-xl font-semibold">{title}</h2>
            {selectedRows && selectedRows > 0 ? (
                <div className="flex items-center gap-2">
                    <span className="block text-sm text-neutral-500">
                        {selectedRows} itens selecionados
                    </span>
                    <span>-</span>
                    <button
                        className="text-sm rounded-md bg-tranparent text-neutral-500 hover:underline"
                        onClick={onDelete}
                    >
                        Excluir
                    </button>
                </div>
            ) : null}
        </div>
    )
}

export default DadosMestreHeader

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { DatePicker } from '@/components/DatePicker'
import useEditProductDialog, {
    EditDialogActionNames,
} from './useEditProductDialog'
import Button from '@/components/Button'
import { Checkbox } from '@/components/ui/checkbox'

const EditProductDialog = () => {
    const {
        initialDate,
        finalDate,
        final,
        initial,
        quantity,
        isOpen,
        item,
        own,
        onChangeProduct,
        onOpenChange,
        dispatch,
    } = useEditProductDialog()

    return (
        <>
            {isOpen && (
                <Dialog open={isOpen} onOpenChange={onOpenChange}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>
                                {item?.parentId ? 'Editar produto' : 'Replicar'}
                            </DialogTitle>
                            <DialogDescription className="text-sm text-neutral-500">
                                {item?.parentId
                                    ? 'Alterar informações do componente.'
                                    : 'As mudanças feitas serão aplicadas para todos os componentes dessa estrutura.'}
                            </DialogDescription>
                        </DialogHeader>
                        <div className="flex items-center gap-2 overflow-hidden">
                            <div className="flex-1 overflow-hidden">
                                <p className="text-sm text-neutral-600">
                                    Produto
                                </p>
                                <div className="w-full px-2 py-1 border rounded-md text-neutral-500">
                                    <p className="overflow-hidden whitespace-nowrap text-ellipsis">
                                        {item?.NK_PRODUTO} - {item?.DS_PRODUTO}
                                    </p>
                                </div>
                            </div>
                            {item?.parentId && (
                                <div className="w-1/4">
                                    <label
                                        htmlFor=""
                                        className="block text-sm text-neutral-600"
                                    >
                                        Qtde
                                    </label>
                                    <input
                                        className="w-full px-2 py-1 border rounded-md"
                                        type="number"
                                        onChange={(e) =>
                                            dispatch({
                                                type: EditDialogActionNames.ON_CHANGE_QUANTITY,
                                                payload: {
                                                    value: e.target.value,
                                                },
                                            })
                                        }
                                        value={quantity}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor=""
                                    className="block text-sm text-neutral-600"
                                >
                                    Revisão inicial
                                </label>
                                <input
                                    className="w-full px-2 py-1 border rounded-md"
                                    type="text"
                                    onChange={(e) =>
                                        dispatch({
                                            type: EditDialogActionNames.ON_CHANGE_INITIAL,
                                            payload: { value: e.target.value },
                                        })
                                    }
                                    value={initial}
                                />
                            </div>
                            <div className="flex-1">
                                <label
                                    htmlFor=""
                                    className="block text-sm text-neutral-600"
                                >
                                    Dt. revisão inicial
                                </label>
                                <DatePicker
                                    className="w-full"
                                    date={initialDate}
                                    setDate={(date) =>
                                        date &&
                                        dispatch({
                                            type: EditDialogActionNames.ON_CHANGE_INITIAL_DATE,
                                            payload: { value: date },
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor=""
                                    className="block text-sm text-neutral-600"
                                >
                                    Revisão Final
                                </label>
                                <input
                                    className="w-full px-2 py-1 border rounded-md"
                                    type="text"
                                    onChange={(e) =>
                                        dispatch({
                                            type: EditDialogActionNames.ON_CHANGE_FINAL,
                                            payload: { value: e.target.value },
                                        })
                                    }
                                    value={final}
                                />
                            </div>

                            <div className="flex-1">
                                <label
                                    htmlFor=""
                                    className="block text-sm text-neutral-600"
                                >
                                    Dt. revisão final
                                </label>
                                <DatePicker
                                    className="w-full"
                                    date={finalDate}
                                    setDate={(date) =>
                                        date &&
                                        dispatch({
                                            type: EditDialogActionNames.ON_CHANGE_FINAL_DATE,
                                            payload: { value: date },
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            {!item?.parentId && (
                                <div className="flex items-center gap-2">
                                    <Checkbox
                                        id="check"
                                        checked={own === 1 ? true : false}
                                        onCheckedChange={(checked) =>
                                            dispatch({
                                                type: EditDialogActionNames.ON_CHANGE_IS_OWN_PRODUCT,
                                                payload: { value: !!checked },
                                            })
                                        }
                                    />
                                    <label htmlFor="check">
                                        Produto próprio
                                    </label>
                                </div>
                            )}
                        </div>
                        <DialogFooter>
                            <Button
                                className="rounded-md w-[100px]"
                                onClick={() => {
                                    if (item) {
                                        onChangeProduct({
                                            final,
                                            finalDate,
                                            initial,
                                            initialDate,
                                            productId: item.id,
                                            parentId: item.parentId,
                                            quantity,
                                            ownProduct: own === 1,
                                        })
                                        onOpenChange(false)
                                    }
                                }}
                            >
                                Aplicar
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            )}
        </>
    )
}

export default EditProductDialog

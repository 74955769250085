import Table from '@/components/Table'
import { TableCustomData } from '@/components/Table/type'
import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import { getStdCostTableDataSelector } from '@/store/stdCostSlice'
import { CostView, StandardCost } from '@/types/Product'
import { StandardCostTableData } from '@/types/StandardCost'
import {
    currencyFormat,
    numberFormat,
    percentageFormat,
} from '@/utils/stringFormatter'
import { Row, createColumnHelper } from '@tanstack/react-table'
import { AlertCircle, ArrowDown, ArrowUp, Minus } from 'lucide-react'
import { ReactNode, useMemo } from 'react'
import StdCostTableHeader from './components/StdCostTableHeader'
import StdCostTableActions from './components/StdCostTableActions'
import { Store } from '@/store/type'

export type StandardCostTable = StandardCostTableData &
    TableCustomData<StandardCost>

const columnHelper = createColumnHelper<StandardCostTableData>()

const columns = [
    columnHelper.accessor(
        ({ PRODUTO_ESTRUTURA, NK_PRODUTO_ESTRUTURA, TIPO_VISAO }) =>
            `${PRODUTO_ESTRUTURA} ${
                TIPO_VISAO !== CostView.CUSTO_EXTRA
                    ? `(${NK_PRODUTO_ESTRUTURA})`
                    : ''
            }`,
        {
            id: 'PRODUTO',
            header: 'Produto',
            cell: ({ row, getValue }) => {
                const {
                    DS_REVISAO_INICIAL,
                    DS_REVISAO_FINAL,
                    PRODUTO_APONTADO_BLOQUEADO,
                    productUsedAsComponentId,
                } = row.original
                const isProduct = row.depth === 0

                return (
                    <div className="flex items-center flex-1 h-full gap-2 overflow-hidden">
                        <div
                            className={cn(
                                'whitespace-nowrap flex-1 text-ellipsis overflow-hidden',
                                productUsedAsComponentId && 'text-yellow-600',
                                isProduct && 'text-primary-500',
                                PRODUTO_APONTADO_BLOQUEADO === 1 &&
                                    'line-through'
                            )}
                        >
                            {getValue()}
                        </div>
                        {DS_REVISAO_INICIAL || DS_REVISAO_FINAL ? (
                            <span
                                className={cn(
                                    'px-2  text-xs text-white w-[50px] text-center rounded-md bg-primary-500',
                                    productUsedAsComponentId && 'bg-yellow-500'
                                )}
                            >
                                {DS_REVISAO_INICIAL && DS_REVISAO_INICIAL}
                                {DS_REVISAO_FINAL && `- ${DS_REVISAO_FINAL}`}
                            </span>
                        ) : null}
                    </div>
                )
            },
            size: 350,
            meta: {
                row: {
                    isGrouped: true,
                },
            },
            filterFn: (row, columnId, filterValue) => {
                const getSanitizedValue = (value: string) =>
                    value.toLowerCase().trim()

                const productFilter = getSanitizedValue(
                    row.original.PRODUTO_FILTRO
                )
                const product = getSanitizedValue(row.getValue(columnId) || '')

                return (
                    productFilter.includes(
                        getSanitizedValue(filterValue as string)
                    ) ||
                    product.includes(getSanitizedValue(filterValue as string))
                )
            },
        }
    ),
    columnHelper.accessor('DD_CLASSIFICACAO', {
        id: 'DD_CLASSIFICACAO',
        header: 'Classificação',
        size: 60,
    }),
    columnHelper.accessor('UM_PRODUTO_ESTRUTURA', {
        id: 'UM_PRODUTO_ESTRUTURA',
        header: 'UM',
        size: 60,
    }),
    columnHelper.accessor(
        ({ CUSTO_SELECIONADO }) => currencyFormat(CUSTO_SELECIONADO, 6),
        {
            id: 'CUSTO_SELECIONADO',
            header: 'Custo Padrão',
            cell: ({ row, getValue }) => {
                return (
                    <p
                        className={cn(
                            'text-ellipsis overflow-hidden',
                            row.original.VISAO_SELECIONADA ===
                                CostView.CUSTO_INFORMADO &&
                                row.original.CUSTO_SELECIONADO === 0
                                ? 'text-red-500'
                                : ''
                        )}
                    >
                        {getValue()}
                    </p>
                )
            },
            size: 150,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ CUSTO_PADRAO_REAL }) =>
            CUSTO_PADRAO_REAL
                ? currencyFormat(CUSTO_PADRAO_REAL, 6)
                : currencyFormat(0, 6),
        {
            id: 'CUSTO_PADRAO_REAL',
            header: 'C.P. Real',
            cell: ({ row, getValue }) => {
                const informedCost = Number(
                    row.original.VALOR_INFORMADO_PRODUTO
                )

                return (
                    <p
                        className={cn(
                            'text-ellipsis overflow-hidden',
                            row.original.VISAO_SELECIONADA ===
                                CostView.CUSTO_INFORMADO && informedCost === 0
                                ? 'text-red-500'
                                : ''
                        )}
                    >
                        {getValue()}
                    </p>
                )
            },
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
            size: 150,
        }
    ),
    columnHelper.accessor(
        ({ VALOR_CUSTO_REAL_COMPONENTE }) =>
            VALOR_CUSTO_REAL_COMPONENTE
                ? currencyFormat(VALOR_CUSTO_REAL_COMPONENTE, 6)
                : currencyFormat(0, 6),
        {
            id: 'VALOR_CUSTO_REAL_COMPONENTE',
            header: 'Custo Real',
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_CUSTO_PADRAO }) =>
            VARIACAO_CUSTO_PADRAO
                ? currencyFormat(VARIACAO_CUSTO_PADRAO, 6)
                : currencyFormat(0, 6),
        {
            id: 'VARIACAO_CUSTO_PADRAO',
            header: 'Var Custo Padrão',
            cell: ({ row, getValue }) => {
                const informedCost = Number(
                    row.original.VALOR_INFORMADO_PRODUTO
                )

                return (
                    <p
                        className={cn(
                            'text-ellipsis overflow-hidden',
                            row.original.VISAO_SELECIONADA ===
                                CostView.CUSTO_INFORMADO && informedCost === 0
                                ? 'text-red-500'
                                : ''
                        )}
                    >
                        {getValue()}
                    </p>
                )
            },
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
            size: 150,
        }
    ),
    columnHelper.accessor(
        ({ CUSTO_PADRAO_TOTAL }) =>
            CUSTO_PADRAO_TOTAL
                ? currencyFormat(CUSTO_PADRAO_TOTAL, 6)
                : currencyFormat(0, 6),
        {
            id: 'CUSTO_PADRAO_TOTAL',
            header: 'C.P. Total',
            cell: ({ row, getValue }) => {
                const informedCost = Number(
                    row.original.VALOR_INFORMADO_PRODUTO
                )
                return (
                    <p
                        className={cn(
                            'text-ellipsis overflow-hidden',
                            row.original.VISAO_SELECIONADA ===
                                CostView.CUSTO_INFORMADO && informedCost === 0
                                ? 'text-red-500'
                                : ''
                        )}
                    >
                        {getValue()}
                    </p>
                )
            },
            size: 160,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ CUSTO_PADRAO_TOTAL_REAL }) =>
            CUSTO_PADRAO_TOTAL_REAL
                ? currencyFormat(CUSTO_PADRAO_TOTAL_REAL, 6)
                : currencyFormat(0, 6),
        {
            id: 'CUSTO_PADRAO_TOTAL_REAL',
            header: 'C.P. Real Total',
            cell: ({ row, getValue }) => {
                const informedCost = Number(
                    row.original.VALOR_INFORMADO_PRODUTO
                )

                return (
                    <p
                        className={cn(
                            'text-ellipsis overflow-hidden',
                            row.original.VISAO_SELECIONADA ===
                                CostView.CUSTO_INFORMADO && informedCost === 0
                                ? 'text-red-500'
                                : ''
                        )}
                    >
                        {getValue()}
                    </p>
                )
            },
            size: 160,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ CUSTO_TOTAL_REAL }) =>
            CUSTO_TOTAL_REAL
                ? currencyFormat(CUSTO_TOTAL_REAL, 6)
                : currencyFormat(0, 6),
        {
            id: 'CUSTO_TOTAL_REAL',
            header: 'Custo Real Total',
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_CUSTO_PADRAO_TOTAL }) =>
            currencyFormat(VARIACAO_CUSTO_PADRAO_TOTAL || 0, 6),
        {
            id: 'VARIACAO_CUSTO_PADRAO_TOTAL',
            header: 'Var. C.P. Real Total',
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL }) =>
            percentageFormat(
                Number(VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL || 0),
                6
            ),
        {
            id: 'VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL',
            header: 'Var. C.P. Real Total (%)',
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_CUSTO_REAL_TOTAL }) =>
            currencyFormat(VARIACAO_CUSTO_REAL_TOTAL || 0, 6),
        {
            id: 'VARIACAO_CUSTO_REAL_TOTAL',
            header: 'Var. C.R. Total',
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL }) =>
            percentageFormat(
                Number(VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL || 0),
                6
            ),
        {
            id: 'VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL',
            header: 'Var. C.R. Total (%)',
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),

    columnHelper.accessor(
        ({ VALOR_INFORMADO_PRODUTO }) =>
            VALOR_INFORMADO_PRODUTO
                ? currencyFormat(Number(VALOR_INFORMADO_PRODUTO), 6)
                : currencyFormat(0, 6),
        {
            id: 'VALOR_INFORMADO_PRODUTO',
            header: 'Custo Informado',
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ ULTIMO_PRECO }) =>
            ULTIMO_PRECO
                ? currencyFormat(ULTIMO_PRECO, 6)
                : currencyFormat(0, 6),
        {
            id: 'ULTIMO_PRECO',
            header: 'Último Preço',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && (
                        <p className="overflow-hidden text-ellipsis">
                            {getValue()}
                        </p>
                    )
                )
            },
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ ULTIMO_PRECO_UNITARIO }) =>
            ULTIMO_PRECO_UNITARIO
                ? currencyFormat(ULTIMO_PRECO_UNITARIO, 6)
                : currencyFormat(0, 6),
        {
            id: 'ULTIMO_PRECO_UNITARIO',
            header: 'Último Preço Uni.',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && (
                        <p className="overflow-hidden text-ellipsis">
                            {getValue()}
                        </p>
                    )
                )
            },
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ CUSTO_PADRAO_UNITARIO }) =>
            CUSTO_PADRAO_UNITARIO
                ? currencyFormat(CUSTO_PADRAO_UNITARIO, 6)
                : currencyFormat(0, 6),
        {
            id: 'CUSTO_PADRAO_UNITARIO',
            header: 'Custo Uni. Std',
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ VALOR_CUSTO_REAL_UNITARIO }) =>
            VALOR_CUSTO_REAL_UNITARIO
                ? currencyFormat(VALOR_CUSTO_REAL_UNITARIO, 6)
                : currencyFormat(0, 6),
        {
            id: 'VALOR_CUSTO_REAL_UNITARIO',
            header: 'Custo Uni. Real',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && (
                        <p className="overflow-hidden text-ellipsis">
                            {getValue()}
                        </p>
                    )
                )
            },
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_CUSTO_UNITARIO }) =>
            currencyFormat(VARIACAO_CUSTO_UNITARIO || 0, 6),
        {
            id: 'VARIACAO_CUSTO_UNITARIO',
            header: 'Var Custo Uni.',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && (
                        <p className="overflow-hidden text-ellipsis">
                            {getValue()}
                        </p>
                    )
                )
            },
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_CUSTO_UNITARIO_PERCENTUAL }) =>
            VARIACAO_CUSTO_UNITARIO_PERCENTUAL
                ? percentageFormat(VARIACAO_CUSTO_UNITARIO_PERCENTUAL, 6)
                : percentageFormat(0, 6),
        {
            id: 'VARIACAO_CUSTO_UNITARIO_PERCENTUAL',
            header: 'Var Custo Uni. (%)',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && (
                        <p className="overflow-hidden text-ellipsis">
                            {getValue()}
                        </p>
                    )
                )
            },
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ IMPACTO_CONSUMO }) => currencyFormat(IMPACTO_CONSUMO || 0, 6),
        {
            id: 'IMPACTO_CONSUMO',
            header: 'Impacto Consumo',
            size: 100,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ IMPACTO_PRECO }) => currencyFormat(IMPACTO_PRECO || 0, 6),
        {
            id: 'IMPACTO_PRECO',
            header: 'Impacto Preço',
            size: 100,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ INDICE_STANDARD }) => numberFormat(INDICE_STANDARD || 0, 6),
        {
            id: 'INDICE_STANDARD',
            header: 'Indice Std',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && (
                        <p className="overflow-hidden text-ellipsis">
                            {getValue()}
                        </p>
                    )
                )
            },
            size: 100,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ INDICE_COMPONENTE_REAL }) =>
            numberFormat(INDICE_COMPONENTE_REAL || 0, 6),
        {
            id: 'INDICE_COMPONENTE_REAL',
            header: 'Indice Real',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && (
                        <p className="overflow-hidden text-ellipsis">
                            {getValue()}
                        </p>
                    )
                )
            },
            size: 120,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_INDICE }) => numberFormat(VARIACAO_INDICE || 0, 6),
        {
            id: 'VARIACAO_INDICE',
            header: 'Var Indice',
            cell: ({ row, getValue }) => {
                const {
                    DD_VARIANCIA_MIN,
                    DD_VARIANCIA_MAX,
                    VARIACAO_PERCENTUAL_INDICE,
                } = row.original

                const min = DD_VARIANCIA_MIN || 0
                const max = DD_VARIANCIA_MAX || 0

                const hasValue = row.depth !== 0 && getValue()

                if (!hasValue) return null

                return (
                    <>
                        <div className="mr-2">
                            {VARIACAO_PERCENTUAL_INDICE < min && (
                                <ArrowDown className="text-red-500" size={14} />
                            )}
                            {VARIACAO_PERCENTUAL_INDICE > max && (
                                <ArrowUp className="text-red-500" size={14} />
                            )}
                            {VARIACAO_PERCENTUAL_INDICE >= min &&
                            VARIACAO_PERCENTUAL_INDICE <= max ? (
                                <Minus className="text-neutral-500" size={14} />
                            ) : null}
                        </div>
                        <div className="flex-1 overflow-hidden">
                            <p className="overflow-hidden text-ellipsis">
                                {getValue()}
                            </p>
                        </div>
                    </>
                )
            },
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_PERCENTUAL_INDICE }) =>
            percentageFormat(Number(VARIACAO_PERCENTUAL_INDICE), 6),
        {
            id: 'VARIACAO_PERCENTUAL_INDICE',
            header: 'Var Indice (%)',
            cell: ({ row, getValue }) => {
                const {
                    DD_VARIANCIA_MIN,
                    DD_VARIANCIA_MAX,
                    VARIACAO_PERCENTUAL_INDICE,
                } = row.original

                const min = DD_VARIANCIA_MIN || 0
                const max = DD_VARIANCIA_MAX || 0

                const hasValue = row.depth !== 0 && getValue()

                if (!hasValue) return null

                return (
                    <>
                        <div className="mr-2">
                            {VARIACAO_PERCENTUAL_INDICE < min && (
                                <ArrowDown className="text-red-500" size={14} />
                            )}
                            {VARIACAO_PERCENTUAL_INDICE > max && (
                                <ArrowUp className="text-red-500" size={14} />
                            )}
                            {VARIACAO_PERCENTUAL_INDICE >= min &&
                            VARIACAO_PERCENTUAL_INDICE <= max ? (
                                <Minus className="text-neutral-500" size={14} />
                            ) : null}
                        </div>
                        <div className="flex-1 overflow-hidden">
                            <p className="overflow-hidden text-ellipsis">
                                {getValue()}
                            </p>
                        </div>
                    </>
                )
            },
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ DD_VARIANCIA_MAX }) => percentageFormat(DD_VARIANCIA_MAX || 0, 6),
        {
            id: 'DD_VARIANCIA_MAX',
            header: 'Var Máx',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && (
                        <p className="overflow-hidden text-ellipsis">
                            {getValue()}
                        </p>
                    )
                )
            },
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ DD_VARIANCIA_MIN }) => percentageFormat(DD_VARIANCIA_MIN || 0, 6),
        {
            id: 'DD_VARIANCIA_MIN',
            header: 'Var Min',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && (
                        <p className="overflow-hidden text-ellipsis">
                            {getValue()}
                        </p>
                    )
                )
            },
            size: 125,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ QTD_COMPONENTE }) =>
            QTD_COMPONENTE
                ? numberFormat(QTD_COMPONENTE, 6)
                : numberFormat(0, 6),
        {
            id: 'qtde',
            header: 'Qtde Std',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && (
                        <p className="overflow-hidden text-ellipsis">
                            {getValue()}
                        </p>
                    )
                )
            },
            size: 100,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ QTD_REAL_UTILIZADA }) => numberFormat(QTD_REAL_UTILIZADA || 0, 6),
        {
            id: 'QTD_REAL_UTILIZADA',
            header: 'Qtde Real',
            cell: ({ row, getValue }) => {
                const hasChanged = !!row.original.PRODUTO_QTD_SELECIONADA
                return (
                    <div
                        className={cn(
                            hasChanged &&
                                'flex items-center gap-2 text-amber-600'
                        )}
                    >
                        {getValue() && (
                            <>
                                {row.depth === 0
                                    ? getValue().split(',')[0]
                                    : getValue()}
                                {hasChanged && <AlertCircle size={14} />}
                            </>
                        )}
                    </div>
                )
            },
            size: 100,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_QUANTIDADE_TOTAL }) =>
            numberFormat(VARIACAO_QUANTIDADE_TOTAL, 6),
        {
            id: 'VARIACAO_QUANTIDADE_TOTAL',
            header: 'Var Qtde',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && (
                        <p className="overflow-hidden text-ellipsis">
                            {getValue()}
                        </p>
                    )
                )
            },
            size: 100,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL }) =>
            percentageFormat(Number(VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL), 6),
        {
            id: 'VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL',
            header: 'Var Qtde (%)',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && (
                        <p className="overflow-hidden text-ellipsis">
                            {getValue()}
                        </p>
                    )
                )
            },
            size: 100,
            meta: {
                cell: {
                    className: 'justify-end',
                },
            },
        }
    ),
    columnHelper.accessor('TIPO_VISAO', {
        id: 'TIPO_VISAO',
        header: 'Visão',
        cell: ({ row, getValue }) => {
            let Badge: ReactNode | null = null
            const className =
                'px-2 py-1 text-xs font-semibold border rounded-md whitespace-nowrap'

            const original = row.original

            let value: CostView | null

            if (original.productUsedAsComponentId) {
                value = CostView.CUSTO_PADRAO
            } else {
                value = original.VISAO_SELECIONADA
                    ? original.VISAO_SELECIONADA
                    : getValue()
            }

            if (original.subRows.length === 0) {
                switch (value) {
                    case CostView.CUSTO_INFORMADO:
                        Badge = (
                            <span
                                className={cn(
                                    className,
                                    'border-green-500 text-green-500'
                                )}
                            >
                                {value}
                            </span>
                        )
                        break
                    case CostView.CUSTO_MEDIO:
                        Badge = (
                            <span
                                className={cn(
                                    className,
                                    'border-orange-500 text-orange-500'
                                )}
                            >
                                {value}
                            </span>
                        )
                        break
                    case CostView.ULTIMO_PRECO:
                        Badge = (
                            <span
                                className={cn(
                                    className,
                                    'border-primary-500 text-primary-500'
                                )}
                            >
                                {value}
                            </span>
                        )
                        break
                    case CostView.CUSTO_EXTRA:
                        Badge = (
                            <span
                                className={cn(
                                    className,
                                    'text-xs font-semibold text-purple-400 border border-purple-400 rounded-md'
                                )}
                            >
                                {value}
                            </span>
                        )
                        break
                    case CostView.CUSTO_PADRAO:
                        Badge = (
                            <span
                                className={cn(
                                    className,
                                    'text-xs font-semibold text-yellow-600 border border-yellow-600 rounded-md'
                                )}
                            >
                                {value}
                            </span>
                        )
                        break
                    default:
                        Badge = null
                        break
                }
            }

            return Badge
        },
        size: 120,
        meta: {
            cell: {
                className: 'flex items-center justify-center',
            },
        },
    }),
    columnHelper.accessor('DD_ORIGEM_DADO', {
        id: 'DD_ORIGEM_DADO',
        header: 'Origem',
        cell: ({ getValue }) => {
            const value = getValue()
            let Badge: ReactNode | null = null

            switch (value) {
                case 'MANUAL':
                    Badge = (
                        <span className="px-2 py-1 text-xs text-green-600 rounded-md bg-green-50">
                            {value}
                        </span>
                    )
                    break
                case 'PROTHEUS':
                    Badge = (
                        <span className="px-2 py-1 text-xs rounded-md text-cyan-600 bg-blue-50">
                            {value}
                        </span>
                    )
                    break
            }

            return Badge
        },
        size: 100,
        meta: {
            cell: {
                className: 'flex items-center justify-center',
            },
        },
    }),
]

const stateSelector = (state: Store) => ({
    stdCostData: state.stdCostSlice.state.stdCostData,
    stdCostSelectedCompany: state.stdCostSlice.state.stdCostSelectedCompany,
    selectedStdCostRow: state.stdCostSlice.state.selectedStdCostRow,
    stdCostTableState: state.stdCostSlice.state.stdCostTableState,
})

const actionsSelector = (state: Store) => ({
    onSelectStdCostRow: state.stdCostSlice.actions.onSelectStdCostRow,
    onTableStateChange: state.stdCostSlice.actions.onTableStateChange,
    onTableExpandedChange: state.stdCostSlice.actions.onTableExpandedChange,
    onTableColumnOrderChange:
        state.stdCostSlice.actions.onTableColumnOrderChange,
})

const StdCostsTable = () => {
    const {
        stdCostData,
        stdCostSelectedCompany: selectedCompany,
        selectedStdCostRow: selectedRow,
        stdCostTableState: tableState,
    } = useBaseStore(stateSelector)

    const {
        onSelectStdCostRow: selectRow,
        onTableStateChange: setTableState,
        onTableExpandedChange: onExpandedStateChange,
        onTableColumnOrderChange: onColumnOrderStateChange,
    } = useBaseStore(actionsSelector)

    const stdCostTable = useBaseStore(getStdCostTableDataSelector)

    const columnsMemo = useMemo(() => columns, [])

    const dataMemo: StandardCostTableData[] = useMemo(
        () => stdCostTable,
        [selectedCompany, stdCostData.components, stdCostData.products]
    )

    const onRowClick = ({ row }: { row: Row<StandardCostTableData> }) => {
        selectRow({
            id: row.id,
            isProduct: row.depth === 0,
        })
    }

    return (
        <Table
            tableId={selectedCompany || ''}
            data={dataMemo}
            columns={columnsMemo}
            selectedRowId={selectedRow?.id}
            onRowClick={onRowClick}
            getRowId={selectedCompany ? (data) => data._id : undefined}
            virtualize
            stripe
            defaultColumn={{
                cell: ({ getValue }) => {
                    return (
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap">
                            {getValue() as string}
                        </p>
                    )
                },
            }}
            expandedState={tableState.expandedState}
            tableState={tableState.state}
            columnOrderState={tableState.columnOrderState}
            onExpandedStateChange={onExpandedStateChange}
            onColumnOrderStateChange={onColumnOrderStateChange}
            onTableStateChange={setTableState}
            tableHeader={<StdCostTableHeader />}
            tableActions={<StdCostTableActions />}
        />
    )
}

export default StdCostsTable

import ConfirmDialog from '../ConfirmDialog'
import { useMutation } from '@tanstack/react-query'
import { changePassword } from '@/api/auth/user'
import { useToast } from '../ui/use-toast'
import { useState } from 'react'
import { useBaseStore } from '@/store'
import {
    appConfigSliceActionsSelector,
    appConfigSliceStateSelector,
} from '@/store/appConfigSlice'

const ChangePasswordDialog = () => {
    const { onChangePasswordDialogClose: onClose } = useBaseStore(
        appConfigSliceActionsSelector
    )
    const { isChangePasswordDialogOpen: isOpen } = useBaseStore(
        appConfigSliceStateSelector
    )

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const { toast } = useToast()

    const { mutate, isLoading } = useMutation({
        mutationFn: changePassword,
        onSuccess: () => {
            toast({
                title: 'Senha alterada com sucesso.',
                description: `Nova senha alterada com sucesso.`,
            })
            onClose()
        },
        onError: () => {
            toast({
                title: 'Erro ao alterar senha.',
                description:
                    'Houve um erro ao alterar a senha. Tente novamente.',
            })
        },
    })

    const onConfirm = () => {
        if (password !== confirmPassword) {
            toast({
                title: 'Senhas não coincidem.',
                description: 'As senhas devem ser a mesmas em ambos os campos.',
                variant: 'destructive',
            })
        } else {
            mutate(password)
        }
    }

    return (
        <ConfirmDialog
            title="Mudar senha"
            isOpen={isOpen}
            isLoading={isLoading}
            onClose={onClose}
            onConfirm={() => onConfirm()}
        >
            <div className="space-y-4">
                <div>
                    <label htmlFor="">Nova senha</label>
                    <input
                        className="w-full p-2 border rounded-md"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                </div>
                <div>
                    <label htmlFor="">Confirmar senha</label>
                    <input
                        className="w-full p-2 border rounded-md"
                        type="password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                    />
                </div>
            </div>
        </ConfirmDialog>
    )
}

export default ChangePasswordDialog

import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { ArrowDownToLine, FileUp, PlusCircle, RefreshCcw } from 'lucide-react'
import { ReactNode } from 'react'

interface DadosMestreHeaderActionsProps {
    CustomButtons?: ReactNode
    onAdd?: () => void
    onDownload?: () => void
    onRefech?: () => void
    onBulk?: () => void
}

const DadosMestreHeaderActions = ({
    CustomButtons,
    onAdd,
    onBulk,
    onDownload,
    onRefech,
}: DadosMestreHeaderActionsProps) => {
    return (
        <>
            {CustomButtons}
            {onAdd && (
                <>
                    <ButtonWithTooltip
                        className="flex items-center justify-center gap-2 px-4 rounded-md bg-primary-50 text-primary-600 hover:text-primary-600 hover:bg-primary-100/80"
                        tooltipContent={<p>Adicionar</p>}
                        onClick={onAdd}
                    >
                        <PlusCircle size={18} />
                        Novo
                    </ButtonWithTooltip>
                    <div className="w-[1px] h-4 bg-neutral-300" />
                </>
            )}
            {onRefech && (
                <>
                    <ButtonWithTooltip
                        className="flex items-center justify-center rounded-md text-neutral-600 w-9 h-9 hover:bg-slate-100 hover:text-slate-500"
                        tooltipContent={<p>Atualizar</p>}
                        onClick={onRefech}
                    >
                        <RefreshCcw size={18} />
                    </ButtonWithTooltip>
                    <div className="w-[1px] h-4 bg-neutral-300" />
                </>
            )}

            {onBulk && (
                <>
                    <ButtonWithTooltip
                        className="flex items-center justify-center rounded-md w-9 h-9 hover:bg-slate-100 hover:text-slate-500 text-neutral-600"
                        tooltipContent={<p>Carga</p>}
                        onClick={onBulk}
                    >
                        <FileUp size={18} />
                    </ButtonWithTooltip>
                    <div className="w-[1px] h-4 bg-neutral-300" />
                </>
            )}
            {onDownload && (
                <>
                    <ButtonWithTooltip
                        className="flex items-center justify-center rounded-md h-9 w-9 hover:bg-slate-100 hover:text-slate-500 text-neutral-600"
                        tooltipContent={<p>Download</p>}
                        onClick={onDownload}
                    >
                        <ArrowDownToLine size={18} />
                    </ButtonWithTooltip>
                    <div className="w-[1px] h-4 bg-neutral-300" />
                </>
            )}
        </>
    )
}

export default DadosMestreHeaderActions

import { Boxes, RotateCcw } from 'lucide-react'
import { CostView, ProductCustomEntity } from '@/types/StandardCost'
import StdCostAnualAvgToggle from '@/pages/StdCosts/components/StdCostsTable/components/StdCostAnualAvgToggle'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { ScrollArea } from '@/components/ui/scroll-area'
import { cn } from '@/lib/utils'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { useEffect, useMemo, useState } from 'react'
import CompositionPieChart from '../../../CompositionPieChart'
// import Chart from './components/Chart'

const stateSelector = (state: Store) => ({
    onChangeStdCostQuantity: state.stdCostSlice.actions.onChangeStdCostQuantity,
    components: state.stdCostSlice.state.stdCostData.components.entities,
})

interface ProductSectionProps {
    product: ProductCustomEntity
}

const ProductSection = ({ product }: ProductSectionProps) => {
    const { onChangeStdCostQuantity, components } = useBaseStore(stateSelector)

    const [quantity, setQuantity] = useState(0)

    const children = useMemo(
        () =>
            Object.values(components).filter(
                (component) => component._parentId === product._id
            ),
        [components, product]
    )

    const base = children.filter(
        (child) => child.TIPO_VISAO !== CostView.CUSTO_EXTRA
    )

    const expenses = children.filter(
        (child) => child.TIPO_VISAO === CostView.CUSTO_EXTRA
    )

    const baseValue = base.reduce(
        (acc, comp) => (acc += comp.CUSTO_SELECIONADO || 0),
        0
    )
    const expensesValue = expenses.reduce(
        (acc, comp) => (acc += comp.CUSTO_SELECIONADO || 0),
        0
    )

    const baseValuePercentage = baseValue / (baseValue + expensesValue) || 0
    const expensesValuePercentage =
        expensesValue / (baseValue + expensesValue) || 0

    useEffect(() => {
        setQuantity(
            product?.PRODUTO_QTD_SELECIONADA ||
                product?.QTD_PRODUCAO_APONTADA ||
                0
        )
    }, [product])

    return (
        <ScrollArea className="flex-1 p-4">
            <div className="mb-4">
                <div className="flex items-center justify-between w-full">
                    <h1 className="font-semibold">Detalhamento</h1>
                    <StdCostAnualAvgToggle />
                </div>
                <div className="flex items-end gap-4">
                    <div className="space-y-1.5 flex-1 ">
                        <p className="text-sm text-neutral-500">Produto</p>
                        <div className="flex items-center gap-2 p-2 text-primary-600 bg-slate-50">
                            <Boxes size={20} strokeWidth={1.5} />
                            <p>{product.PRODUTO_ESTRUTURA}</p>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center justify-between">
                            <p className="text-sm text-neutral-500">Qtde</p>
                            {product?.PRODUTO_QTD_SELECIONADA ? (
                                <button
                                    onClick={() =>
                                        onChangeStdCostQuantity({
                                            isProduct: true,
                                            id: product._id,
                                            quantity: null,
                                        })
                                    }
                                >
                                    <RotateCcw size={14} />
                                </button>
                            ) : null}
                        </div>

                        <input
                            className="w-full py-1 text-center bg-transparent border rounded-md border-primary-500"
                            type="number"
                            value={quantity}
                            onChange={(e) =>
                                setQuantity(Number(e.target.value))
                            }
                            onBlur={() => {
                                if (!product) return
                                onChangeStdCostQuantity({
                                    isProduct: true,
                                    id: product._id,
                                    quantity: Number(quantity),
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="mb-4 space-y-2">
                <div>
                    <h2 className="font-semibold">Custos</h2>
                </div>
                <div className="grid w-full grid-cols-[repeat(3,minmax(50px,1fr))] gap-4">
                    <div className="p-2 rounded-md bg-slate-50">
                        <p className="text-sm text-neutral-500">Custo padrão</p>
                        <div>
                            <p className="font-semibold text-md">
                                {currencyFormat(product.CUSTO_SELECIONADO, 6)}
                            </p>
                        </div>
                    </div>
                    <div className="p-2 rounded-md bg-slate-50">
                        <p className="text-sm text-neutral-500">
                            Custo padrão real
                        </p>
                        <div>
                            <p className="font-semibold text-md">
                                {currencyFormat(product.CUSTO_PADRAO_REAL, 6)}
                            </p>
                        </div>
                    </div>
                    <div className="p-2 rounded-md bg-slate-50">
                        <p className="text-sm text-neutral-500">Custo real</p>
                        <div>
                            <p className="font-semibold text-md">
                                {currencyFormat(
                                    product.VALOR_CUSTO_REAL_COMPONENTE,
                                    6
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="p-2 rounded-md bg-slate-50">
                        <p className="text-sm text-neutral-500">
                            Custo padrão total
                        </p>
                        <div>
                            <p className="font-semibold text-md">
                                {currencyFormat(product.CUSTO_PADRAO_TOTAL, 6)}
                            </p>
                        </div>
                    </div>
                    <div className="p-2 rounded-md bg-slate-50">
                        <p className="text-sm text-neutral-500">
                            Custo padrão real total
                        </p>
                        <div>
                            <p className="font-semibold text-md">
                                {currencyFormat(
                                    product.CUSTO_PADRAO_TOTAL_REAL,
                                    6
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="p-2 rounded-md bg-slate-50">
                        <p className="text-sm text-neutral-500">
                            Custo real total
                        </p>
                        <div>
                            <p className="font-semibold text-md">
                                {currencyFormat(product.CUSTO_TOTAL_REAL, 6)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-4 space-y-2">
                <div>
                    <h2 className="font-semibold">Composição do custo</h2>
                </div>
                <div className="grid h-[200px] w-full grid-cols-3 gap-4">
                    <div className="flex items-center justify-center col-span-2 row-span-2 bg-slate-50">
                        <CompositionPieChart
                            product={product}
                            baseValue={baseValue || 0}
                            expensesValue={expensesValue || 0}
                        />
                    </div>
                    <div className="flex flex-col justify-center gap-2 p-2 text-center rounded-md bg-slate-50">
                        <p className="text-sm text-neutral-500">
                            Matéria prima
                        </p>
                        <div>
                            <p className="text-lg font-semibold">
                                {currencyFormat(baseValue, 6)}
                            </p>
                            <p className="text-sm text-neutral-500">
                                {percentageFormat(baseValuePercentage * 100)}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center gap-2 p-2 text-center rounded-md bg-slate-50">
                        <p className="text-sm text-neutral-500">Rateios</p>
                        <div>
                            <p className="text-lg font-semibold">
                                {currencyFormat(expensesValue, 6)}
                            </p>
                            <p className="text-sm text-neutral-500">
                                {percentageFormat(
                                    expensesValuePercentage * 100
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-4 space-y-2">
                <div>
                    <h2 className="font-semibold">Variações</h2>
                </div>
                <div className="gap-4 space-y-2">
                    <div className="flex items-center justify-between p-2 rounded-md bg-slate-50">
                        <p className="text-sm text-neutral-500">
                            Variação custo padrão real total
                        </p>
                        <div className="flex items-center gap-8">
                            <div>
                                <p className="font-semibold text-md text-end">
                                    {currencyFormat(
                                        product.VARIACAO_CUSTO_PADRAO_TOTAL ||
                                            0,
                                        6
                                    )}
                                </p>
                            </div>
                            <div>
                                <p
                                    className={cn(
                                        'font-semibold text-md text-end',
                                        product.VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL <
                                            0
                                            ? 'text-emerald-500'
                                            : 'text-red-500/90'
                                    )}
                                >
                                    {percentageFormat(
                                        product.VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL ||
                                            0,
                                        6
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between p-2 rounded-md bg-slate-50">
                        <p className="text-sm text-neutral-500">
                            Variação custo real total
                        </p>
                        <div className="flex items-center gap-8">
                            <div>
                                <p className="font-semibold text-md text-end">
                                    {currencyFormat(
                                        product.VARIACAO_CUSTO_REAL_TOTAL || 0,
                                        6
                                    )}
                                </p>
                            </div>
                            <div>
                                <p
                                    className={cn(
                                        'font-semibold text-md text-end',
                                        product.VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL <
                                            0
                                            ? 'text-emerald-500'
                                            : 'text-red-500/90'
                                    )}
                                >
                                    {percentageFormat(
                                        product.VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL ||
                                            0,
                                        6
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollArea>
    )
}

export default ProductSection

import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { currencyFormat } from '@/utils/stringFormatter'
import { Box, DollarSign } from 'lucide-react'
import { CostViewSelectOptions } from '../../../../../DefaultDetails/components/DetailsTab/components/Component'
import { ChangeEvent, useState } from 'react'
import { CostView } from '@/types/Product'

interface ComponentProps {
    id: string
}

const componentSelector = (id: string) => (state: Store) =>
    state.stdCostSlice.state.stdCostData.components.entities[id]

const stateSelector = (state: Store) => ({
    selectedDate: state.stdCostSlice.state.selectedDate,
})
const actionsSelector = (state: Store) => ({
    onChangeCostView: state.stdCostSlice.actions.onChangeCostView,
})

const Component = ({ id }: ComponentProps) => {
    const component = useBaseStore(componentSelector(id))
    const { selectedDate } = useBaseStore(stateSelector)
    const { onChangeCostView } = useBaseStore(actionsSelector)

    const [selectedCostView, setSelectedCostView] = useState<CostView | ''>(
        component?.VISAO_SELECIONADA || ''
    )

    const isProduct = component.productUsedAsComponentId
    const isExtraComponent =
        component.ESTRUTURA === 'EXTRA-COMPONENTE' ||
        component.ESTRUTURA === 'EXTRA-PRODUTO'

    const isComponent = !isProduct && !isExtraComponent

    const onChangeSelectedCostView = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value as CostView
        setSelectedCostView(value)

        if (!component) return

        onChangeCostView({
            productId: component._id,
            costView: value,
            dateId: selectedDate.id,
        })
    }

    if (!component) return null

    return (
        <div
            key={component._id}
            className="w-full p-2 overflow-hidden border-b rounded-md"
        >
            <div
                className={cn(
                    'flex items-center gap-2 text-neutral-500',
                    isProduct && 'text-yellow-600',
                    isExtraComponent && 'text-purple-400'
                )}
            >
                {isProduct ? (
                    <Box size={14} />
                ) : isExtraComponent ? (
                    <DollarSign size={16} />
                ) : (
                    <Box size={14} />
                )}
                <p>{component.PRODUTO_COMPONENTE}</p>
            </div>
            <div className="flex items-center justify-between gap-4">
                <div className="pl-6">
                    <p className="text-sm text-neutral-400">Custo</p>
                    <p
                        className={cn(
                            'text-md',
                            component.VISAO_SELECIONADA ===
                                CostView.CUSTO_INFORMADO &&
                                component.CUSTO_SELECIONADO === 0
                                ? 'text-red-500'
                                : ''
                        )}
                    >
                        {currencyFormat(component.CUSTO_SELECIONADO, 6)}
                    </p>
                </div>
                {isComponent && (
                    <div>
                        {/* <label
                            className="block text-sm text-neutral-400"
                            htmlFor=""
                        >
                            Visão:
                        </label> */}
                        <select
                            className={cn(
                                'rounded-md border text-sm p-1',
                                component.VISAO_SELECIONADA ===
                                    CostView.CUSTO_INFORMADO
                                    ? 'border-emerald-500 text-emerald-500'
                                    : 'border-primary-600 text-primary-600'
                            )}
                            onChange={onChangeSelectedCostView}
                            value={selectedCostView || ''}
                        >
                            {CostViewSelectOptions.map((option) => {
                                return (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Component

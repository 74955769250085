import { Separator } from '@/components/ui/separator'
import { ReactNode } from 'react'

interface TabSectionProps {
    title: ReactNode
    actionButton?: ReactNode
    children: ReactNode
}

const TabSection = ({ title, actionButton, children }: TabSectionProps) => {
    return (
        <div>
            <div className="flex items-center justify-between">
                {typeof title == 'string' ? (
                    <p className="font-semibold ">{title}</p>
                ) : (
                    title
                )}
                {actionButton}
            </div>
            <Separator />
            <div className="p-2 rounded-lg bg-slate-50">{children}</div>
        </div>
    )
}

export default TabSection

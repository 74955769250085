import ConfirmDialog from '@/components/ConfirmDialog'
import DatePickerMonthWithRange from '@/components/DatePickerMonthWithRange'
import Select from '@/components/Select'
import { useToast } from '@/components/ui/use-toast'
import { useCurrencyQuery } from '@/queries/useCurrencyQuery'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
    addMonths,
    eachDayOfInterval,
    endOfMonth,
    isAfter,
    isBefore,
    isSameMonth,
    isSameYear,
    isSunday,
} from 'date-fns'
import useAddDialog, {
    AddDialogActionNames,
    AddDialogState,
} from './useAddDialog'
import { createCurrencyValue } from '@/api/business/currency'
import { getIdFromDate } from '@/utils/date'
import { currencyValuesKeys } from '@/queries/useCurrencyValuesQuery'
import { isAxiosError } from '@/api/business'

interface AddDialogProps {
    isOpen: boolean
    onClose: () => void
}

const AddDialog = ({ isOpen, onClose }: AddDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { data } = useCurrencyQuery()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: createCurrencyValue,
        onSuccess: () => {
            queryClient.invalidateQueries(currencyValuesKeys.lists())
            onClose()
            onReset()
            toast({
                title: 'Valor de moeda adicionado com sucesso',
            })
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao criar valor de moeda',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const initialState: AddDialogState = {
        currency: data ? data[0].SK_MOEDA.toString() : '',
        date: {
            from: new Date(),
            to: addMonths(new Date(), 1),
        },
        value: '0',
    }

    const { currency, date, value, dispatch } = useAddDialog(initialState)
    const currentDate = new Date()

    const onReset = () => dispatch({ type: AddDialogActionNames.RESET })

    const onSubmit = () => {
        const dates = eachDayOfInterval({
            start: date.from,
            end: endOfMonth(date.to),
        })
            .filter((day) => isAfter(day, new Date()) && !isSunday(day))
            .map((day) => Number(getIdFromDate(day).join('')))

        mutate({
            SK_MOEDA: Number(currency),
            SK_TEMPO: dates,
            VL_MOEDA: Number(value),
        })
    }

    return (
        <ConfirmDialog
            title="Criar valor de moeda"
            isOpen={isOpen}
            onClose={() => {
                onClose()
                onReset()
            }}
            onConfirm={onSubmit}
            isLoading={mutateLoading}
        >
            <div className="py-4 space-y-4">
                <div>
                    <label htmlFor="">Selecionar moeda</label>
                    {data && (
                        <Select
                            value={currency}
                            onChange={(value) =>
                                dispatch({
                                    type: AddDialogActionNames.ON_CHANGE_CURRENCY,
                                    payload: {
                                        currency: value,
                                    },
                                })
                            }
                            options={data.map((currency) => ({
                                label: currency.DS_MOEDA,
                                value: currency.SK_MOEDA.toString(),
                            }))}
                        />
                    )}
                </div>
                <div>
                    <label htmlFor="">Valor</label>
                    <input
                        className="w-full p-2 border rounded-md"
                        type="number"
                        value={value}
                        onChange={(e) =>
                            dispatch({
                                type: AddDialogActionNames.ON_CHANGE_VALUE,
                                payload: { value: e.target.value },
                            })
                        }
                    />
                </div>
                <div>
                    <label htmlFor="">Selecionar data</label>
                    <DatePickerMonthWithRange
                        className="w-full"
                        startMonthRange={{
                            start: isSameYear(date.from, currentDate)
                                ? currentDate.getMonth()
                                : 0,
                        }}
                        endMonthRange={{
                            start:
                                (isBefore(date.from, date.to) &&
                                    isSameYear(date.from, date.to)) ||
                                isSameMonth(date.from, date.to)
                                    ? Math.min(date.from.getMonth(), 11)
                                    : 0,
                            end: 11,
                        }}
                        startYearRange={{
                            start: currentDate.getFullYear(),
                        }}
                        endYearRange={{
                            start: currentDate.getFullYear(),
                        }}
                        date={date}
                        setDate={(value) =>
                            dispatch({
                                type: AddDialogActionNames.ON_CHANGE_DATE,
                                payload: { ...value },
                            })
                        }
                    />
                </div>
            </div>
        </ConfirmDialog>
    )
}

export default AddDialog

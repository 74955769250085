import ConfirmDialog from '@/components/ConfirmDialog'
import { useToast } from '@/components/ui/use-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Button from '@/components/Button'
import { useExtraCostSpreadsheet } from '../../useExtraCostSpreadsheet'
import { createColumnHelper } from '@tanstack/react-table'
import { TableCustomData } from '@/components/Table/type'
import useBulkDialog, { BulkDialogActionNames } from './useBulkDialog'
import { ChangeEvent, useMemo } from 'react'
import { CreateBulkExtraCostProps } from '@/api/business/extraCost/types'
import { TableWithInput } from '@/components/TableWithInput'
import Select from '@/components/Select'
import { createBulkExtraCost } from '@/api/business/extraCost'
import { extraCostKeys } from '@/queries/useExtraCostQuery'
import {
    ArrowUpToLine,
    Download,
    Info,
    ListPlus,
    Paintbrush,
    X,
} from 'lucide-react'
import useDisclosure from '@/hooks/useDisclosure'
import { isAxiosError } from '@/api/business'
import { cn } from '@/lib/utils'
import ResponseDialog from './components/ResponseDialog'
import { ExtraCostValueCategory } from '@/types/Costs'
interface BulkDialogProps {
    isOpen: boolean
    onClose: () => void
}

export type BulkData = CreateBulkExtraCostProps & {
    STATUS?: number
    MENSAGEM?: string
    DS_PRODUTO_APONTADO?: string
    DS_PRODUTO?: string
    id: string
}

type BulkTable = BulkData & TableCustomData<BulkData>

const columnHelper = createColumnHelper<BulkData>()

const BulkDialog = ({ isOpen, onClose }: BulkDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const { tableData, feedbackResponse, productsLoading, dispatch } =
        useBulkDialog()
    const { onDownloadBulkModel, onReadData } = useExtraCostSpreadsheet()

    const {
        isOpen: isFeedbackDialogOpen,
        onClose: onFeedbackDialogClose,
        onOpen: onFeedbackDialogOpen,
    } = useDisclosure()

    // const [bulkTableInstance, setBulkTableInstance] =
    //     useState<TableType<BulkTable>>()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: createBulkExtraCost,
        onSuccess: (data) => {
            queryClient.invalidateQueries(extraCostKeys.lists())
            dispatch({
                type: BulkDialogActionNames.SET_DATA_STATUS,
                payload: data,
            })
            dispatch({
                type: BulkDialogActionNames.RESET,
            })
            onFeedbackDialogOpen()
            toast({
                title: 'Carga realizada com sucesso',
            })
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao realizar carga',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
    const dataMemo = useMemo(() => tableData, [tableData])
    const columnsMemo = useMemo(
        () => [
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    return (
                        <div className="flex items-center justify-center h-full p-0 text-red-500">
                            <button
                                onClick={() =>
                                    dispatch({
                                        type: BulkDialogActionNames.REMOVE_ROW,
                                        payload: { id: row.original.id },
                                    })
                                }
                            >
                                <X size={16} />
                            </button>
                        </div>
                    )
                },
                size: 35,
                enableResizing: false,
            }),

            columnHelper.accessor('SK_EMPRESA', {
                header: 'Cod Empresa',
                size: 90,
            }),
            columnHelper.accessor('SK_PRODUTO_ESTRUTURA', {
                header: 'Cod Produto apontado',
                size: 90,
            }),
            columnHelper.accessor('DS_PRODUTO_APONTADO', {
                header: 'Produto apontado',
                cell: ({ getValue }) => {
                    return (
                        <div
                            className={cn(
                                'p-1 text-xs',
                                !getValue() && 'text-red-500'
                            )}
                        >
                            {getValue() ? getValue() : 'Produto não encontrado'}
                        </div>
                    )
                },
                size: 200,
            }),
            columnHelper.accessor('SK_PRODUTO_COMPONENTE', {
                header: 'Cod Componente',
                size: 90,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                header: 'Produto',
                cell: ({ getValue }) => {
                    return (
                        <div
                            className={cn(
                                'p-1 text-xs',
                                !getValue() && 'text-red-500'
                            )}
                        >
                            {getValue() ? getValue() : 'Produto não encontrado'}
                        </div>
                    )
                },
                size: 200,
            }),
            columnHelper.accessor('SK_TEMPO', {
                header: 'Data',
                size: 90,
            }),
            columnHelper.accessor('DS_CUSTO', {
                header: 'Descrição',
                size: 120,
            }),
            columnHelper.accessor('VL_CUSTO_ORCADO', {
                header: 'Custo',
                size: 80,
            }),
            columnHelper.accessor('CATEGORIA_VALOR', {
                header: 'Categoria',
                cell: ({ getValue, column, row }) => {
                    const options: {
                        label: string
                        value: ExtraCostValueCategory
                    }[] = [
                        {
                            label: 'FIXO',
                            value: 'FIXO',
                        },
                        { label: 'VARIAVEL', value: 'VARIAVEL' },
                    ]

                    return (
                        <Select
                            className="h-[26px] px-1 py-0 text-xs rounded-sm"
                            onChange={(value) =>
                                dispatch({
                                    type: BulkDialogActionNames.UPDATE_TABLE_DATA,
                                    payload: {
                                        columnId: column.id,
                                        rowIdx: row.index,
                                        value,
                                    },
                                })
                            }
                            options={options}
                            value={getValue() ? getValue().toString() : 'FIXO'}
                        />
                    )
                },
                size: 80,
            }),
            columnHelper.accessor('VL_PIS', {
                header: 'PIS',
                size: 80,
            }),
            columnHelper.accessor('VL_ALIQUOTA_PIS', {
                header: 'PIS (%)',
                size: 80,
            }),
            columnHelper.accessor('VL_COFINS', {
                header: 'COFINS',
                size: 80,
            }),
            columnHelper.accessor('VL_ALIQUOTA_COFINS', {
                header: 'COFINS (%)',
                size: 80,
            }),
            columnHelper.accessor('VL_FCP', {
                header: 'FCP',
                size: 80,
            }),
            columnHelper.accessor('VL_ALIQUOTA_FCP', {
                header: 'FCP (%)',
                size: 80,
            }),
            columnHelper.accessor('VL_ICMS', {
                header: 'ICMS',
                size: 80,
            }),
            columnHelper.accessor('VL_ALIQUOTA_ICMS', {
                header: 'ICMS (%)',
                size: 80,
            }),
            columnHelper.accessor('VL_IPI', {
                header: 'IPI',
                size: 80,
            }),
            columnHelper.accessor('VL_ALIQUOTA_IPI', {
                header: 'IPI (%)',
                size: 80,
            }),
            columnHelper.accessor('VL_ST', {
                header: 'ST',
                size: 80,
            }),
            columnHelper.accessor('VL_ALIQUOTA_ST', {
                header: 'ST (%)',
                size: 80,
            }),
        ],
        []
    )

    const onSubmit = () => {
        mutate(
            tableData.map((data) => ({
                ...data,
                SK_PRODUTO_COMPONENTE: data.SK_PRODUTO_COMPONENTE
                    ? `${data.SK_EMPRESA}${data.SK_PRODUTO_COMPONENTE}`
                    : null,
                SK_PRODUTO_ESTRUTURA: data.SK_PRODUTO_ESTRUTURA
                    ? `${data.SK_EMPRESA}${data.SK_PRODUTO_ESTRUTURA}`
                    : null,
            }))
        )
    }

    const onChangeFileInput = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files
        if (file && file[0]) {
            const data = await onReadData(file[0])
            dispatch({
                type: BulkDialogActionNames.SET_TABLE_DATA,
                payload: data,
            })
        }
    }

    return (
        <>
            <ConfirmDialog
                className="flex flex-col h-full max-w-full overflow-hidden sm:rounded-none"
                title="Carga custo extra"
                isOpen={isOpen}
                onClose={onClose}
                onConfirm={onSubmit}
                isLoading={mutateLoading}
            >
                <div className="flex flex-col flex-1 w-full space-y-4 overflow-auto">
                    <div className="flex items-center justify-end gap-2">
                        {dataMemo.length > 0 && (
                            <Button
                                className="flex items-center justify-center w-10 h-10 p-0 bg-indigo-500 rounded-md"
                                onClick={() =>
                                    dispatch({
                                        type: BulkDialogActionNames.ADD_ROW,
                                    })
                                }
                            >
                                <ListPlus size={16} />
                            </Button>
                        )}
                        <Button
                            className="flex items-center justify-center w-10 h-10 p-0 rounded-md bg-amber-500"
                            onClick={() =>
                                dispatch({
                                    type: BulkDialogActionNames.RESET,
                                })
                            }
                        >
                            <Paintbrush size={16} />
                        </Button>
                        <label
                            htmlFor="file"
                            className={cn(
                                'flex items-center justify-center w-10 h-10 p-0 text-white rounded-md',
                                productsLoading
                                    ? 'bg-neutral-300'
                                    : 'bg-primary-600 cursor-pointer'
                            )}
                            onClick={(e) => {
                                if (productsLoading) {
                                    return e.preventDefault()
                                }
                            }}
                        >
                            <ArrowUpToLine size={16} />
                        </label>
                        <input
                            className="hidden"
                            id="file"
                            type="file"
                            onChange={onChangeFileInput}
                        />
                        <Button
                            className="flex items-center justify-center w-10 h-10 p-0 rounded-md bg-emerald-500"
                            onClick={() => onDownloadBulkModel(tableData)}
                        >
                            <Download size={16} />
                        </Button>
                    </div>
                    <div className="flex-1 w-full overflow-hidden">
                        {dataMemo.length > 0 && columnsMemo ? (
                            <TableWithInput<BulkTable>
                                data={dataMemo}
                                columns={columnsMemo}
                                updateData={(rowIdx, columnId, value) => {
                                    dispatch({
                                        type: BulkDialogActionNames.UPDATE_TABLE_DATA,
                                        payload: {
                                            rowIdx: Number(rowIdx),
                                            columnId,
                                            value,
                                        },
                                    })
                                }}
                                // getTableInstance={(table) =>
                                //     setBulkTableInstance(table)
                                // }
                            />
                        ) : (
                            <div className="flex justify-center w-full">
                                <p>Nenhum dado importado</p>
                            </div>
                        )}
                    </div>
                    {dataMemo.some((item) => !item.SK_EMPRESA) && (
                        <div className="flex items-center self-end gap-2 text-orange-500">
                            <Info size={14} />
                            <p>Há itens com dados faltantes</p>
                        </div>
                    )}
                </div>
            </ConfirmDialog>
            {isFeedbackDialogOpen && (
                <ResponseDialog
                    isOpen={isFeedbackDialogOpen}
                    onClose={onFeedbackDialogClose}
                    response={feedbackResponse}
                />
            )}
        </>
    )
}

export default BulkDialog

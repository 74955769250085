import { useCallback, useReducer } from 'react'

export interface EditDialogState {
    value: string
}
export enum EditDialogActionNames {
    ON_CHANGE_VALUE = 'ON_CHANGE_VALUE',
    RESET = 'RESET',
}

type EditDialogActions =
    | {
          type: EditDialogActionNames.ON_CHANGE_VALUE
          payload: {
              value: string
          }
      }
    | {
          type: EditDialogActionNames.RESET
      }

const useEditDialog = (initialState: EditDialogState) => {
    const reducer = useCallback(
        (
            state: typeof initialState,
            action: EditDialogActions
        ): typeof initialState => {
            switch (action.type) {
                case EditDialogActionNames.ON_CHANGE_VALUE:
                    return {
                        ...state,
                        value: action.payload.value,
                    }
                case EditDialogActionNames.RESET:
                    return initialState
                default:
                    return state
            }
        },
        [initialState]
    )

    const [state, dispatch] = useReducer(reducer, initialState)

    return {
        ...state,
        dispatch,
    }
}
export default useEditDialog

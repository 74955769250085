import TopMessage from '@/components/TopMessage'
import { useCostViewQuery } from '@/queries/useCostViewQuery'
import { useCurrencyQuery } from '@/queries/useCurrencyQuery'
import { useStdCostsQuery } from '@/queries/useStdCostsQuery'
import { useBaseStore } from '@/store'
import { currencySliceActionsSelector } from '@/store/currencySlice'
import { Store } from '@/store/type'

import { useEffect } from 'react'

const stateSelector = (state: Store) => ({
    selectedDate: state.stdCostSlice.state.selectedDate,
    isAnualAvg: state.stdCostSlice.state.isAnualAvg,
    isCalculating: state.stdCostSlice.state.isCalculating,
})

const actionsSelector = (state: Store) => ({
    setStdCostData: state.stdCostSlice.actions.setStdCostData,
    // onCalculateTotalCost: state.stdCostSlice.actions.onCalculateTotalCost,
    setCalculating: state.stdCostSlice.actions.setCalculating,
})

const StdCostStatus = () => {
    const { selectedDate, isAnualAvg, isCalculating } =
        useBaseStore(stateSelector)

    const { setStdCostData, setCalculating } = useBaseStore(actionsSelector)

    const { setCurrencies } = useBaseStore(currencySliceActionsSelector)

    const { data: currencyData } = useCurrencyQuery()

    const {
        data: costViewData,
        isError: costViewIsError,
        isSuccess: isCostViewSuccess,
        isLoading: costVIewIsLoading,
    } = useCostViewQuery({
        SK_TEMPO: selectedDate.id,
    })

    const { data, isLoading, isFetching, isInitialLoading, isError } =
        useStdCostsQuery({
            date: selectedDate.id,
            enabled: isCostViewSuccess,
            isAnualAvg,
        })

    useEffect(() => {
        if (data && costViewData) {
            setStdCostData(data, costViewData)
            setCalculating(true)
            // onCalculateTotalCost()
        }
    }, [data, costViewData])

    useEffect(() => {
        if (currencyData) setCurrencies(currencyData)
    }, [currencyData])

    return (
        <>
            {isCalculating && (
                <TopMessage text="Realizando calculos..." type="loading" />
            )}
            {!isLoading && isFetching && (
                <TopMessage text="Atualizando dados..." type="loading" />
            )}
            {isInitialLoading || costVIewIsLoading ? (
                <TopMessage text="Carregando dados..." type="success" />
            ) : null}
            {isError && (
                <TopMessage text="Erro ao carregar dados" type="error" />
            )}
            {costViewIsError && (
                <TopMessage
                    text="Houve um erro ao carregar os custos padrões"
                    type="error"
                />
            )}
        </>
    )
}

export default StdCostStatus

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useStdCostUpdateDate } from '@/queries/useStdCostsQuery'
import { useBaseStore } from '@/store'
import { appConfigSliceActionsSelector } from '@/store/appConfigSlice'
import { authSliceActionsSelector } from '@/store/authSlice'
import { useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { Key, LogOut } from 'lucide-react'
import { ReactNode } from 'react'

interface ProfileMenuProps {
    trigger: ReactNode
    className?: string
}

const ProfileMenu = ({ trigger, className }: ProfileMenuProps) => {
    const { onChangePasswordDialogOpen } = useBaseStore(
        appConfigSliceActionsSelector
    )
    const { logout } = useBaseStore(authSliceActionsSelector)
    const { data } = useStdCostUpdateDate()
    const queryClient = useQueryClient()

    return (
        <DropdownMenu>
            <DropdownMenuTrigger className={className}>
                {trigger}
            </DropdownMenuTrigger>
            <DropdownMenuContent className="border-neutral-200 shadow-xl w-[220px]">
                {data && data[0].CREATED_AT ? (
                    <DropdownMenuItem
                        className="flex-col text-xs focus:cursor-default focus:bg-white focus:text-neutral-500"
                        onClick={(e) => e.preventDefault()}
                    >
                        <p>Dados atualizados em:</p>
                        <p>
                            {format(
                                new Date(data[0].CREATED_AT),
                                'dd/MM/yyyy, HH:mm:ss'
                            )}
                        </p>
                    </DropdownMenuItem>
                ) : null}
                <DropdownMenuSeparator />
                <DropdownMenuItem
                    className="gap-2"
                    onClick={onChangePasswordDialogOpen}
                >
                    <Key size={14} />
                    Alterar senha
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                    className="gap-2"
                    onClick={() => logout(queryClient)}
                >
                    <LogOut size={14} />
                    Sair
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

export default ProfileMenu

import { SidenavItems } from '../../consts'
import { NavLink } from 'react-router-dom'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { useState } from 'react'
import { useBaseStore } from '@/store'
import {
    appConfigSliceActionsSelector,
    appConfigSliceStateSelector,
} from '@/store/appConfigSlice'
// import {
//     useAppConfigSliceActions,
//     useAppConfigSliceState,
// } from '@/store/appConfigSlice'

type SidenavItemProps = SidenavItems

const SidenavItem = ({ Icon, label, to, subItems }: SidenavItemProps) => {
    const { setSidenavOpen } = useBaseStore(appConfigSliceActionsSelector)
    const { isSidenavOpen, isMobile } = useBaseStore(
        appConfigSliceStateSelector
    )

    const [isChildrenOpen, setIsChildrenOpen] = useState(false)

    return (
        <>
            {!subItems && to ? (
                <NavLink
                    className={({ isActive }) =>
                        ` ${isActive ? 'text-primary-500 active' : ''} ${
                            isSidenavOpen ? 'p-2 gap-4' : `justify-center py-2`
                        } flex items-center hover:bg-neutral-700 rounded-md`
                    }
                    to={to}
                    onClick={() => {
                        if (isMobile) setSidenavOpen(false)
                    }}
                >
                    {Icon ? (
                        <div className="p-1">
                            <Icon size={18} />
                        </div>
                    ) : (
                        <span className="sidenavItem__no-icon"></span>
                    )}
                    {isSidenavOpen && (
                        <span className="flex-1 whitespace-nowrap text-ellipsis">
                            {label}
                        </span>
                    )}
                </NavLink>
            ) : (
                <>
                    <div
                        className={` ${
                            isSidenavOpen ? 'p-2 gap-4' : `justify-center py-2`
                        } flex items-center hover:bg-neutral-700 rounded-md cursor-pointer`}
                        onClick={() => {
                            if (!isSidenavOpen) {
                                setIsChildrenOpen(true)
                                setSidenavOpen(true)
                            } else {
                                setIsChildrenOpen((prev) => !prev)
                            }
                        }}
                    >
                        {Icon ? (
                            <div className="p-1">
                                <Icon size={18} />
                            </div>
                        ) : (
                            <span className="sidenavItem__no-icon"></span>
                        )}
                        {isSidenavOpen && (
                            <span className="flex-1 whitespace-nowrap text-ellipsis">
                                {label}
                            </span>
                        )}
                        {isSidenavOpen && subItems ? (
                            <>
                                {isChildrenOpen ? (
                                    <ChevronDown size={16} />
                                ) : (
                                    <ChevronUp size={16} />
                                )}
                            </>
                        ) : null}
                    </div>
                    <div className={`${isChildrenOpen ? 'block' : 'hidden'}`}>
                        {subItems && isSidenavOpen
                            ? subItems.map((subItem) => (
                                  <div
                                      key={subItem.id}
                                      className="relative hover:bg-neutral-700 pl-11"
                                  >
                                      <SidenavItem
                                          id={subItem.id}
                                          label={subItem.label}
                                          to={subItem.to}
                                          subItems={subItem.subItems}
                                      />
                                  </div>
                              ))
                            : null}
                    </div>
                </>
            )}
        </>
    )
}

export default SidenavItem

import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from '@/components/ui/hover-card'
import { getInitials } from '@/utils/stringFormatter'

interface HoverUserProps {
    username: string
    user: string
    email: string
}

export const HoverUser = ({ username, user, email }: HoverUserProps) => {
    return (
        <HoverCard>
            <HoverCardTrigger className="font-bold cursor-pointer">
                {user}
            </HoverCardTrigger>
            <HoverCardContent className="border-none text-primary-800 bg-slate-50 w-fit">
                <div className="flex items-center justify-between space-x-4">
                    <Avatar>
                        <AvatarFallback className="text-white bg-primary-500">
                            {username && getInitials(username)}
                        </AvatarFallback>
                    </Avatar>
                    <div>
                        <h4 className="text-sm font-semibold">{username}</h4>
                        <p className="text-sm">{email}</p>
                    </div>
                </div>
            </HoverCardContent>
        </HoverCard>
    )
}

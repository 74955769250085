import { useState } from 'react'

const useDisclosure = () => {
    const [isOpen, setIsOpen] = useState(false)

    const onClose = () => setIsOpen(false)
    const onOpen = () => setIsOpen(true)
    const onToggle = () => setIsOpen((prev) => !prev)

    return {
        isOpen,
        onOpen,
        onClose,
        onToggle,
    }
}

export default useDisclosure

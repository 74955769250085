import { SIDENAV_ITEMS } from './consts'
import SidenavItem from './components/SidenavItem'
import Logo from '@/assets/icons/icon192.png'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { getHeaders } from '@/api/business/utils'
import { useBaseStore } from '@/store'
import {
    appConfigSliceActionsSelector,
    appConfigSliceStateSelector,
} from '@/store/appConfigSlice'

const Sidenav = () => {
    const { setSidenavOpen } = useBaseStore(appConfigSliceActionsSelector)
    const { isSidenavOpen } = useBaseStore(appConfigSliceStateSelector)

    return (
        <>
            {isSidenavOpen && (
                <div
                    className="absolute top-0 bottom-0 left-0 right-0 z-10 sm:hidden"
                    onClick={() => setSidenavOpen(false)}
                ></div>
            )}
            <motion.div
                className={`fixed left-0 flex flex-col h-full z-40 bg-neutral-900 text-neutral-50 ease-in transition`}
                initial={{
                    width: isSidenavOpen ? 224 : 56,
                }}
                animate={{
                    width: isSidenavOpen ? 224 : 56,
                }}
            >
                <Link
                    className={`${
                        isSidenavOpen ? 'px-4' : ''
                    } flex items-center w-full gap-2 h-header`}
                    to="/"
                >
                    <img src={Logo} className={`w-10 h-10 mx-auto`} />
                    {isSidenavOpen && (
                        <span className="flex-1 whitespace-nowrap text-ellipsis">
                            Portal Custo
                        </span>
                    )}
                </Link>
                <div
                    className={`${
                        isSidenavOpen ? 'p-4' : 'p-2'
                    } flex-1 w-full overflow-y-auto overflow-x-hidden`}
                >
                    {SIDENAV_ITEMS.map((item) => (
                        <SidenavItem
                            key={item.id}
                            id={item.id}
                            Icon={item.Icon}
                            label={item.label}
                            to={item.to}
                            subItems={item.subItems}
                        />
                    ))}
                </div>
                {isSidenavOpen && (
                    <div className="pb-4 overflow-hidden">
                        <p className="text-xs text-center text-white">
                            V{getHeaders()['x-versao']}
                        </p>
                    </div>
                )}
            </motion.div>
        </>
    )
}
export default Sidenav

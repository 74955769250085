import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import Button from '../Button'
import { ConfirmDialogCommonProps } from '@/types/Dialog'
import { cn } from '@/lib/utils'

interface ConfirmDialogProps extends ConfirmDialogCommonProps {}

const ConfirmDialog = ({
    title,
    description,
    children,
    isOpen,
    isLoading,
    buttonDisabled,
    dialogType = 'confirm',
    buttonLabel = 'Confirmar',
    onConfirm,
    onClose,
    className,
}: ConfirmDialogProps) => {
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent
                className={cn(className, 'overflow-auto max-h-full')}
            >
                <DialogHeader>
                    <DialogTitle className="font-normal">{title}</DialogTitle>
                    <DialogDescription>{description}</DialogDescription>
                </DialogHeader>
                {children}
                <DialogFooter className="flex items-center">
                    <Button
                        onClick={onClose}
                        className="w-[100px]"
                        variant="ghost"
                    >
                        Cancelar
                    </Button>
                    <Button
                        className={cn(
                            'px-4 py-2 rounded-md w-[100px]',
                            dialogType === 'destructive' &&
                                'bg-red-500 text-white hover:bg-red-600',
                            dialogType === 'confirm' &&
                                'bg-primary-500 text-white',
                            buttonDisabled &&
                                'bg-neutral-100 hover:bg-neutral-100'
                        )}
                        onClick={() => onConfirm()}
                        isLoading={isLoading}
                        disabled={buttonDisabled}
                    >
                        {buttonLabel ?? 'Confirmar'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmDialog

import { CurrencyType } from './Currency'

export interface Product {
    SK_PRODUTO: string
    SK_EMPRESA: string
    ABREVIATURA_EMPRESA: string
    NK_PRODUTO: string
    DS_PRODUTO: string
    DD_UM: string
    DD_GRUPO: string
    DD_BLOQUEADO: 0 | 1
    DD_CLASSIFICACAO: string
    QTD_VOLUME: number
    DD_TAMANHO_EMBALAGEM: number
    DD_ORIGEM_DADO: 'MANUAL' | 'PROTHEUS'
}

export interface FinalProduct {
    SK_EMPRESA: string
    SK_PRODUTO_ESTRUTURA: string
    NK_PRODUTO: string
    DS_PRODUTO: string
}

export enum CostView {
    CUSTO_INFORMADO = 'Custo informado',
    CUSTO_MEDIO = 'Custo médio',
    ULTIMO_PRECO = 'Último preço',
    CUSTO_EXTRA = 'Custo extra',
    CUSTO_PADRAO = 'Custo padrão',
}

export interface CostViewAPI {
    SK_CONFIGURACAO_EXIBICAO: number
    DS_CONFIGURACAO_EXIBICAO: string
    SK_TEMPO: number
}

export interface ProductAvgCost {
    SK_EMPRESA: string
    ABREVIATURA_EMPRESA: string
    DS_REVISAO_INICIAL: string
    DS_REVISAO_FINAL: string
    SK_PRODUTO_ESTRUTURA: string
    PRODUTO_ESTRUTURA?: string
    NK_PRODUTO_ESTRUTURA?: string
    UM_PRODUTO_ESTRUTURA?: string
    CUSTO: number
    MANIPULA_COMPONENTE: 0 | 1
    COMPONENTES: ComponentAvgCost[]
}

export interface ComponentAvgCost {
    SK_PRODUTO_COMPONENTE: string
    PRODUTO_COMPONENTE?: string
    UM_PRODUTO_COMPONENTE?: string
    NK_PRODUTO_COMPONENTE?: string
    QTD_COMPONENTE: string
    PRECO_MEDIO: number
    ESTRUTURA: 'PADRAO' | 'EXTRA-COMPONENTE' | 'EXTRA-PRODUTO'
    COMPONENTES: ComponentAvgCost[]
}
export interface StandardCost {
    id: string
    DD_ORIGEM_DADO: 'MANUAL' | 'PROTHEUS'
    SK_ESTRUTURA_DE_PRODUTO: number
    SK_EMPRESA: string
    ABREVIATURA_EMPRESA: string
    SK_PRODUTO_ESTRUTURA: string
    NK_ESTRUTURA_DE_PRODUTO: string
    SK_COMPONENTE_ESTRUTURA: string | null
    NK_PRODUTO_ESTRUTURA: string
    DD_CLASSIFICACAO: string
    DS_REVISAO_INICIAL: string
    DS_REVISAO_FINAL: string
    PRODUTO_ESTRUTURA: string
    UM_PRODUTO_ESTRUTURA: string
    COMPONENTES: Component[]
    QTD_SELECIONADA?: number | null
    CUSTO: number
    CUSTO_SELECIONADO: number
    CUSTO_PADRAO_REAL: number
    CUSTO_PADRAO_TOTAL: number
    CUSTO_PADRAO_TOTAL_REAL: number
    ULTIMO_PRECO: number
    PRECO_MEDIO?: number
    VALOR_INFORMADO_PRODUTO?: string
    QTD_PRODUCAO_APONTADA: string
    VISAO_SELECIONADA: CostView | ''
    TIPO_VISAO: CostView | ''
    ESTRUTURA: 'PADRAO' | 'EXTRA-COMPONENTE' | 'EXTRA-PRODUTO' | ''
    MANIPULA_PRODUTO_ESTRUTURA: 0 | 1
    PRODUTO_APONTADO_BLOQUEADO: 0 | 1
}

export interface Component {
    id: string
    DD_ORIGEM_DADO: 'MANUAL' | 'PROTHEUS'
    SK_PRODUTO_COMPONENTE: string
    NK_PRODUTO_COMPONENTE: string
    PRODUTO_COMPONENTE: string
    UM_PRODUTO_COMPONENTE: string
    QTD_COMPONENTE: string
    QTD_PRODUCAO_COMPONENTE: string
    QTD_SELECIONADA: number | null
    CUSTO_SELECIONADO?: number
    CUSTO_PADRAO_REAL: number
    CUSTO_PADRAO_TOTAL: number
    CUSTO_PADRAO_TOTAL_REAL: number
    ULTIMO_PRECO: number
    PRECO_MEDIO?: number
    VALOR_INFORMADO_COMPONENTE?: string
    VL_UNITARIO: number
    TIPO_VISAO: CostView
    PRODUTO_COMPONENTE_BLOQUEADO: 0 | 1
    MANIPULA_COMPONENTE: 0 | 1
    VISAO_SELECIONADA: CostView | ''
    VALOR_MOEDA_PADRAO_COMPONENTE: number
    MOEDA_UTILIZADA_COMPONENTE?: CurrencyType | null
    PRODUTO_UTILIZA_COMPONENTE: boolean
    SK_NOVO_CUSTO_COMPONENTE?: number
    VL_PIS_COMPONENTE?: number
    VL_PIS_ALIQUOTA_COMPONENTE?: number
    VL_COFINS_COMPONENTE?: number
    VL_COFINS_ALIQUOTA_COMPONENTE?: number
    VL_ICMS_COMPONENTE?: number
    VL_ICMS_ALIQUOTA_COMPONENTE?: number
    VL_IPI_COMPONENTE?: number
    VL_IPI_ALIQUOTA_COMPONENTE?: number
    VL_ST_COMPONENTE?: number
    VL_ST_ALIQUOTA_COMPONENTE?: number
    VL_FCP_COMPONENTE?: number
    VL_FCP_ALIQUOTA_COMPONENTE?: number
    ESTRUTURA: 'PADRAO' | 'EXTRA-COMPONENTE' | 'EXTRA-PRODUTO'
    COMPONENTES: Component[]
}

export interface StandardCostTableData extends StandardCost {
    SK_PRODUTO_COMPONENTE: string | null
    PRODUTO_COMPONENTE?: string
    QTD_COMPONENTE: string | null
    QTD_SELECIONADA?: number | null
    PRODUTO_FILTRO: string
    PRODUTO_UTILIZA_COMPONENTE: boolean
    QTD_PRODUCAO_COMPONENTE: string
    CUSTO_PADRAO_REAL: number
    CUSTO_PADRAO_TOTAL: number
    CUSTO_PADRAO_TOTAL_REAL: number
    VL_UNITARIO: number
    VL_PIS_COMPONENTE?: number
    VL_PIS_ALIQUOTA_COMPONENTE?: number
    VL_COFINS_COMPONENTE?: number
    VL_COFINS_ALIQUOTA_COMPONENTE?: number
    VL_ICMS_COMPONENTE?: number
    VL_ICMS_ALIQUOTA_COMPONENTE?: number
    VL_IPI_COMPONENTE?: number
    VL_IPI_ALIQUOTA_COMPONENTE?: number
    VL_ST_COMPONENTE?: number
    VL_ST_ALIQUOTA_COMPONENTE?: number
    VL_FCP_COMPONENTE?: number
    VL_FCP_ALIQUOTA_COMPONENTE?: number
}

export interface ProductionVolume {
    SK_EMPRESA: string
    ABREVIATURA_EMPRESA: string
    SK_VOLUME_PRODUCAO: number
    SK_PRODUTO: string
    NK_PRODUTO: string
    DS_PRODUTO: string
    QTD_VOLUME: string
    SK_TEMPO: number
    INFORMADO: string
    CREATED_AT: string
}

export interface ProductStructureFlat {
    SK_ESTRUTURA_DE_PRODUTO: number
    SK_EMPRESA: number
    ABREVIATURA_EMPRESA: string
    SK_PRODUTO: string
    NK_PRODUTO: string
    SK_PRODUTO_ESTRUTURA: string
    PRODUTO_ESTRUTURA: string
    UM_PRODUTO_ESTRUTURA: string
    NK_COMPONENTE: string
    SK_PRODUTO_COMPONENTE: string
    PRODUTO_COMPONENTE: string
    UM_PRODUTO_COMPONENTE: string
    SK_TEMPO_INICIAL: number
    SK_TEMPO_FINAL: number
    DD_PRODUTO_PROPRIO: 0 | 1
    QTD_COMPONENTE: string
    DS_REVISAO_INICIAL: string
    DS_REVISAO_FINAL: string
    DD_ORIGEM_DADO: 'MANUAL' | 'PROTHEUS'
}
export interface ProductStructure {
    SK_ESTRUTURA_DE_PRODUTO: number
    SK_EMPRESA: number
    ABREVIATURA_EMPRESA: string
    SK_PRODUTO: string
    NK_PRODUTO: string
    SK_PRODUTO_ESTRUTURA: string
    PRODUTO_ESTRUTURA: string
    UM_PRODUTO_ESTRUTURA: string
    DD_ORIGEM_DADO: 'MANUAL' | 'PROTHEUS'
    DD_PRODUTO_PROPRIO: 0 | 1
    COMPONENTES: ProductStructureComponent[]
}

export interface ProductStructureComponent {
    SK_ESTRUTURA_DE_PRODUTO: number
    NK_COMPONENTE: string
    SK_PRODUTO_COMPONENTE: string
    PRODUTO_COMPONENTE: string
    UM_PRODUTO_COMPONENTE: string
    SK_TEMPO_INICIAL: number
    SK_TEMPO_FINAL: number
    QTD_COMPONENTE: string
    DS_REVISAO_INICIAL: string
    DS_REVISAO_FINAL: string
    DD_ORIGEM_DADO: 'MANUAL' | 'PROTHEUS'
}

export interface UnityOfMeasurement {
    DS_SIGLA: string
    DS_UM: string
}

export interface Classification {
    DS_SIGLA: string
    DS_CLASSIFICACAO: string
}

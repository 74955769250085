import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import ProtectedRoutes from '@/pages/Layouts/ProtectedLayout'
import Login from '@/pages/Login'
// import Home from '@/pages/Home'
import NotFound from '@/pages/NotFound'
import PublicLayout from '@/pages/Layouts/PublicLayout'
import AppLayout from '@/pages/Layouts/AppLayout'
import StdCosts from './pages/StdCosts'
import CurrencyDadosMestre from './pages/DadosMestre/CurrencyDadosMestre'
import ProductionVolumeDadosMestre from './pages/DadosMestre/ProductionVolumeDadosMestre'
import InformedCostDadosMestre from './pages/DadosMestre/InformedCostDadosMestre'
import ProductStructureDadosMestre from './pages/DadosMestre/ProductStructureDadosMestre'
import ResetPassword from './pages/ResetPassword'
import ProductDadosMestre from './pages/DadosMestre/ProductDadosMestre'
import ExtraCostDadosMestre from './pages/DadosMestre/ExtraCostDadosMestre'

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<AppLayout />}>
                    <Route element={<PublicLayout />}>
                        <Route path="*" element={<NotFound />} />
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/recuperar-senha"
                            element={<ResetPassword />}
                        />
                    </Route>
                    <Route path="/" element={<ProtectedRoutes />}>
                        <Route index element={<Navigate to="/custos" />} />
                        <Route path="custos" element={<StdCosts />} />
                        <Route path="dados-mestre">
                            <Route
                                index
                                element={<Navigate to="custo-informado" />}
                            />
                            <Route
                                path="moeda"
                                element={<CurrencyDadosMestre />}
                            />
                            <Route
                                path="volume-producao"
                                element={<ProductionVolumeDadosMestre />}
                            />
                            <Route
                                path="custo-extra"
                                element={<ExtraCostDadosMestre />}
                            />
                            <Route
                                path="custo-informado"
                                element={<InformedCostDadosMestre />}
                            />
                            <Route
                                path="estrutura-produto"
                                element={<ProductStructureDadosMestre />}
                            />
                            <Route
                                path="produto"
                                element={<ProductDadosMestre />}
                            />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router

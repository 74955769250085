import ConfirmDialog from '@/components/ConfirmDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createInformedCost } from '@/api/business/stdCost'
import { memo, useState } from 'react'
import { stdCostKeys } from '@/queries/useStdCostsQuery'
import { useToast } from '@/components/ui/use-toast'
import { Checkbox } from '@/components/ui/checkbox'
import {
    addDays,
    eachDayOfInterval,
    format,
    isFirstDayOfMonth,
    isSunday,
} from 'date-fns'
import { DatePicker } from '@/components/DatePicker'
import { ptBR } from 'date-fns/locale'
import { Separator } from '@/components/ui/separator'
import Select from '@/components/Select'
import DatePickerMonthWithRange from '@/components/DatePickerMonthWithRange'
import { getIdFromDate } from '@/utils/date'
import { Taxes } from '@/api/business/stdCost/type'
import { historyKeys } from '@/queries/useStdCostHistoryQuery'
import { MultiSelect } from '@/components/MultiSelect'
import useAddDialog, { AddDialogActionNames } from './useAddCost'
import useDisclosure from '@/hooks/useDisclosure'
import { isAxiosError } from '@/api/business'
import { ComponentCustomEntity } from '@/types/StandardCost'
import { useBaseStore } from '@/store'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'

interface AddCostDialogProps {
    isOpen: boolean
    onClose: () => void
}

const AddCostDialog = memo(({ isOpen, onClose }: AddCostDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const { isAnualAvg } = useBaseStore(stdCostSliceStateSelector)

    const {
        component,
        costValue,
        currencies,
        currencyId,
        date,
        dateRange,
        componetParent,
        isCurrencyLoading,
        selectedDate,
        productIds,
        products,
        taxes,
        product,
        dispatch,
    } = useAddDialog()

    const queryKey = stdCostKeys.list(selectedDate.id, isAnualAvg)

    const { mutate: mutateInformedCost, isLoading: isInformedCostLoading } =
        useMutation({
            mutationFn: createInformedCost,
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey)

                queryClient.invalidateQueries(
                    historyKeys.detail({
                        productId: product?.SK_PRODUTO_ESTRUTURA || '',
                        componentId: component?.SK_PRODUTO_COMPONENTE || '',
                        date: selectedDate.id,
                    })
                )
                toast({
                    title: 'Custo adicionado com sucesso',
                })
                onClose()
            },
            onError: (err) => {
                if (isAxiosError(err)) {
                    if (
                        err.response?.status === 401 ||
                        err.response?.status === 402
                    ) {
                        toast({
                            title: 'Sem permissão de acesso',
                            description:
                                'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                            variant: 'destructive',
                        })
                    } else {
                        toast({
                            title: 'Erro ao adicionar custo',
                            description:
                                'Não foi possível adicionar o custo, tente novamente',
                            variant: 'destructive',
                        })
                    }
                }
            },
        })
    const [canReplicate, setCanReplicate] = useState(false)
    const {
        isOpen: isMultiSelectOpen,
        onClose: onMultiSelectClose,
        onOpen: onMultiSelectOpen,
    } = useDisclosure()

    return (
        <ConfirmDialog
            className={'max-w-6xl'}
            title="Adicionar custo informado"
            isOpen={isOpen}
            isLoading={isInformedCostLoading}
            onConfirm={() => {
                if (!component || !currencyId) return

                const dates = eachDayOfInterval({
                    start: dateRange.from,
                    end: dateRange.to,
                })
                    .filter((day) => isFirstDayOfMonth(day))
                    .map((day) =>
                        isSunday(day)
                            ? Number(getIdFromDate(addDays(day, 1)).join(''))
                            : Number(getIdFromDate(day).join(''))
                    )

                const taxObj = taxes.reduce((acc, tax) => {
                    return {
                        ...acc,
                        ...tax.inputs.reduce((acc, input) => {
                            return {
                                ...acc,
                                [input.name]: input.value,
                            }
                        }, {} as Taxes),
                    }
                }, {} as Taxes)

                if (!component || !product) return

                mutateInformedCost({
                    SK_EMPRESA: product.SK_EMPRESA,
                    VL_CUSTO_ORCADO: Number(costValue),
                    SK_PRODUTO_COMPONENTE:
                        component.SK_PRODUTO_COMPONENTE || null,
                    SK_PRODUTO_ESTRUTURA: productIds,
                    SK_TEMPO:
                        canReplicate && dateRange
                            ? dates
                            : isSunday(date)
                            ? [Number(`${format(date, 'yyyyMM')}02`)]
                            : [Number(`${format(date, 'yyyyMM')}01`)],
                    SK_MOEDA: Number(currencyId),
                    ...taxObj,
                })
            }}
            onClose={onClose}
        >
            <div className="flex">
                <div className="flex-1 p-4 space-y-4">
                    <div className="py-2 ">
                        <p className="font-semibold">Produto</p>
                        <Separator />
                        {component?.PRODUTO_COMPONENTE}
                    </div>
                    <div>
                        <p className="font-semibold">Selecionar estruturas</p>
                        <Separator />
                        <div className="space-y-4">
                            <div className="flex items-center gap-2">
                                <Checkbox
                                    id="checkbox"
                                    checked={canReplicate}
                                    onClick={() =>
                                        setCanReplicate((prev) => !prev)
                                    }
                                />
                                <label
                                    htmlFor="checkbox"
                                    className="text-base cursor-pointer"
                                >
                                    Replicar estrutura?
                                </label>
                            </div>
                            {canReplicate ? (
                                <MultiSelect
                                    isOpen={isMultiSelectOpen}
                                    onChange={(value) =>
                                        dispatch({
                                            type: AddDialogActionNames.ON_SELECT_PRODUCT_STRUCTURE,
                                            payload: { productId: value },
                                        })
                                    }
                                    onClose={onMultiSelectClose}
                                    onOpen={onMultiSelectOpen}
                                    onSelectAll={(values) =>
                                        dispatch({
                                            type: AddDialogActionNames.ON_SELECT_ALL_PRODUCTS,
                                            payload: {
                                                productIds: values,
                                            },
                                        })
                                    }
                                    options={products.filtered.map(
                                        (product) => ({
                                            label: product.PRODUTO_ESTRUTURA,
                                            value: product.SK_PRODUTO_ESTRUTURA.toLowerCase(),
                                        })
                                    )}
                                    selectedValues={productIds}
                                    customFilter={(id, search) => {
                                        const value = products.filtered.find(
                                            (product) =>
                                                product.SK_PRODUTO_ESTRUTURA ==
                                                id
                                        )

                                        if (!value) return 0

                                        const matchName =
                                            value.PRODUTO_ESTRUTURA.toLowerCase()
                                                .trim()
                                                .includes(
                                                    search
                                                        .toLocaleLowerCase()
                                                        .trim()
                                                )

                                        const matchId =
                                            value.SK_PRODUTO_ESTRUTURA.includes(
                                                search
                                            )

                                        return matchName || matchId ? 1 : 0
                                    }}
                                />
                            ) : (
                                <input
                                    className="w-full p-2 text-gray-300 border rounded-md border-neutral-300"
                                    type="text"
                                    value={
                                        componetParent?.PRODUTO_ESTRUTURA ||
                                        (
                                            componetParent as unknown as ComponentCustomEntity
                                        )?.PRODUTO_COMPONENTE
                                    }
                                    disabled
                                />
                            )}
                            <div className="flex flex-col ga4">
                                {canReplicate ? (
                                    <DatePickerMonthWithRange
                                        date={dateRange}
                                        setDate={(date) =>
                                            date &&
                                            dispatch({
                                                type: AddDialogActionNames.ON_CHANGE_DATE_RANGE,
                                                payload: date,
                                            })
                                        }
                                    />
                                ) : (
                                    <DatePicker
                                        className="w-full"
                                        date={date}
                                        setDate={(date) =>
                                            date &&
                                            dispatch({
                                                type: AddDialogActionNames.ON_CHANGE_DATE,
                                                payload: { date },
                                            })
                                        }
                                        disabled={isSunday}
                                        locale={ptBR}
                                    />
                                )}
                                {canReplicate &&
                                currencies?.find(
                                    (currency) =>
                                        currency.SK_MOEDA.toString() ===
                                        currencyId
                                )?.DS_MOEDA !== 'REAL' ? (
                                    <p className="p-1 mt-2 text-red-500 rounded-md bg-red-50">
                                        Obs.: Para replicação futura em moeda
                                        diferente de real, é necessário cadastro
                                        do valor da moeda. Caso não haja,
                                        contatar o financeiro.
                                    </p>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="py-6">
                        <p className="font-semibold">Custo informado</p>
                        <Separator />
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-4">
                                <div className="flex flex-col flex-1">
                                    <label
                                        htmlFor=""
                                        className="text-neutral-400"
                                    >
                                        Custo:{' '}
                                    </label>
                                    <input
                                        className="w-full px-4 py-1.5 text-base border rounded-md border-divider-color"
                                        type="number"
                                        value={costValue}
                                        onChange={(e) =>
                                            dispatch({
                                                type: AddDialogActionNames.ON_CHANGE_COST,
                                                payload: {
                                                    cost: e.target.value,
                                                },
                                            })
                                        }
                                        step="any"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor=""
                                        className="text-neutral-400"
                                    >
                                        Moeda:{' '}
                                    </label>
                                    <Select
                                        onChange={(value) =>
                                            dispatch({
                                                type: AddDialogActionNames.ON_CHANGE_CURRENCY,
                                                payload: {
                                                    currencyId: value,
                                                },
                                            })
                                        }
                                        options={
                                            currencies
                                                ? currencies.map(
                                                      (currency) => ({
                                                          label: currency.DS_MOEDA as string,
                                                          value: currency.SK_MOEDA.toString(),
                                                      })
                                                  )
                                                : []
                                        }
                                        value={currencyId || ''}
                                        placeholder={
                                            isCurrencyLoading
                                                ? 'Carregando moedas... '
                                                : 'Selecionar moeda'
                                        }
                                        className="w-fit"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 py-6">
                    <p className="font-semibold">Impostos</p>
                    <Separator />
                    <div className="grid grid-cols-2 w-fit gap-x-6 gap-y-6">
                        {taxes.map((taxGroup, idx) => (
                            <div
                                key={idx}
                                className="relative flex items-center gap-4 p-2 py-3 border rounded-md border-neutral-300 "
                            >
                                <span className="absolute px-2 bg-white -top-3 left-5">
                                    {taxGroup.groupName}
                                </span>
                                {taxGroup.inputs.map((tax) => (
                                    <div
                                        key={tax.name}
                                        className="flex flex-col"
                                    >
                                        <label
                                            htmlFor={tax.name}
                                            className="text-neutral-400 whitespace-nowrap"
                                        >
                                            {tax.label}
                                        </label>
                                        <input
                                            className="w-full px-4 py-1 text-base border rounded-md border-divider-color"
                                            type="number"
                                            id={tax.name}
                                            name={tax.name}
                                            value={tax.value}
                                            onChange={(e) =>
                                                dispatch({
                                                    type: AddDialogActionNames.ON_CHANGE_TAX_VALUE,
                                                    payload: {
                                                        taxId: tax.name,
                                                        value: e.target.value,
                                                    },
                                                })
                                            }
                                            step="any"
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ConfirmDialog>
    )
})

export default AddCostDialog

import { FormEvent, useEffect, useRef } from 'react'
import { useState } from 'react'

import icon from '../../assets/icons/icon.png'
import { Eye, EyeOff } from 'lucide-react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { isAxiosError } from '@/api/business'
import { authenticateUser } from '@/api/auth/user'
import { AxiosError } from 'axios'
import { useToast } from '@/components/ui/use-toast'
import Button from '@/components/Button'
import { getHeaders } from '@/api/business/utils'
import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import { authSliceActionsSelector } from '@/store/authSlice'

const Login = () => {
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [inputUser, setInputUser] = useState('')
    const [password, setPassword] = useState('')

    const { toast } = useToast()
    const navigate = useNavigate()
    const { state } = useLocation()

    const { login } = useBaseStore(authSliceActionsSelector)

    const {
        mutate: onLogin,
        isError,
        isLoading,
    } = useMutation(
        ({ username, password }: { username: string; password: string }) =>
            authenticateUser(username, password),
        {
            onSuccess: (data) => {
                login({
                    token: data.token,
                    user: data.nomeUsuario,
                    userId: null,
                })
                navigate(state?.path || '/custos')
            },
            onError: (err: AxiosError) => {
                if (isAxiosError<{ message: string }>(err)) {
                    // setErrorMessage(err.response.data.message)
                    toast({
                        title: 'Erro ao fazer login',
                        description: 'Usuário ou senha incorretos',
                        variant: 'destructive',
                        // status: 'error',
                    })
                }
            },
        }
    )
    const userRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        userRef.current?.focus()
    }, [isError])

    const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (inputUser && password) {
            onLogin({ username: inputUser, password })
        }
    }

    return (
        <>
            <form
                className="absolute top-1/2 left-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col gap-4 p-4 sm:w-[480px] sm:p-0"
                onSubmit={onFormSubmit}
            >
                <div className="flex items-center gap-2 mx-auto mb-4 md:gap-4">
                    <img
                        src={icon}
                        className="w-8 h-8 md:w-11 md:h-11"
                        alt="Portal icon"
                    />
                    <h1 className="text-sm font-semibold md:text-lg ">
                        PORTAL CUSTOS
                    </h1>
                </div>

                <div className="w-full">
                    <label
                        className="block text-sm text-left peer-focus:text-white"
                        htmlFor="user"
                    >
                        Usuário
                    </label>
                    <input
                        id="user"
                        className={`${
                            isError ? 'border-red-500' : ''
                        } w-full border text-sm rounded-md px-4 py-2  outline-none focus:border-primary-500`}
                        type="text"
                        ref={userRef}
                        onChange={(e) => setInputUser(e.target.value)}
                    />
                </div>
                <div className="w-full">
                    <label
                        className="block text-sm text-left "
                        htmlFor="password"
                    >
                        Senha
                    </label>
                    <div
                        className={`${
                            isError ? 'border-red-500' : ''
                        } flex items-center border rounded-md text-sm px-4 py-2 focus-within:border-primary-500`}
                    >
                        <input
                            id="password"
                            className="flex-1 h-full pr-2 bg-transparent outline-none "
                            type={passwordVisible ? 'text' : 'password'}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {passwordVisible ? (
                            <EyeOff
                                className="cursor-pointer hover:text-neutral-400"
                                onClick={() =>
                                    setPasswordVisible((prev) => !prev)
                                }
                                size={24}
                            />
                        ) : (
                            <Eye
                                className="cursor-pointer hover:text-neutral-400"
                                onClick={() =>
                                    setPasswordVisible((prev) => !prev)
                                }
                                size={24}
                            />
                        )}
                    </div>
                </div>
                <Link
                    className="text-sm text-right text-neutral-400"
                    to="/recuperar-senha"
                >
                    Recuperar senha
                </Link>

                <Button
                    className={cn(
                        'w-full',
                        !isLoading && 'hover:bg-primary-600'
                    )}
                    isLoading={isLoading}
                >
                    Entrar
                </Button>
                <p className="text-xs text-center text-neutral-400">
                    V{getHeaders()['x-versao']}
                </p>
            </form>
        </>
    )
}

export default Login

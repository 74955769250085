import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { getItem } from '@/utils/storage'
import Sidenav from '@/components/Sidenav'
import Header from '@/components/Header'
import ChangePasswordDialog from '@/components/ChangePasswordDialog'
import { authSliceStateSelector } from '@/store/authSlice'
import { appConfigSliceStateSelector } from '@/store/appConfigSlice'
import { useBaseStore } from '@/store'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core'

const ProtectedLayout = () => {
    const { user } = useBaseStore(authSliceStateSelector)
    const { isSidenavOpen } = useBaseStore(appConfigSliceStateSelector)

    const token = getItem(localStorage, 'token')
    const location = useLocation()

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    )

    if (!user && !token) {
        return (
            <Navigate to="/login" replace state={{ path: location.pathname }} />
        )
    }

    return (
        <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToHorizontalAxis]}
            sensors={sensors}
        >
            <div className="w-full h-screen overflow-y-auto">
                <Sidenav />
                <div
                    className={`${
                        isSidenavOpen
                            ? 'ml-sidenav-closed w-container-sidenav-closed sm:ml-sidenav sm:w-container-sidenav'
                            : 'ml-sidenav-closed w-container-sidenav-closed'
                    } h-full`}
                >
                    <Header />
                    <div className={`h-container-header w-full`}>
                        <Outlet />
                    </div>
                </div>
            </div>
            <ChangePasswordDialog />
        </DndContext>
    )
}

export default ProtectedLayout

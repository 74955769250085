import ConfirmDialog from '@/components/ConfirmDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteProductionVolume } from '@/api/business/productionVolume'
import { useToast } from '@/components/ui/use-toast'
import { productionVolumeKeys } from '@/queries/useProductionVolumeQuery'
import { isAxiosError } from '@/api/business'

interface DeleteDialogProps {
    ids: number[]

    isOpen: boolean
    onClose: () => void
}

const DeleteDialog = ({ ids, ...props }: DeleteDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate: mutateDelete, isLoading: mutateLoading } = useMutation({
        mutationFn: deleteProductionVolume,
        onSuccess: () => {
            queryClient.invalidateQueries(productionVolumeKeys.lists())
            props.onClose()
            toast({
                title: 'Volume de produção removido com sucesso',
            })
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao criar volume de produção',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    return (
        <ConfirmDialog
            title="Excluir volume de produção"
            dialogType="destructive"
            isLoading={mutateLoading}
            onConfirm={() => mutateDelete({ SK_VOLUME_PRODUCAO: ids })}
            {...props}
        >
            <div className="pt-4">
                <p>
                    Realmente deseja excluir{' '}
                    <span className="font-bold">{ids.length}</span> itens?
                </p>
            </div>
        </ConfirmDialog>
    )
}

export default DeleteDialog

import { NormalizedState } from '@/store'
import { Taxes } from './Costs'
import { CurrencyType } from './Currency'

// Definições de tipos simples
export type StructureType = 'PADRAO' | 'EXTRA-COMPONENTE' | 'EXTRA-PRODUTO'
export type DataOrigin = 'PROTHEUS' | 'MANUAL'
export type ViewType = 'VISAO_CUSTO_PADRAO' | 'LAYOUT_CUSTO_PADRAO'

export enum CostView {
    CUSTO_INFORMADO = 'Custo informado',
    CUSTO_MEDIO = 'Custo médio',
    ULTIMO_PRECO = 'Último preço',
    CUSTO_EXTRA = 'Custo extra',
    CUSTO_PADRAO = 'Custo padrão',
}

// Interface para valores de custo padrão
export interface StandardCostValues {
    QTD_COMPONENTE: number
    CUSTO_SELECIONADO: number
    CUSTO_PADRAO_REAL: number
    CUSTO_PADRAO_TOTAL: number
    CUSTO_PADRAO_TOTAL_REAL: number
    CUSTO_TOTAL_REAL: number
    VARIACAO_CUSTO_PADRAO: number
    VARIACAO_INDICE: number
    VARIACAO_PERCENTUAL_INDICE: number
    VARIACAO_CUSTO_PADRAO_TOTAL: number
    VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL: number
    VARIACAO_CUSTO_REAL_TOTAL: number
    VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL: number
    VARIACAO_QUANTIDADE_TOTAL: number
    VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL: number
    IMPACTO_CONSUMO: number
    IMPACTO_PRECO: number
    VARIACAO_CUSTO_UNITARIO: number
    VARIACAO_CUSTO_UNITARIO_PERCENTUAL: number
    CUSTO_PADRAO_UNITARIO: number
}

// Interface para dados personalizados de custo padrão
export interface StandardCostCustomData extends StandardCostValues {
    PRODUTO_QTD_SELECIONADA: number | null
    VISAO_SELECIONADA: CostView | null
    TIPO_VISAO: CostView | null
    PRODUTO_FILTRO: string
    productUsedAsComponentId: string | null
}

// Interface para resposta da API do produto
export interface ProductAPIResponse {
    SK_EMPRESA: string
    SK_PRODUTO_ESTRUTURA: string
    NK_ESTRUTURA_DE_PRODUTO: string
    NK_PRODUTO_ESTRUTURA?: string
    QTD_PRODUCAO_APONTADA: number
    ABREVIATURA_EMPRESA: string
    DS_REVISAO_INICIAL?: string
    DS_REVISAO_FINAL: string
    QTD_VOLUME?: number
    PRODUTO_ESTRUTURA: string
    UM_PRODUTO_ESTRUTURA: string
    DD_CLASSIFICACAO: string
    MANIPULA_PRODUTO_ESTRUTURA: 0 | 1
    PRODUTO_APONTADO_BLOQUEADO?: 0 | 1
    VALOR_INFORMADO_PRODUTO: number
    CUSTO: number
    DD_ORIGEM_DADO: DataOrigin
    DD_VARIANCIA_MAX: number
    DD_VARIANCIA_MIN: number
    COMPONENTES: ComponentAPIResponse[]
}

// Interface para resposta da API do componente
export interface ComponentAPIResponse extends Taxes {
    SK_NOVO_CUSTO_COMPONENTE: number | null
    SK_PRODUTO_COMPONENTE: string
    NK_ESTRUTURA_DE_PRODUTO?: string
    NK_PRODUTO_COMPONENTE: string
    INDICE_COMPONENTE_REAL: number | null
    INDICE_STANDARD: number
    QTD_REAL_UTILIZADA: number
    PRODUTO_COMPONENTE: string
    UM_PRODUTO_COMPONENTE?: string
    DD_CLASSIFICACAO: string
    COMPONENTES: ComponentAPIResponse[]
    ULTIMO_PRECO: number
    ULTIMO_PRECO_UNITARIO: number
    VALOR_CUSTO_REAL_UNITARIO: number
    VALOR_CUSTO_REAL_COMPONENTE: number
    VALOR_INFORMADO_COMPONENTE: number
    VALOR_MOEDA_PADRAO_COMPONENTE: number
    MOEDA_UTILIZADA_COMPONENTE: CurrencyType
    PRODUTO_COMPONENTE_BLOQUEADO?: 0 | 1
    MANIPULA_COMPONENTE: 0 | 1
    ESTRUTURA: StructureType
    DD_VARIANCIA_MAX: number
    DD_VARIANCIA_MIN: number
}

// Interface para dados da tabela de custo padrão
export interface StandardCostTableData
    extends StandardCostCustomData,
        Partial<
            Pick<
                Product,
                | 'SK_EMPRESA'
                | 'ABREVIATURA_EMPRESA'
                | 'SK_PRODUTO_ESTRUTURA'
                | 'NK_PRODUTO_ESTRUTURA'
                | 'PRODUTO_ESTRUTURA'
                | 'DS_REVISAO_FINAL'
                | 'DS_REVISAO_INICIAL'
                | 'DD_CLASSIFICACAO'
                | 'UM_PRODUTO_ESTRUTURA'
                | 'VALOR_INFORMADO_PRODUTO'
                | 'DD_ORIGEM_DADO'
                | 'PRODUTO_APONTADO_BLOQUEADO'
                | 'DD_VARIANCIA_MAX'
                | 'DD_VARIANCIA_MIN'
            >
        >,
        Partial<
            Pick<
                Component,
                | 'ULTIMO_PRECO'
                | 'ULTIMO_PRECO_UNITARIO'
                | 'VALOR_CUSTO_REAL_COMPONENTE'
                | 'VALOR_CUSTO_REAL_UNITARIO'
                | 'INDICE_STANDARD'
                | 'INDICE_COMPONENTE_REAL'
                | 'QTD_REAL_UTILIZADA'
                | 'VALOR_INFORMADO_COMPONENTE'
                | 'DD_VARIANCIA_MAX'
                | 'DD_VARIANCIA_MIN'
            >
        > {
    subRows: StandardCostTableData[]
    _id: string
}

// Definição de entidade de produto
export type ProductEntity = Omit<ProductAPIResponse, 'COMPONENTES'> & {
    _id: string
    _parentId: null
    COMPONENTES: string[]
    VALOR_CUSTO_REAL_COMPONENTE: number
}

// Dados personalizados de entidade de produto
export type ProductCustomEntity = ProductEntity & StandardCostCustomData

// Definição de tipo de produto
export type Product = Omit<ProductEntity, 'COMPONENTES'> &
    StandardCostCustomData & {
        COMPONENTES: ComponentEntity[]
        VALOR_CUSTO_REAL_COMPONENTE: number
    }

// Definição de entidade de componente
export type ComponentEntity = Omit<ComponentAPIResponse, 'COMPONENTES'> & {
    _id: string
    _parentId: string
    SK_EMPRESA: string
    DD_ORIGEM_DADO: DataOrigin
    COMPONENTES: string[]
}

// Dados personalizados de entidade de componente
export type ComponentCustomEntity = ComponentEntity & StandardCostCustomData

// Definição de tipo de componente
export type Component = Omit<ComponentEntity, 'COMPONENTES'> & {
    COMPONENTES: ComponentEntity[]
    SK_EMPRESA: string
    DD_ORIGEM_DADO: DataOrigin
}

// === Tipos normalizados para produtos e componentes ===

// Tipos produtos
export type ProductNormalized = NormalizedState<ProductEntity>
export type ProductCustom = ProductEntity &
    StandardCostCustomData & { VALOR_CUSTO_REAL_COMPONENTE: number }
export type ProductCustomNormalized = NormalizedState<ProductCustom>

// Tipos componentes
export type ComponentNormalized = NormalizedState<ComponentEntity>
export type ComponentCustom = ComponentEntity & StandardCostCustomData
export type ComponentCustomNormalized = NormalizedState<ComponentCustom>

// Interface para visão
export interface View {
    SK_CONFIGURACAO_EXIBICAO: number
    NM_CONFIGURACAO_EXIBICAO: string
    DS_CONFIGURACAO_EXIBICAO: string
    DD_TIPO_CONFIGURACAO: ViewType
    SK_TEMPO: number
}

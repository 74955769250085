import { ExtraCostValueCategory, Taxes } from '@/types/Costs'
import { getDateFromId } from '@/utils/date'
import { useCallback, useMemo, useReducer } from 'react'
import { ExtraCostTable } from '../..'

interface Tax {
    groupName: string
    inputs: {
        name: keyof Taxes
        label: string
        value: string
    }[]
}

export interface EditDialogState {
    taxes: Tax[]
    date: Date
    description: string
    costValue: string
    category: string
    quantity: string
}

export enum EditDialogActionNames {
    ON_CHANGE_TAX_VALUE,
    ON_CHANGE_DATE,
    ON_CHANGE_DESCRIPTION,
    ON_CHANGE_CATEGORY,
    ON_CHANGE_COST,
    ON_CHANGE_QUANTITY,
    RESET,
}

type EditDialogActions =
    | {
          type: EditDialogActionNames.ON_CHANGE_DATE
          payload: { date: Date }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_DESCRIPTION
          payload: {
              description: string
          }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_COST
          payload: {
              cost: string
          }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_QUANTITY
          payload: {
              quantity: string
          }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_TAX_VALUE
          payload: {
              taxId: string
              value: string
          }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_CATEGORY
          payload: {
              category: ExtraCostValueCategory
          }
      }
    | {
          type: EditDialogActionNames.RESET
      }

const useEditDialog = (data: ExtraCostTable) => {
    const TAXES: Tax[] = useMemo(
        () => [
            {
                groupName: 'PIS',
                inputs: [
                    {
                        name: 'VL_PIS',
                        label: 'Valor',
                        value: data.VL_PIS,
                    },
                    {
                        name: 'VL_ALIQUOTA_PIS',
                        label: 'Aliquota',
                        value: data.VL_ALIQUOTA_PIS,
                    },
                ],
            },
            {
                groupName: 'COFINS',
                inputs: [
                    {
                        name: 'VL_COFINS',
                        label: 'Valor',
                        value: data.VL_COFINS,
                    },
                    {
                        name: 'VL_ALIQUOTA_COFINS',
                        label: 'Aliquota',
                        value: data.VL_ALIQUOTA_COFINS,
                    },
                ],
            },
            {
                groupName: 'ICMS',
                inputs: [
                    {
                        name: 'VL_ICMS',
                        label: 'Valor',
                        value: data.VL_ICMS,
                    },
                    {
                        name: 'VL_ALIQUOTA_ICMS',
                        label: 'Aliquota',
                        value: data.VL_ALIQUOTA_ICMS,
                    },
                ],
            },
            {
                groupName: 'IPI',
                inputs: [
                    {
                        name: 'VL_IPI',
                        label: 'Valor',
                        value: data.VL_IPI,
                    },
                    {
                        name: 'VL_ALIQUOTA_IPI',
                        label: 'Aliquota',
                        value: data.VL_ALIQUOTA_IPI,
                    },
                ],
            },
            {
                groupName: 'ST',
                inputs: [
                    {
                        name: 'VL_ST',
                        label: 'Valor',
                        value: data.VL_ST,
                    },
                    {
                        name: 'VL_ALIQUOTA_ST',
                        label: 'Aliquota',
                        value: data.VL_ALIQUOTA_ST,
                    },
                ],
            },
            {
                groupName: 'FCP',
                inputs: [
                    {
                        name: 'VL_FCP',
                        label: 'Valor',
                        value: data.VL_FCP,
                    },
                    {
                        name: 'VL_ALIQUOTA_FCP',
                        label: 'Aliquota',
                        value: data.VL_ALIQUOTA_FCP,
                    },
                ],
            },
        ],
        [data]
    )

    const initialState: EditDialogState = useMemo(
        () => ({
            taxes: TAXES,
            costValue: data.VL_CUSTO_ORCADO,
            description: data.DS_CUSTO,
            date: getDateFromId(data.SK_TEMPO),
            category: data.CATEGORIA_VALOR,
            quantity: data.QTD_CUSTO.toString(),
        }),

        [TAXES, data]
    )

    const reducer = useCallback(
        (
            state: typeof initialState,
            action: EditDialogActions
        ): typeof initialState => {
            switch (action.type) {
                case EditDialogActionNames.ON_CHANGE_DESCRIPTION: {
                    const { description } = action.payload

                    return {
                        ...state,
                        description,
                    }
                }
                case EditDialogActionNames.ON_CHANGE_COST: {
                    const { cost } = action.payload
                    return {
                        ...state,
                        costValue: cost,
                    }
                }
                case EditDialogActionNames.ON_CHANGE_QUANTITY: {
                    const { quantity } = action.payload
                    return {
                        ...state,
                        quantity: quantity,
                    }
                }
                case EditDialogActionNames.ON_CHANGE_DATE: {
                    const { date } = action.payload
                    return {
                        ...state,
                        date,
                    }
                }
                case EditDialogActionNames.ON_CHANGE_TAX_VALUE: {
                    const { taxId, value } = action.payload
                    return {
                        ...state,
                        taxes: state.taxes.map((taxGroup) => {
                            return {
                                ...taxGroup,
                                inputs: taxGroup.inputs.map((input) => {
                                    if (input.name === taxId) {
                                        return {
                                            ...input,
                                            value: value,
                                        }
                                    }
                                    return input
                                }),
                            }
                        }),
                    }
                }
                case EditDialogActionNames.RESET:
                    return initialState
                default:
                    return state
            }
        },
        [initialState]
    )

    const [state, dispatch] = useReducer(reducer, initialState)

    return {
        ...state,
        dispatch,
    }
}
export default useEditDialog

import { useEffect, useRef, useState } from 'react'

import {
    ColumnDef,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    flexRender,
    getSortedRowModel,
    SortingState,
    ColumnFiltersState,
    Table,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    Row,
} from '@tanstack/react-table'
import { Filter } from '../Table/components/Filter'
import { ChevronDown, ChevronUp, ChevronsUpDown } from 'lucide-react'
import { cn } from '@/lib/utils'
import { TableCustomData } from '@/components/Table/type'
import { useVirtualizer } from '@tanstack/react-virtual'
import { UpdateData } from '@/tanstack'

const defaultColumn: Partial<ColumnDef<any>> = {
    cell: ({ getValue, row: { index }, column: { id, columnDef }, table }) => {
        const initialValue = getValue()
        const [value, setValue] = useState(initialValue)

        const onBlur = () => {
            if (table.options.meta?.updateData) {
                table.options.meta.updateData(index, id, value, columnDef)
            }
        }
        useEffect(() => {
            setValue(initialValue)
        }, [initialValue])

        return (
            <input
                className="w-full px-2 py-1 text-xs border"
                value={value as string}
                onChange={(e) => setValue(e.target.value)}
                onBlur={onBlur}
            />
        )
    },
}

interface TableWithInputProps<TData> {
    data: TData[]
    columns: ColumnDef<TData, any>[]
    getTableInstance?: (table: Table<TData>) => void
    updateData?: UpdateData<TData>
}

export const TableWithInput = <TData extends TableCustomData<TData>>({
    data,
    columns,
    updateData,
    getTableInstance,
}: TableWithInputProps<TData>) => {
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const tableRef = useRef<HTMLDivElement | null>(null)

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnFilters,
        },
        meta: {
            updateData,
        },
        enableColumnFilters: false,
        columnResizeMode: 'onChange',
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        defaultColumn,
    })

    useEffect(() => {
        if (getTableInstance) getTableInstance(table)
    }, [])

    const tableRows = table.getRowModel().rows

    const rowVirtualizer = useVirtualizer({
        getScrollElement: () => tableRef.current,
        estimateSize: () => 30,
        count: tableRows.length,
    })

    const { getVirtualItems, getTotalSize } = rowVirtualizer

    const rows = getVirtualItems()

    return (
        <div className="relative w-full h-full overflow-auto" ref={tableRef}>
            {/* table */}

            <div
                className="flex flex-col min-w-full"
                style={{
                    width: table.getTotalSize(),
                }}
            >
                <div className="sticky top-0 z-10 bg-white">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <div
                            key={headerGroup.id}
                            className="flex text-left border-b border-divider-color"
                        >
                            {headerGroup.headers.map((header) => (
                                // th
                                <div
                                    key={header.id}
                                    className={`${
                                        header.column.getCanSort()
                                            ? 'cursor-pointer select-none'
                                            : ''
                                    } p-2 relative font-normal overflow-hidden text-disabled text-sm text-ellipsis whitespace-nowrap`}
                                    style={{
                                        width: header.getSize(),
                                    }}
                                >
                                    <div className="relative flex items-center gap-2">
                                        <span
                                            className={
                                                header.column.getCanSort()
                                                    ? 'absolute top-0 left-0 right-0 h-full cursor-pointer select-none'
                                                    : ''
                                            }
                                            onClick={header.column.getToggleSortingHandler()}
                                        ></span>

                                        <div className="flex-1 overflow-hidden text-ellipsis">
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef
                                                          .header,
                                                      header.getContext()
                                                  )}
                                        </div>
                                        <div>
                                            {header.column.getCanSort() &&
                                                ({
                                                    asc: (
                                                        <ChevronUp size={16} />
                                                    ),
                                                    desc: (
                                                        <ChevronDown
                                                            size={16}
                                                        />
                                                    ),
                                                }[
                                                    header.column.getIsSorted() as string
                                                ] ?? (
                                                    <ChevronsUpDown size={16} />
                                                ))}
                                        </div>
                                    </div>

                                    {header.column.getCanFilter() ? (
                                        <Filter
                                            column={header.column}
                                            table={table}
                                        />
                                    ) : null}
                                    {header.column.getCanResize() && (
                                        <div
                                            onMouseDown={header.getResizeHandler()}
                                            onTouchStart={header.getResizeHandler()}
                                            className={`absolute right-0 top-0 h-full w-[5px] cursor-col-resize select-none hover:bg-primary-200 touch-none ${
                                                header.column.getIsResizing()
                                                    ? 'bg-primary-500'
                                                    : ''
                                            }`}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="flex-1">
                    <div
                        className="relative"
                        style={{
                            height: `${getTotalSize()}px`,
                        }}
                    >
                        {rows.map((item) => {
                            const row = tableRows[item.index] as Row<TData>
                            if (!row) return null
                            return (
                                <div
                                    key={row.id}
                                    className={cn('flex overflow-hidden')}
                                    style={{
                                        height: `${item.size}px`,
                                        transform: `translateY(${item.start}px)`,
                                        position: 'absolute',
                                    }}
                                >
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            // td
                                            <div
                                                key={cell.id}
                                                style={{
                                                    width: cell.column.getSize(),
                                                }}
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

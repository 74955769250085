import { cn } from '@/lib/utils'
import { VariantProps, cva } from 'class-variance-authority'
import { ButtonHTMLAttributes, forwardRef } from 'react'

const buttonVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-gray-950 dark:focus-visible:ring-gray-300',
    {
        variants: {
            variant: {
                default:
                    'py-1.5 text-sm text-white rounded-lg w-[80px] bg-primary-500 text-gray-50 hover:bg-primary-600/90 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90',
                destructive:
                    'bg-red-500 text-gray-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-gray-50 dark:hover:bg-red-900/90',
                outline:
                    'border border-gray-200 bg-white hover:bg-gray-100 hover:text-gray-900 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50',
                secondary:
                    'bg-secondary-500 text-white hover:bg-secondary-600/80 dark:bg-secondary-800 dark:text-secondary-50 dark:hover:bg-secondary-800/80',
                ghost: 'hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-gray-50',
                link: 'text-gray-900 underline-offset-4 hover:underline dark:text-gray-50',
                icon: 'hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-gray-50 rounded-md h-10 min-w-10',
            },
            size: {
                default: 'h-10 px-4 py-2',
                sm: 'h-9 rounded-md px-3',
                lg: 'h-11 rounded-md px-8',
                icon: 'h-10 min-w-10',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
)
export interface ButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    loadingSize?: 'sm' | 'md' | 'lg'
    isLoading?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            isLoading,
            children,
            // loadingSize = 'md',
            disabled,
            variant,
            size,
            ...props
        },
        ref
    ) => {
        return (
            <button
                className={cn(
                    buttonVariants({
                        className,
                        size,
                        variant,
                    }),
                    isLoading || disabled
                        ? 'bg-neutral-300 text-neutral-600'
                        : ''
                )}
                disabled={isLoading || disabled}
                {...props}
                ref={ref}
            >
                {isLoading ? (
                    <div
                        className={cn('button-spinner animate-spin', size)}
                    ></div>
                ) : (
                    children
                )}
            </button>
        )
    }
)

export default Button

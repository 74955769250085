import { Boxes, MinusCircle, Plus } from 'lucide-react'
import { useMemo } from 'react'
import TabSection from '../../../../../TabSection'
import { currencyFormat } from '@/utils/stringFormatter'
import { IdToRemove } from '../..'
import { ProductEntity } from '@/types/StandardCost'
import { useBaseStore } from '@/store'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'

interface ProductProps {
    product: ProductEntity
    onAddExtraCostDialogOpen: () => void
    onRemoveCostDialogOpen: () => void
    setIdToRemove: (props: IdToRemove) => void
}

const Product = ({
    product,
    onAddExtraCostDialogOpen,
    onRemoveCostDialogOpen,
    setIdToRemove,
}: ProductProps) => {
    const {
        stdCostData: { components },
    } = useBaseStore(stdCostSliceStateSelector)

    const extraCosts = useMemo(
        () =>
            product.COMPONENTES.map((id) => components.entities[id]).filter(
                (component) =>
                    component.ESTRUTURA === 'EXTRA-COMPONENTE' ||
                    component.ESTRUTURA === 'EXTRA-PRODUTO'
            ) || [],
        [product]
    )

    return (
        <>
            <TabSection title={'Produto'}>
                <p className="flex items-center gap-2 ">
                    <Boxes size={20} />
                    <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                        {product?.PRODUTO_ESTRUTURA}
                    </span>
                </p>
            </TabSection>
            <TabSection
                title="Custos extras"
                actionButton={
                    product?.MANIPULA_PRODUTO_ESTRUTURA === 1 && (
                        <button onClick={onAddExtraCostDialogOpen}>
                            <Plus size={16} />
                        </button>
                    )
                }
            >
                {extraCosts.length > 0 ? (
                    <table>
                        <thead>
                            <tr className="text-left text-primary-600">
                                <th className="px-4">Descrição</th>
                                <th className="px-4">Valor</th>
                                <th className="px-4"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {extraCosts.map((component) => (
                                <tr key={component.SK_PRODUTO_COMPONENTE}>
                                    <td className="px-4">
                                        {component.PRODUTO_COMPONENTE}
                                    </td>
                                    <td className="px-4">
                                        {currencyFormat(
                                            component.ULTIMO_PRECO,
                                            4
                                        )}
                                    </td>
                                    <td className="px-4">
                                        {product?.MANIPULA_PRODUTO_ESTRUTURA && (
                                            <button
                                                className="text-red-500"
                                                onClick={() => {
                                                    setIdToRemove({
                                                        id: Number(
                                                            component.SK_PRODUTO_COMPONENTE
                                                        ),
                                                        isExtraCost: true,
                                                    })
                                                    onRemoveCostDialogOpen()
                                                }}
                                            >
                                                <MinusCircle size={14} />
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center text-primary-600">
                        Não há custo extra
                    </p>
                )}
            </TabSection>
        </>
    )
}

export default Product

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { motion } from 'framer-motion'
import CostsTab from '@/pages/StdCosts/components/Details/components/DefaultDetails/components/CostsTab'
import DetailsTab from '@/pages/StdCosts/components/Details/components/DefaultDetails/components/DetailsTab'
import HistoryTab from '@/pages/StdCosts/components/Details/components/DefaultDetails/components/HistoryTab'
import {
    ComponentCustomEntity,
    ProductCustomEntity,
} from '@/types/StandardCost'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { CostView } from '@/types/Product'
import { Maximize, X } from 'lucide-react'

const TABS = [
    {
        label: 'Detalhamento',
        value: 'details',
        component: <DetailsTab />,
    },
    {
        label: 'Custos',
        value: 'costs',
        component: <CostsTab />,
    },
    {
        label: 'Histórico',
        value: 'history',
        component: <HistoryTab />,
    },
]

interface DefaultDetailsProps {
    productOrComponent: ProductCustomEntity | ComponentCustomEntity
    onChangeFullScreen: (isOpen: boolean) => void
}

const stateSelector = (state: Store) => ({
    isMobile: state.appConfigSlice.state.isMobile,
    selectedRow: state.stdCostSlice.state.selectedStdCostRow,
})
const actionsSelector = (state: Store) => ({
    onClose: state.stdCostSlice.actions.onCloseDetails,
})

const DefaultDetails = ({
    productOrComponent,
    onChangeFullScreen,
}: DefaultDetailsProps) => {
    const { onClose } = useBaseStore(actionsSelector)

    const { isMobile, selectedRow } = useBaseStore(stateSelector)

    const isExtraCost = productOrComponent?.TIPO_VISAO === CostView.CUSTO_EXTRA
    const isStd = productOrComponent?.TIPO_VISAO === CostView.CUSTO_PADRAO

    return (
        <motion.div
            layoutId="details"
            layout="position"
            animate={{
                width: isMobile ? 'auto' : 367,
            }}
            className="fixed h-full w-full overflow-hidden sm:static right-0 left-0 bottom-0 top-0 z-[100] bg-white sm:w-[367px] shadow-lg border border-[#f4f2f2] rounded-none md:rounded-lg"
        >
            <div className="flex justify-end gap-2 px-2 py-3">
                {selectedRow?.isProduct && (
                    <button onClick={() => onChangeFullScreen(true)}>
                        <Maximize size={16} />
                    </button>
                )}
                <button onClick={onClose}>
                    <X size={16} />
                </button>
            </div>
            <Tabs defaultValue="details" className="w-full h-full">
                <TabsList className="w-full bg-slate-100">
                    {TABS.filter((tab) =>
                        selectedRow?.isProduct || isExtraCost || isStd
                            ? tab.value !== 'history'
                            : true
                    ).map((tab) => (
                        <TabsTrigger
                            key={tab.value}
                            className="flex-1"
                            value={tab.value}
                        >
                            {tab.label}
                        </TabsTrigger>
                    ))}
                </TabsList>
                {TABS.filter((tab) =>
                    selectedRow?.isProduct ? tab.value !== 'history' : true
                ).map((tab) => (
                    <TabsContent
                        key={tab.value}
                        className="h-[calc(100%_-_85px)] p-2 overflow-auto"
                        value={tab.value}
                    >
                        {tab.component}
                    </TabsContent>
                ))}
            </Tabs>
        </motion.div>
    )
}

export default DefaultDetails

import { useCurrencyQuery } from '@/queries/useCurrencyQuery'
import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { Taxes } from '@/types/Costs'
import { CurrencyDS } from '@/types/Currency'
import { ProductStructureFlat } from '@/types/Product'
import { lastDayOfMonth } from 'date-fns'
import { useCallback, useEffect, useMemo, useReducer } from 'react'

interface Tax {
    groupName: string
    inputs: {
        name: keyof Taxes
        label: string
        value: string
    }[]
}

export interface AddDialogState {
    taxes: Tax[]
    companies: { companyId: number; company: string }[]
    date: {
        from: Date
        to: Date
    }
    products: { all: ProductStructureFlat[]; filtered: ProductStructureFlat[] }
    components: {
        all: ProductStructureFlat[]
        filtered: ProductStructureFlat[]
    }
    productIds: string[]
    componentId: string
    companyId: string
    currencyId: string
    currencies: CurrencyDS[]
    costValue: string
}
export enum AddDialogActionNames {
    SET_PRODUCTS = 'SET_PRODUCTS',
    SET_CURRENCIES = 'SET_CURRENCIES',
    ON_CHANGE_TAX_VALUE = 'ON_CHANGE_TAX_VALUE',
    ON_CHANGE_COMPANY = 'ON_CHANGE_COMPANY',
    ON_SELECT_PRODUCT_STRUCTURE = 'ON_SELECT_PRODUCT_STRUCTURE',
    ON_SELECT_ALL_PRODUCT_STRUCTURES = 'ON_SELECT_ALL_PRODUCT_STRUCTURES',
    ON_SELECT_COMPONENT = 'ON_SELECT_COMPONENT',
    ON_CHANGE_DATE = 'ON_CHANGE_DATE',
    ON_CHANGE_CURRENCY = 'ON_CHANGE_CURRENCY',
    ON_CHANGE_COST = 'ON_CHANGE_COST',
    RESET = 'RESET',
}

type AddDialogActions =
    | {
          type: AddDialogActionNames.SET_PRODUCTS
          payload: ProductStructureFlat[]
      }
    | {
          type: AddDialogActionNames.SET_CURRENCIES
          payload: CurrencyDS[]
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_DATE
          payload: {
              from: Date
              to: Date
          }
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_DATE
          payload: {
              from: Date
              to: Date
          }
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_CURRENCY
          payload: {
              currencyId: string
          }
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_COST
          payload: {
              cost: string
          }
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_COMPANY
          payload: {
              companyId: string
          }
      }
    | {
          type: AddDialogActionNames.ON_SELECT_PRODUCT_STRUCTURE
          payload: {
              productId: string
          }
      }
    | {
          type: AddDialogActionNames.ON_SELECT_ALL_PRODUCT_STRUCTURES
          payload: {
              productIds: string[]
          }
      }
    | {
          type: AddDialogActionNames.ON_SELECT_COMPONENT
          payload: {
              componentId: string
          }
      }
    | {
          type: AddDialogActionNames.ON_CHANGE_TAX_VALUE
          payload: {
              taxId: string
              value: string
          }
      }
    | {
          type: AddDialogActionNames.RESET
      }

interface UseAddDialogProps {
    isOpen: boolean
}

const useAddDialog = ({ isOpen }: UseAddDialogProps) => {
    const TAXES: Tax[] = useMemo(
        () => [
            {
                groupName: 'PIS',
                inputs: [
                    {
                        name: 'VL_PIS',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_PIS',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'COFINS',
                inputs: [
                    {
                        name: 'VL_COFINS',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_COFINS',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'ICMS',
                inputs: [
                    {
                        name: 'VL_ICMS',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_ICMS',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'IPI',
                inputs: [
                    {
                        name: 'VL_IPI',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_IPI',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'ST',
                inputs: [
                    {
                        name: 'VL_ST',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_ST',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
            {
                groupName: 'FCP',
                inputs: [
                    {
                        name: 'VL_FCP',
                        label: 'Valor',
                        value: '0',
                    },
                    {
                        name: 'VL_ALIQUOTA_FCP',
                        label: 'Aliquota',
                        value: '0',
                    },
                ],
            },
        ],
        []
    )

    const { data: productStructureData, isLoading: isProductStructureLoading } =
        useProductStructureQuery({ enabled: isOpen })

    const { data: currencyData, isLoading: isCurrencyLoading } =
        useCurrencyQuery()

    useEffect(() => {
        if (productStructureData) {
            dispatch({
                type: AddDialogActionNames.SET_PRODUCTS,
                payload: productStructureData,
            })
        }
    }, [productStructureData])

    useEffect(() => {
        if (currencyData) {
            dispatch({
                type: AddDialogActionNames.SET_CURRENCIES,
                payload: currencyData,
            })
        }
    }, [currencyData])

    const initialState: AddDialogState = useMemo(
        () => ({
            taxes: TAXES,
            companyId: '',
            companies: [],
            productIds: [],
            products: { all: [], filtered: [] },
            components: { all: [], filtered: [] },
            componentId: '',
            costValue: '0',
            currencyId: '',
            currencies: [],
            date: {
                from: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    1
                ),
                to: lastDayOfMonth(new Date()),
            },
        }),

        [TAXES]
    )

    const reducer = useCallback(
        (
            state: typeof initialState,
            action: AddDialogActions
        ): typeof initialState => {
            switch (action.type) {
                case AddDialogActionNames.SET_PRODUCTS: {
                    const data = action.payload

                    const components = [
                        ...new Set(
                            data.map(
                                (component) => component.SK_PRODUTO_COMPONENTE
                            )
                        ),
                    ].map(
                        (componentId) =>
                            data.find(
                                (component) =>
                                    component.SK_PRODUTO_COMPONENTE ===
                                    componentId
                            )!
                    )

                    const uniqueCompanies = [
                        ...new Set(data.map((product) => product.SK_EMPRESA)),
                    ].map((companyId) => ({
                        companyId,
                        company:
                            data.find(
                                (company) => company.SK_EMPRESA === companyId
                            )?.ABREVIATURA_EMPRESA || '',
                    }))

                    const companyId =
                        uniqueCompanies[0].companyId.toString() || ''

                    const filteredComponents = components.filter(
                        (product) => product.SK_EMPRESA.toString() === companyId
                    )

                    return {
                        ...state,
                        companyId,
                        companies: uniqueCompanies,
                        componentId: '',
                        products: { all: data, filtered: [] },
                        productIds: [],
                        components: {
                            all: components,
                            filtered: filteredComponents,
                        },
                    }
                }
                case AddDialogActionNames.SET_CURRENCIES: {
                    const currencies = action.payload

                    return {
                        ...state,
                        currencies,
                        currencyId: currencies[0].SK_MOEDA.toString(),
                    }
                }
                case AddDialogActionNames.ON_CHANGE_CURRENCY: {
                    const { currencyId } = action.payload

                    return {
                        ...state,
                        currencyId,
                    }
                }
                case AddDialogActionNames.ON_CHANGE_COST: {
                    const { cost } = action.payload
                    return {
                        ...state,
                        costValue: cost,
                    }
                }
                case AddDialogActionNames.ON_CHANGE_DATE: {
                    const { to, from } = action.payload
                    return {
                        ...state,
                        date: {
                            from: from,
                            to: to,
                        },
                    }
                }
                case AddDialogActionNames.ON_CHANGE_COMPANY: {
                    const { companyId } = action.payload

                    return {
                        ...state,
                        companyId,
                        productIds: [],
                        components: {
                            ...state.components,
                            filtered: state.components.all.filter(
                                (product) =>
                                    product.SK_EMPRESA.toString() === companyId
                            ),
                        },
                        products: {
                            ...state.products,
                            filtered: state.products.all.filter(
                                (product) =>
                                    product.SK_EMPRESA.toString() ===
                                        companyId &&
                                    product.NK_COMPONENTE === state.componentId
                            ),
                        },
                    }
                }
                case AddDialogActionNames.ON_SELECT_PRODUCT_STRUCTURE: {
                    const { productId } = action.payload

                    return {
                        ...state,
                        productIds: state.productIds.includes(productId)
                            ? state.productIds.filter((id) => id !== productId)
                            : [...state.productIds, productId],
                    }
                }
                case AddDialogActionNames.ON_SELECT_ALL_PRODUCT_STRUCTURES: {
                    const { productIds } = action.payload

                    return {
                        ...state,
                        productIds,
                    }
                }
                case AddDialogActionNames.ON_SELECT_COMPONENT: {
                    const { componentId } = action.payload

                    const unique = [
                        ...new Set(
                            state.products.all
                                .filter(
                                    (product) =>
                                        product.NK_COMPONENTE === componentId &&
                                        product.SK_EMPRESA.toString() ===
                                            state.companyId
                                )
                                .map((product) => product.NK_COMPONENTE)
                        ),
                    ]

                    const productsFiltered = [
                        ...new Set(
                            state.products.all
                                .filter(
                                    (product) =>
                                        unique.includes(
                                            product.NK_COMPONENTE
                                        ) &&
                                        product.SK_EMPRESA.toString() ===
                                            state.companyId
                                )
                                .map((product) => product.NK_PRODUTO)
                        ),
                    ].map(
                        (product) =>
                            state.products.all.find(
                                (prd) =>
                                    prd.NK_PRODUTO === product &&
                                    prd.SK_EMPRESA.toString() ===
                                        state.companyId
                            )!
                    )
                    return {
                        ...state,
                        componentId,
                        productIds: [],
                        products: {
                            ...state.products,
                            filtered: productsFiltered,
                        },
                    }
                }
                case AddDialogActionNames.ON_CHANGE_TAX_VALUE: {
                    const { taxId, value } = action.payload
                    return {
                        ...state,
                        taxes: state.taxes.map((taxGroup) => {
                            return {
                                ...taxGroup,
                                inputs: taxGroup.inputs.map((input) => {
                                    if (input.name === taxId) {
                                        return {
                                            ...input,
                                            value: value,
                                        }
                                    }
                                    return input
                                }),
                            }
                        }),
                    }
                }
                case AddDialogActionNames.RESET:
                    return initialState
                default:
                    return state
            }
        },
        [initialState]
    )

    const [state, dispatch] = useReducer(reducer, initialState)

    return {
        ...state,
        isCurrencyLoading,
        isProductStructureLoading,
        dispatch,
    }
}
export default useAddDialog

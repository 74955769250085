import ConfirmDialog from '@/components/ConfirmDialog'
import { Row } from '@tanstack/react-table'
import { CurrencyTable } from '../..'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { useToast } from '@/components/ui/use-toast'
import { getDateFromId } from '@/utils/date'
import { updateCurrencyValue } from '@/api/business/currency'
import useEditDialog, { EditDialogActionNames } from './useEditDialog'
import { currencyValuesKeys } from '@/queries/useCurrencyValuesQuery'
import { isAxiosError } from '@/api/business'

interface EditDialogProps {
    row: Row<CurrencyTable>
    isOpen: boolean
    onClose: () => void
}

const EditDialog = ({ row, ...props }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: updateCurrencyValue,
        onSuccess: () => {
            queryClient.invalidateQueries(currencyValuesKeys.lists())
            toast({
                title: 'Custo da moeda atualizado com sucesso',
            })
            props.onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao atualizar custo da moeda',
                        description: 'Verifique os dados e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const { original } = row
    const { value, dispatch } = useEditDialog({
        value: original.VL_MOEDA.toString(),
    })

    const onSubmit = () => {
        if (!original || !value) return

        mutate({
            IDENTIFICADOR: original.SK_VALOR_MOEDA,
            CAMPOS: {
                VL_MOEDA: Number(value),
            },
        })
    }

    return (
        <ConfirmDialog
            title="Editar moeda"
            {...props}
            isLoading={mutateLoading}
            onConfirm={onSubmit}
        >
            <div className="pt-4 space-y-4">
                <div>
                    <label htmlFor="">Moeda</label>
                    <input
                        className="w-full p-2 border rounded-md text-neutral-400"
                        type="text"
                        disabled
                        value={original.DS_MOEDA}
                    />
                </div>
                <div>
                    <label htmlFor="">Data</label>
                    <input
                        id="date"
                        className="block w-full p-2 border rounded-md text-neutral-400"
                        name="date"
                        type="text"
                        disabled
                        value={format(
                            getDateFromId(original.SK_TEMPO),
                            'dd/MM/yyyy'
                        )}
                    />
                </div>
                <div>
                    <label htmlFor="">Valor</label>
                    <input
                        className="w-full p-2 border rounded-md"
                        type="number"
                        value={value}
                        onChange={(e) =>
                            dispatch({
                                type: EditDialogActionNames.ON_CHANGE_VALUE,
                                payload: {
                                    value: e.target.value,
                                },
                            })
                        }
                    />
                </div>
            </div>
        </ConfirmDialog>
    )
}

export default EditDialog

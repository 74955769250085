import { cn } from '@/lib/utils'
import { Header, Table, flexRender } from '@tanstack/react-table'
import HeaderActions from './components/HeaderActions'
import { CSSProperties } from 'react'
import { ArrowDown, ArrowUp, FilterIcon, GripVertical } from 'lucide-react'
import { getCommonPinningStyles } from '../../utils'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Filter } from '../Filter'
interface TableColumnHeaderProps<TData> {
    header: Header<TData, any>
    table: Table<TData>
}

const TableColumnHeader = <T,>({
    header,
    table,
}: TableColumnHeaderProps<T>) => {
    const enableReorder =
        header.column.columnDef.meta?.enableColumnOrdering === undefined ||
        header.column.columnDef.meta?.enableColumnOrdering

    const enableMenu =
        header.column.columnDef.meta?.enableMenu === undefined ||
        header.column.columnDef.meta?.enableMenu

    const layout =
        table.options.meta?.layout || table.options.meta?.layout === 'default'

    const canSort = header.column.getCanSort()

    const headerClassName =
        header.column.columnDef.meta?.header?.className ||
        table.options.meta?.header?.className

    const { attributes, isDragging, listeners, transform } = useSortable({
        id: header.column.id,
    })

    const style: CSSProperties = {
        opacity: isDragging ? 0.8 : 1,
        position: 'relative',
        transform: CSS.Translate.toString(transform),
        transition: 'width transform 0.2s ease-in-out',
        whiteSpace: 'nowrap',
        width: header.getSize(),
        zIndex: isDragging ? 1 : 0,
    }

    return (
        <div
            key={header.id}
            style={{ ...style, ...getCommonPinningStyles(header.column) }}
            className={cn(
                'relative group p-1 px-2 border-b space-y-1 first-of-type:border-l border-r h-auto border-neutral-200 border-t overflow-hidden',
                layout === 'stretch' ? 'last-of-type:flex-1' : '',
                canSort && 'cursor-pointer select-none',
                headerClassName
            )}
        >
            <div className="flex items-center gap-2 ">
                {enableReorder && (
                    <button
                        {...attributes}
                        {...listeners}
                        disabled={!!header.column.getIsPinned()}
                    >
                        <GripVertical size={14} />
                    </button>
                )}
                {enableMenu ? (
                    <HeaderActions table={table} header={header} />
                ) : header.isPlaceholder ? null : (
                    flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                    )
                )}
                {header.column.getIsFiltered() && (
                    <button className="w-4 h-4">
                        <FilterIcon size={14} className="text-neutral-500" />
                    </button>
                )}
                {header.column.getCanSort() &&
                    {
                        asc: <ArrowUp size={14} className="text-neutral-500" />,
                        desc: (
                            <ArrowDown size={14} className="text-neutral-500" />
                        ),
                    }[header.column.getIsSorted() as string]}
            </div>
            {header.column.getCanFilter() ? (
                <div className="px-1">
                    <Filter column={header.column} table={table} />
                </div>
            ) : null}
            {header.column.getCanResize() && (
                <div
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className={`absolute right-0 top-1/2 -translate-y-1/2 h-1/2 rounded-md w-[4px] cursor-col-resize select-none hover:bg-primary-200 touch-none ${
                        header.column.getIsResizing()
                            ? 'bg-primary-500'
                            : 'group-hover:bg-neutral-300'
                    }`}
                />
            )}
        </div>
    )
}

export default TableColumnHeader
